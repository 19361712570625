import {Col, Popover, Row, Typography} from 'antd';
import {useEffect, useMemo, useState} from 'react';

import {
    createUniqueId,
    fetchCurrentField,
    transformNodeToText,
} from '../../../schema-components/antd/edit/RichText/helper';
import {deepEqual} from '../../../schema-components/antd/edit/RichText/plugIns/helper';
import {useRichText} from '../../table/context/RichText';
import {RichTextFieldProps} from '../Fields';
import RichTextEditor from '../plugins/RichText/index';

const {Text} = Typography;

export const RichTextInputField = (props: RichTextFieldProps) => {
    const {fieldValue, forwardedRef, record, name, onChange, onInlineUpdateCompletion} = props;
    const [visible, setVisible] = useState(false);
    const [renderText, setRenderText] = useState('');
    const {disableOnPopoverChange, textEditors} = useRichText();

    const handleOnOpenChange = async (isOpen: boolean) => {
        const clickedInsideModal = document.activeElement?.closest('.rich-text-modal');
        if (disableOnPopoverChange || clickedInsideModal) {
            return;
        }
        const uniqueID = createUniqueId(name, record?._id);
        const {rtFieldValue} = fetchCurrentField(textEditors, uniqueID) ?? {};
        if (!rtFieldValue) return;
        const areObjectsEqual = deepEqual(fieldValue, rtFieldValue);
        if (!isOpen) {
            areObjectsEqual ? onInlineUpdateCompletion?.() : onChange(rtFieldValue, true);
        }
        setVisible(isOpen);
    };

    const fetchText = useMemo(() => {
        return () => {
            if (!fieldValue) return;
            const transformedStr = transformNodeToText(fieldValue);
            setRenderText(transformedStr);
        };
    }, [fieldValue]);

    useEffect(() => {
        fetchText();
        if (disableOnPopoverChange) return;
        setVisible(true);
        return () => setVisible(false);
    }, [fetchText]);

    return (
        <Popover
            content={<RichTextEditor {...props} />}
            trigger="click"
            open={visible}
            destroyTooltipOnHide
            onOpenChange={handleOnOpenChange}
            afterOpenChange={(open) => open && forwardedRef.current.focus()}
        >
            <Row className={`padded-row ${visible ? 'padded-row-bordered' : ''}`}>
                <Col>
                    <Text ellipsis={{tooltip: ''}}>{renderText}</Text>
                </Col>
            </Row>
        </Popover>
    );
};
