import {InputNumberProps} from 'antd';

import {currencySymbol, DEFAULT_DECIMAL_VALUE} from '../../../constants/constants';
import {formatCurrencyInput} from '../../../utils/utils';
import {CurrencyFieldProps} from '../Fields';
import {StyledInputNumber} from '../Fields.styled';
import {useFieldOperations} from '../hooks/useFieldOperations';

const allowedKeys = new Set([
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '.',
    'Backspace',
    'Delete',
]);

export const CurrencyInputField = ({
    attributes,
    disabled,
    fieldValue,
    forwardedRef,
    required,
    validations,
    onChange,
    onCancel,
    className,
    dataTestId,
    fieldType,
}: CurrencyFieldProps) => {
    const {inputValue, handleOnChange, handleOnBlur, handleKeyDown} = useFieldOperations(
        fieldType,
        fieldValue,
        required,
        validations,
        onChange,
        onCancel
    );
    const decimalValue = validations?.decimal_places?.value ?? DEFAULT_DECIMAL_VALUE;
    const formattedVal = inputValue
        ? formatCurrencyInput(Number(inputValue).toLocaleString(), decimalValue)
        : '';
    const step = Math.pow(10, -decimalValue);

    const currency = attributes?.currency;
    const currencyPrefix = currency ? currencySymbol[currency as keyof typeof currencySymbol] : '';
    const minValue = validations?.minLength?.value ?? undefined;

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (!allowedKeys.has(event.key)) {
            event.preventDefault();
        }
    };

    const currencyInputOnChange: InputNumberProps['onChange'] = (value) => {
        const formattedValue = formatCurrencyInput(value, decimalValue) || '';
        handleOnChange(formattedValue);
    };

    return (
        <StyledInputNumber
            className={className}
            value={formattedVal}
            onBlur={handleOnBlur}
            step={step}
            onKeyPress={handleKeyPress}
            onChange={currencyInputOnChange}
            placeholder={Number(0).toFixed(decimalValue)}
            prefix={currencyPrefix}
            stringMode
            min={minValue?.toString()}
            disabled={disabled}
            autoFocus
            controls={false}
            ref={forwardedRef}
            data-testid={dataTestId}
            onKeyDown={handleKeyDown}
        />
    );
};
