export enum FeatureFlag {
	EnableEsignPilet = 'enable-esign-pilet',
	DisableRightSignature = 'disable-rightsignature',
	EnableEditSignatureRequest = 'enable-edit-signature-request', // Enable editing of pending signature requests
	EnableEsignPiletBulkSend = 'esign-pilet-enable-bulk-send', // Enable bulk send
	EnablePiletForcedAuth = 'enable-pilet-forced-reauth', // enable forced reauth when session is using legacy auth token
	EnableStorageEnforcement = 'enable-storage-enforcement', // SFDMS-48 Storage enforcement for accounts that are violating storage limits
	EnableEsignSignerRoute = 'enable-esign-signer-route', // Enable the signer route
	EnableProductLedGrowth = 'enable_product_led_growth', // Enable Product led growth
	EnableEntitlementsPilet = 'enable_entitlements_pilet', // Enable Entitlements Pilet
	EnablePlgForEsign = 'enable_plg_for_esign', // Enable PLG for Esign
	EnableEsignInFBP = 'enable_esign_in_fbp', // Enable Esign in FBP - short lived
	EnableNativeEsignSignerExperience = 'enable_native_esign_signer_experience', // Enable native esign signer experience
}
