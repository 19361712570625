import styled from '@emotion/styled';
import {Flex} from 'antd';

export const ToolbarWrapper = styled(Flex)`
    margin: 16px 0 16px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    .anticon {
        position: relative;
        top: 2px;
    }
`;

export const CreateRowButton = styled(Flex)`
    margin-left: auto;
`;
