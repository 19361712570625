import {SelectProps} from 'antd';

import {CurrencyEnum, FieldsEnum} from '../constants/fields';

// Utility type for non-empty arrays
type NonEmptyArray<T> = [T, ...T[]];
export type FieldsArray = NonEmptyArray<FieldsEnum>;
export type CurrencyArray = NonEmptyArray<CurrencyEnum>;

export type TagRender = SelectProps['tagRender'];

export enum Mode {
    View = 'view',
    Edit = 'edit',
}

export enum Layout {
    Form = 'form',
    Table = 'table',
    Kanban = 'kanban',
}

export enum Variant {
    View = 'view',
    Edit = 'edit',
    Hidden = 'hidden',
}

export type UIMapperType = {
    [layout in Layout]?: {
        [variant in Variant]?: {
            [componentType in FieldsEnum]?: React.FC<any>;
        };
    };
};

export interface TableEmptyStateProps {
    buttonLabel?: string;
    imageUrl?: string;
    message?: string;
    title?: string;
}

export type InputType = string | string[] | number | [] | null | object;
export type InputObj = {
    value: InputType;
    errorMessage?: string;
};
export type ValidationType = {
    type: string | string[] | number;
    minLength?: {
        value: number;
        message: string;
    };
    maxLength?: {
        value: number;
        message: string;
    };
    limit?: {
        value: number;
        message: string;
    };
    minDate?: {
        value?: Date;
        message: string;
    };
    maxDate?: {
        value?: Date;
        message: string;
    };
    decimal_places?: {
        value: number;
        message: string;
    };
};

export interface SchemaProperty {
    name: string;
    label: string;
    component: FieldsEnum;
    validations?: ValidationType;
    primary?: boolean;
    required?: boolean;
    editable?: boolean;
    permissions?: {
        level: string;
        meta?: any;
    };
    attributes?: any;
    isTemplateField?: boolean;
    views?: {
        hide_in_create_view: boolean;
        hide_in_table_view: boolean;
        hide_in_record_detail_view: boolean;
        readonly_in_create_view: boolean;
    };
    disabled?: boolean;
}

export type UIConfig = {
    table_icon?: string;
    allow_create_record?: boolean;
    allow_add_field?: boolean;
    last_sync_date?: string;
    allow_create_record_modal?: boolean;
    allow_create_record_inline?: boolean;
    allow_more_options?: boolean;
    show_metadata_columns?: boolean;
    empty_state?: TableEmptyStateProps;
    delete_confirmation_message?: string;
};
export type Schema = {
    schemaLabel: string;
    dataSource: string;
    schemaName: string;
    defaultLayout: string;
    layouts: string[];
    properties: SchemaProperty[];
    uiConfiguration?: UIConfig;
    meta_data?: {
        client_form_layout?: {
            hidden_fields?: string[]; // Array of strings for hidden fields
            read_only?: string[]; // Array of strings for read-only fields
            hidden_actions?: string[];
        };
    };
};

export interface FieldProperties extends SchemaProperty {
    isExpanded?: boolean;
    id: string;
    value?: InputType;
    isEditable: boolean;
    errorMessage?: string;
    onChange: (e: InputObj) => void;
    onEdit?: () => void;
    onEndEdit?: () => void;
    onCancel?: () => void;
    layout?: string;
    ariaLabel?: string;
    record?: any;
    record_id?: string;
    forwardedRef: React.RefObject<any>;
    className?: string;
    dataTestId: string;
}

export enum ModalType {
    Save = 'save',
    Delete = 'delete',
    Cancel = 'cancel',
    Update = 'Update',
}

export type FileAction = 'upload' | 'delete' | 'reset';
