import {Typography} from 'antd';
import moment from 'moment';

import {useAppContext} from '../../../AppContext';
import {DEFAULT_DATE_FORMAT} from '../../../constants/constants';
import {convertUtcToLocal} from '../../../utils/utils';
import {DateFieldProps} from '../Fields';

const {Text} = Typography;
const TEST_DATE = '01/13/2000';

export const DateText = ({attributes, fieldValue}: DateFieldProps) => {
    if (!fieldValue) return null;
    const {piletApi} = useAppContext();
    const userPreferences = piletApi.sf.piletUserContext.get().user.Preferences;
    const localDate = convertUtcToLocal(
        Number(fieldValue) * 1000,
        userPreferences?.TimeZoneOffsetMins
    );
    const dateValue = new Date(localDate.slice(0, -1));

    const fetchFormat = () => {
        const initialFormat = attributes?.format ?? DEFAULT_DATE_FORMAT;
        const formattedDateString = moment(new Date(TEST_DATE)).format(initialFormat);
        const parsedDate = moment(formattedDateString, initialFormat, true);
        return parsedDate.isValid() ? initialFormat : DEFAULT_DATE_FORMAT;
    };

    const formattedDate = moment(dateValue).format(fetchFormat());

    return <Text>{formattedDate}</Text>;
};
