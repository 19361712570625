import {getFeatureFlagValue} from '@citrite/feature-flags';
import {Api} from '@citrite/sf-api';
import {projectsExtensionSlots} from '@sharefiledev/projects-pilet';
import type {PiletApi} from '@sharefiledev/sharefile-appshell';

import {AsyncLazyTodosBlock} from './blocks';
import {AsyncLazyLocalPage, AsyncLazyProjectTabExtension} from './components/Extensions';
// import {AsyncBlockDevelopment} from './development';
import {FeatureFlag} from './featureFlags';
import {setLogger} from './logger';

export const setup = (app: PiletApi) => {
    setLogger(app.sf.getLogger());

    app.sf.registerSfApiConfigHandler(Api.configure);
    registerApp(app);

    if (getFeatureFlagValue(FeatureFlag.EnableTasksUnderProjects)) {
        // tasks tab
        app.registerExtension(
            projectsExtensionSlots.tabsExtensionSlots.tabTasks,
            ({params}) => (<AsyncLazyProjectTabExtension piletApi={app} params={params} />) as any
        );

        // TODO: (SFCC-946) remove when we fully move to plugin specific extension slots
        app.registerExtension(
            projectsExtensionSlots.tabs,
            ({params}) => (<AsyncLazyProjectTabExtension piletApi={app} params={params} />) as any,
            {piletSortWeight: 100}
        );

        // TODO: Uncomment the below lines for enabling tile view inside projects tab
        // app.registerExtension(
        //     projectsExtensionSlots.homeTiles,
        //     ({params}) => (
        //         // TODO: Create new component
        //         <Tasks projectId={params} tabPath={params.getTabPath('tasks')} />
        //     ),
        //     {piletSortWeight: 100}
        // );
    }

    if (process.env.NODE_ENV === 'development') {
        app.sf.registerLeftNavComponent({
            href: '/todos-local',
            title: () => 'Todos Local',
            icon: () => null,
        });

        app.registerPage('/todos-local', () => (<AsyncLazyLocalPage piletApi={app} />) as any);
        // Commenting for now to pass build failure related to @sharefiledev/solution-view-engine/BlockBuilder, currently there is no impact

        // app.registerPage('/todos-dev', () => (<AsyncBlockDevelopment piletApi={app} />) as any);
    }
};

const registerApp = (app: PiletApi) => {
    app.registerExtension(
        'urn:sfblock:todos:list',
        ({params}) => (<AsyncLazyTodosBlock containerRID={params.containerRID} app={app} />) as any
    );
};
