import {RID} from '@sharefiledev/sfplugin-resource-id';
import dayjs from 'dayjs';

import {RECORD_ID_COMPONENTS, RICH_TEXT_ID} from '../constants/constants';
import {Schema, SchemaProperty} from '../engine/types';
import {FormState, RecordQueryParam} from '../model/types';
import {t} from './translate';

export const getTableId = (rid: string): string => {
    return rid.split('/').pop() ?? '';
};

/**
 * This function returns table ID from the Record RID.
 * @param recordRId conatins project id, table id, record id.
 * @returns tableRID.
 */
export const getTableRIDFromRecordRID = (recordRID: string): string => {
    // Find the last occurrence of '/' in the string
    const index = recordRID.lastIndexOf('/');
    return recordRID.slice(0, index);
};

export const getParentResourceRID = (resourceRID: string): string => {
    return RID.parse(resourceRID)['parentResourceId'];
};

export const hasEmptyFileFields = (tableSchema: Schema, record: any): boolean => {
    const properties = tableSchema.properties;
    const hasEmptyFile = properties.some((property) => {
        return record[property.name] == null && property.component == 'FileStorageField';
    });
    return hasEmptyFile;
};

export const newColumnAdded = (newSchemaProps: any, oldSchemaProps: any): any => {
    const oldFieldNames = oldSchemaProps.map((obj: any) => obj.name);
    return newSchemaProps.filter((obj: any) => !oldFieldNames.includes(obj.name));
};

export const getRecordLabel = (tableData: any): string => {
    const recordLabel = tableData?.schema?.recordLabel || t('dynamic-components:toaster.row');

    if (recordLabel?.trim()) {
        return recordLabel.charAt(0).toUpperCase() + recordLabel.slice(1).toLowerCase();
    }

    return '';
};

/**
 * This function determines if a table is empty based on what records it contains
 * @param records an array of record objects
 * @returns boolean
 */
export const isTableEmpty = (
    records: any[],
    queryParams: RecordQueryParam,
    isLoadingRecords: boolean
): boolean => {
    if (queryParams?.filter) {
        if (queryParams.filter !== '{}') return false;
        if (isLoadingRecords) return false;
    }

    if (records.length === 0) return true;

    if (records.length === 1 && records[0]._id === 'addrecord') return true;

    return false;
};

/**
 * This function determines if a filtered table is empty based on the number of records it contains
 * @param records an array of record objects
 * @param queryParams RecordQueryParam
 * @param isLoadingRecords boolean
 * @returns boolean
 */
export const checkFilteredTableEmpty = (
    records: any[],
    queryParams: RecordQueryParam,
    isLoadingRecords: boolean
): boolean => {
    if (isLoadingRecords) return false; // irrespective of filter
    if (queryParams?.filter && queryParams.filter !== '{}') {
        if (records.length === 0) return true;
        if (records.length === 1 && records[0]._id === 'addrecord') return true;
    }
    return false;
};

/**
 * This function determines the customised names for title and action buttons of record
 * @param formState string determining the state of form sidesheet
 * @param tableData table's data
 * @returns object
 */
export const customizedRecord = (formState: string, tableData: any) => {
    const isUpdateMode = formState == FormState.UPDATE;
    const customizedTitle = isUpdateMode
        ? `${t('dynamic-components:form.title.update')} ${tableData?.schema.recordLabel ?? ''}`
        : `${t('dynamic-components:form.title.create')} ${tableData?.schema.recordLabel ?? ''}`;

    const customizedSaveButton = isUpdateMode
        ? tableData?.schema?.uiConfiguration?.form_layout_config?.details_form_layout_config
              ?.create_button_name ?? t('dynamic-components:form.button.submit')
        : tableData?.schema?.uiConfiguration?.form_layout_config?.create_form_layout_config
              ?.create_button_name ?? t('dynamic-components:form.button.submit');

    const customizedCancelButton = isUpdateMode
        ? tableData?.schema?.uiConfiguration?.form_layout_config?.details_form_layout_config
              ?.cancel_button_name ?? t('dynamic-components:form.button.cancel')
        : tableData?.schema?.uiConfiguration?.form_layout_config?.create_form_layout_config
              ?.cancel_button_name ?? t('dynamic-components:form.button.cancel');

    // const customizedDeleteButton = isUpdateMode
    //     ? tableData?.schema?.uiConfiguration?.form_layout_config?.details_form_layout_config
    //           ?.delete_button_name ?? t('dynamic-components:form.button.delete')
    //     : tableData?.schema?.uiConfiguration?.form_layout_config?.create_form_layout_config
    //           ?.delete_button_name ?? t('dynamic-components:form.button.delete');
    const customizedCancelButtonId = isUpdateMode
        ? 'cancel-recordUpdate-btn'
        : 'cancel-recordCreate-btn';
    const customizedSaveButtonId = isUpdateMode ? 'save-recordUpdate-btn' : 'save-recordCreate-btn';
    const showFormLabelIcon =
        tableData?.schema?.uiConfiguration?.form_layout_config?.show_field_icon;

    return {
        customizedTitle,
        customizedSaveButton,
        customizedCancelButton,
        // customizedDeleteButton,
        customizedCancelButtonId,
        customizedSaveButtonId,
        showFormLabelIcon,
    };
};

/**
 * This function determines whether record id api is required or not.
 * @param tableData data table schema
 * @returns string
 */
export const customizeCreateTableButton = (tableData: any): string => {
    const customCreateRowButton =
        tableData?.schema?.uiConfiguration?.grid_layout_config?.create_button_name ??
        t('dynamic-components:tableLabels.createRow');
    return customCreateRowButton;
};

/**
 * This function determines whether record id api is required or not.
 * @param properties an array of table properties
 * @returns boolean
 */
export const isRecordIdRequired = (properties: any[]): boolean => {
    return properties.some((item: any) => RECORD_ID_COMPONENTS.includes(item.component));
};

export const fetchRichTextComponent = (properties: any[]): string[] => {
    const filteredData = properties.filter((field: any) => field.component == RICH_TEXT_ID);
    const filteredNames = filteredData.map((item) => item.name);
    return filteredNames;
};

export const formatCurrencyInput = (inputValue: any, decimalValue: any) => {
    // Handle null case explicitly
    if (!inputValue) {
        return undefined;
    }
    // Split number into integer and decimal parts
    const [integerPart, decimalPart = ''] = inputValue.toString().split('.');
    // Handle decimal padding and truncation
    const paddedDecimalPart = decimalPart.padEnd(decimalValue, '0').slice(0, decimalValue);
    return `${integerPart}.${paddedDecimalPart}`;
};

/**
 * This function convert UTC time to local timezone as per user preferences
 * @param utcTime UTC date time
 * @param timeZoneOffsetMins  timezoneoffset as per user prefernces
 * @returns string
 */

export const convertUtcToLocal = (utcTime: any, timeZoneOffsetMins: any) => {
    if (utcTime == null) {
        utcTime = Date.now();
    }
    const localDate = new Date(utcTime + timeZoneOffsetMins * 60000);
    return localDate.toJSON();
};

/**
 * This function convert Local time to UTC timezone as per user preferences
 * @param utcTime UTC date time
 * @param timeZoneOffsetMins  timezoneoffset as per user prefernces
 * @returns string
 */
export const convertLocalToUTC = (utcTime: any, timeZoneOffsetMins: any) => {
    return new Date(utcTime - timeZoneOffsetMins * 60000);
};

/**
 * This function adjusts the given datestring (of format MM/DD/YYY) with the
 * given timeZoneOffset (in mins) and returns the timestamp.
 * @param dateString string representing date, ex: '12/30/2024'
 * @param timeZoneOffsetMins number of minutes offset from UTC (set as per user timezone preference)
 * @returns number representing timestamp
 */
export const getAdjustedTimestamp = (dateString: any, timeZoneOffsetMins: any) => {
    const parts = dateString.split('/');
    const date = new Date(Date.UTC(parseInt(parts[2]), parseInt(parts[0]) - 1, parseInt(parts[1])));
    const utcDate = convertLocalToUTC(date.getTime(), timeZoneOffsetMins);
    const timestamp = dayjs(utcDate).unix();
    return timestamp;
};

/**
 * This function set manipulate the response if frozen flag is set for project
 * @param table
 * @returns object
 */
export const setFrozen = (table: any) => {
    if (!table || !table.schema) {
        return;
    }

    // 1. Update properties: set `editable` to false
    const updatedProperties = table.schema.properties.map((property: SchemaProperty) => ({
        ...property,
        editable: false,
    }));

    // 2. Update `uiConfiguration`: set permissions to false
    const updatedConfig = {
        ...table.schema.uiConfiguration,
        allow_create_record: false,
        allow_create_record_modal: false,
        allow_create_record_inline: false,
        allow_add_field: false,
        allow_more_options: false,
    };

    // 3. Update the schema
    const updatedSchema = {
        ...table.schema,
        properties: updatedProperties,
        uiConfiguration: updatedConfig,
    };

    return {
        ...table,
        schema: updatedSchema,
    };
};

// export const isRecordsEmpty = (records: any[]) => {
//     return records.filter((record) => record._id !== "addrecord" ).length === 1
// }
