import {
    ClientInformationRequest,
    InformationRequest,
    InformationResponsePayload,
    ViewType,
} from '../components/common/types';
import {shortRequestURISegment, shortResponseURISegment} from '../config/constants';
import ApiClient from './apiClient';
import {
    CreateRequestPayload,
    DataProvider,
    DuplicateRequestPayload,
    SetAssigneePayload,
    UpdateMetaRequestPayload,
    UpdateSchemaRequestPayload,
} from './types';
import {encodedRID} from './utils';
// TODO:
//  - add constants to use inforeq and infores instead of information_request and information_response
//      (respectively) in the api calls
// Information Requests

/**
 * Get information requests
 * GET /information-requests
 * params: //TODO
 * return: // array of information requests
 */
const getInformationRequests = async (
    containerId: string,
    view: ViewType = 'mini',
    isDeleted: boolean = false,
    take?: number,
    skip?: number,
) => {
    try {
        const searchParams = new URLSearchParams();

        // Append view parameter if provided
        if (view) {
            searchParams.append('view', view);
        }

        // Append isDeleted parameter if provided
        if (isDeleted) {
            searchParams.append('isDeleted', isDeleted.toString());
        }

        if (take) {
            searchParams.append('take', take.toString());
        }

        if (skip) {
            searchParams.append('skip', skip.toString());
        }

        // Convert searchParams to string and append it to the base URL
        const queryString = searchParams.toString();
        const url = `/${shortRequestURISegment}/${encodedRID(containerId)}/list${
            queryString ? `?${queryString}` : ''
        }`;

        const {data} = await ApiClient.get(url);

        return data.items;
    } catch (error) {
        throw new Error(`Error occured in requesting information requests: ${error}`);
    }
};

const getInformationRequestsByUser = async (
    userRID: string,
    containerRID?: string,
    skip?: number,
    limit?: number,
) => {
    try {
        const searchParams = new URLSearchParams();

        searchParams.append('assignedUserRID', userRID);

        if (containerRID) {
            searchParams.append('containerRID', containerRID);
        }

        if (skip) {
            searchParams.append('skip', skip.toString());
        }

        if (limit) {
            searchParams.append('limit', limit.toString());
        }

        const queryString = searchParams.toString();
        const url = `/ecosystem/tasks${queryString ? `?${queryString}` : ''}`;

        const {data} = await ApiClient.get(url);

        return data as ClientInformationRequest[];
    } catch (error) {
        throw new Error(`Error occurred in requesting information requests by user: ${error}`);
    }
};

/**
 * Get information request by information request id
 * GET /information-requests/{IR_RID}
 * params: //TODO
 * return: // an information request
 */
const getInformationRequest = async (informationRequestRID: string) => {
    try {
        const {data} = await ApiClient.get<InformationRequest>(
            `/${shortRequestURISegment}/${encodedRID(informationRequestRID)}`,
        );

        return data;
    } catch (error) {
        throw new Error(`Error getting information request: ${error}`);
    }
};

/**
 * Create
 * POST /information-requests
 * body: //TODO {}
 * return: // RID of information request
 */
const createInformationRequest = async (payload: CreateRequestPayload) => {
    try {
        const informationRequest = await ApiClient.post(`/${shortRequestURISegment}/`, payload);

        return informationRequest;
    } catch (error) {
        throw new Error(`Error creating information request: ${error}`);
    }
};
/**
 * PUT /information-requests/{IR_RID}/schema
 * body: //TODO {}
 * return: // array of information requests
 */

const updateInformationRequestSchema = async (
    informationRequestRID: string,
    payload: UpdateSchemaRequestPayload,
) => {
    try {
        const informationRequest = await ApiClient.put(
            `/${shortRequestURISegment}/${encodedRID(informationRequestRID)}/schema`,
            payload,
        );

        return informationRequest;
    } catch (error) {
        throw new Error(`Error updating information request: ${error}`);
    }
};

/**
 * Update meta data of a information request
 * PUT /information-response/{IR_RID}/meta
 * body: {name, description, duedate, optional thankYouMessage }
 * return: // success message
 */
const updateInformationRequestMeta = async (
    informationRequestRID: string,
    payload: UpdateMetaRequestPayload,
) => {
    try {
        const informationRequest = await ApiClient.put(
            `/${shortRequestURISegment}/${encodedRID(informationRequestRID)}/meta`,
            payload,
        );

        return informationRequest;
    } catch (error) {
        throw new Error(`Error updating information request meta: ${error}`);
    }
};

/**
 * Fetch users with containerRID and subdomain
 * GET /users/{CONTAINER_RID}/list
 * return: // array of users
 */
const getUsers = async (containerID: string, subdomain: string) => {
    try {
        const {data} = await ApiClient.get(`/users/${encodedRID(containerID)}/list`, {
            params: {
                subdomain: subdomain,
            },
        });

        return data.users;
    } catch (error) {
        throw new Error(`Error getting users: ${error}`);
    }
};

/**
 * Assign an assignee to an information request
 * PUT /information-request/{informationRequestRID}/assignee
 * body: //TODO {}
 * return: // array of information requests
 */
const setAssigneeToInformationRequest = async (
    informationRequestRID: string,
    payload: SetAssigneePayload,
) => {
    try {
        const response = await ApiClient.put(
            `/${shortRequestURISegment}/${informationRequestRID}/assignee`,
            payload,
        );

        return response;
    } catch (error) {
        throw new Error(`Error setting assignees to information request: ${error}`);
    }
};

// Information Responses

/**
 * Get information requests
 * GET /information-requests
 * params: //TODO
 * return: // array of information requests
 */
const getInformationResponses = async (containerId: string) => {
    try {
        const {data} = await ApiClient.get(
            `/${shortResponseURISegment}/${encodedRID(containerId)}/list`,
        );
        return data.responseList;
    } catch (error) {
        throw new Error(`Error occured in requesting information requests: ${error}`);
    }
};

/**
 * Get information response for one user for one information request
 * GET /information-response/{informationRequestRID}/response
 * body: //TODO
 * return: // information response
 */
const getInformationResponse = async (informationRequestRID: string) => {
    try {
        const {data} = await ApiClient.get(
            `/${shortResponseURISegment}/${encodedRID(informationRequestRID)}/response`,
        );
        return data;
    } catch (error) {
        throw new Error(`Error occured in fetching information response: ${error}`);
    }
};

/**
 * Gets an information-response for a given RID
 * GET /information-response/{informationResponseRID}
 * return: // information response
 */
const getInformationResponseByResponseRID = async (informationResponseRID: string) => {
    try {
        const {data} = await ApiClient.get(
            `/${shortResponseURISegment}/${encodedRID(informationResponseRID)}`,
        );
        return data;
    } catch (error) {
        throw new Error(
            `Error occured in fetching information response using informationResponseRID: ${error}`,
        );
    }
};

/**
 * Create an information response
 * POST /information-response/
 * body: //TODO {}
 * return: // RID of information response
 */
const createInformationResponse = async (
    payload: InformationResponsePayload,
    signal?: AbortSignal,
) => {
    try {
        const informationResponse = await ApiClient.post(`/${shortResponseURISegment}/`, payload, {
            signal,
        });
        return informationResponse;
    } catch (error) {
        throw new Error(`Error creating information response: ${error}`);
    }
};

/**
 * Remove an assignee from an information request
 * DELETE /information-request/{informationRequestRID}/assignee/{assigneeID}
 * return: // success/failure for API call
 */
const deleteAssigneeFromInformationRequest = async (
    informationRequestRID: string,
    assigneeId: string,
) => {
    try {
        const response = await ApiClient.delete(
            `/${shortRequestURISegment}/${encodedRID(
                informationRequestRID,
            )}/assignee/${assigneeId}`,
        );

        return response;
    } catch (error) {
        throw new Error(`Error deleting assignee from information request: ${error}`);
    }
};

/**
 * Deletes an information request for given RID
 * DELETE /information-requests/{IR_RID}
 * return: // a string
 */
const deleteInformationRequest = async (informationRequestRID: string) => {
    try {
        const informationRequest = await ApiClient.delete(
            `/${shortRequestURISegment}/${encodedRID(informationRequestRID)}`,
        );
        return informationRequest;
    } catch (error) {
        throw new Error(`Error deleting information request: ${error}`);
    }
};

/**
 * Closes/Disables an information-request for a given RID
 * PUT /information-requests/{IR_RID}/close
 * return: // a string
 */
const closeInformationRequest = async (informationRequestRID: string, closureMessage?: string) => {
    try {
        const requestBody = closureMessage ? {closureMessage} : {};
        const informationRequest = await ApiClient.put(
            `/${shortRequestURISegment}/${encodedRID(informationRequestRID)}/close`,
            requestBody,
        );
        return informationRequest;
    } catch (error) {
        throw new Error(`Error closing information request: ${error}`);
    }
};

/**
 * Duplicate
 * POST /information-requests/{IR_RID}/clone
 * return: // RID of information request
 */
const duplicateInformationRequest = async (
    informationRequestRID: string,
    payload: DuplicateRequestPayload,
) => {
    try {
        const informationRequest = await ApiClient.post(
            `/${shortRequestURISegment}/${encodedRID(informationRequestRID)}/clone`,
            payload,
        );
        return informationRequest;
    } catch (error) {
        throw new Error(`Error duplicating information request: ${error}`);
    }
};

/**
 * Restores an information request for given RID
 * PUT /information-request/{IR_RID}/restore
 * return: // a string
 */
const restoreInformationRequest = async (informationRequestRID: string) => {
    try {
        const informationRequest = await ApiClient.put(
            `/${shortRequestURISegment}/${encodedRID(informationRequestRID)}/restore`,
        );
        return informationRequest;
    } catch (error) {
        throw new Error(`Error restoring information request: ${error}`);
    }
};

/**
 * Sends email reminder for all assignees of an information-request for a given RID
 * POST /information-requests/{IR_RID}/reminder
 * return: // a string
 */
const remindAssigneesInformationRequest = async (
    informationRequestRID: string,
    reminderMessage?: string,
) => {
    try {
        const requestBody = reminderMessage ? {reminderMessage} : {};
        const informationRequest = await ApiClient.post(
            `/${shortRequestURISegment}/${encodedRID(informationRequestRID)}/reminder`,
            requestBody,
        );
        return informationRequest;
    } catch (error) {
        throw new Error(`Error sending reminders for information request: ${error}`);
    }
};

/**
 * Fetch responses for the searched query
 * GET /information-response/{informationRequestRID}/response/search?q=keyword
 * return: list of related search results
 */

const getConnectedEntityData = async (
    connectedEntityRID: string,
    searchTerm: string,
    abortController?: AbortController,
) => {
    try {
        if (searchTerm !== '') {
            const {data} = await ApiClient.get(
                `/${shortResponseURISegment}/${encodedRID(
                    connectedEntityRID,
                )}/response/search?q=${searchTerm}`,
                {
                    signal: abortController?.signal,
                },
            );
            return data;
        }
    } catch (error) {
        throw new Error(`Error occured in fetching responses for the searched query: ${error}`);
    }
};

/**
 * Get information responses as CSV
 * GET /inforeq/{IR_RID}/export
 * return: CSV file
 */
const exportInformationResponses = async (informationRequestRID: string) => {
    try {
        const response = await ApiClient.get(
            `/${shortRequestURISegment}/${encodedRID(informationRequestRID)}/export`,
            {
                responseType: 'blob',
            },
        );

        const contentDisposition = response.headers['content-disposition'];
        const filenameMatch = contentDisposition.split('filename=');
        const filename = filenameMatch[1] || 'download.csv'; // Fallback filename
        const fileResponse = {
            filename,
            content: response.data,
        };
        return fileResponse;
    } catch (error) {
        throw new Error(`Error fetching information response`);
    }
};

export const defaultDataProvider: DataProvider = {
    getInformationRequests,
    getInformationRequestsByUser,
    getInformationRequest,
    createInformationRequest,
    updateInformationRequestSchema,
    updateInformationRequestMeta,
    getUsers,
    setAssigneeToInformationRequest,
    getInformationResponses,
    getInformationResponse,
    getInformationResponseByResponseRID,
    createInformationResponse,
    deleteAssigneeFromInformationRequest,
    deleteInformationRequest,
    closeInformationRequest,
    duplicateInformationRequest,
    restoreInformationRequest,
    remindAssigneesInformationRequest,
    getConnectedEntityData,
    exportInformationResponses,
};
