import {DatePicker} from 'antd';
import {RangePickerProps} from 'antd/es/date-picker';
import dayjs from 'dayjs';
import {isNumber} from 'lodash';
import {useState} from 'react';

import {DEFAULT_DATE_FORMAT} from '../../../constants/constants';
import {convertLocalToUTC, convertUtcToLocal} from '../../../utils/utils';
import {DateFieldProps} from '../Fields';
import {useFieldOperations} from '../hooks/useFieldOperations';

export const DatePickerField = ({
    dateFormat,
    disabled,
    fieldValue,
    required,
    validations,
    forwardedRef,
    onChange,
    onCancel,
    className,
    dataTestId,
    timeZoneOffsetMins,
    fieldType,
}: DateFieldProps) => {
    let dateValue: any;
    if (!fieldValue) {
        dateValue = null;
    } else {
        const localDate = convertUtcToLocal(Number(fieldValue) * 1000, timeZoneOffsetMins);
        dateValue = dayjs(localDate.slice(0, -1));
    }
    const {inputValue, handleOnChange, isError, handleOnBlur, handleKeyDown} = useFieldOperations(
        fieldType,
        dateValue,
        required,
        validations,
        onChange,
        onCancel
    );
    const DATE_FORMAT = dateFormat ?? DEFAULT_DATE_FORMAT;
    const [isOpen, setIsOpen] = useState(false);
    const handleChange = (date: any) => {
        if (!date) handleOnChange(date);
        const localDateTime = convertUtcToLocal(null, timeZoneOffsetMins);
        const nowDate = new Date(localDateTime.slice(0, -1));
        const convertedDate = new Date(
            Date.UTC(
                date.year(),
                date.month(),
                date.date(),
                nowDate.getHours(),
                nowDate.getMinutes(),
                nowDate.getSeconds()
            )
        );
        const localDate = convertLocalToUTC(convertedDate.getTime(), timeZoneOffsetMins);
        const newDate = dayjs(localDate).unix();
        handleOnChange(newDate);
        onChange(newDate, !isError);
    };

    const handleOnKeyDown = (event: React.KeyboardEvent<any>) => {
        if (isOpen && event.key === 'Escape') {
            event.stopPropagation();
            onCancel(true);
        }
        !isOpen && handleKeyDown(event);
    };

    const handleDisabledDate: RangePickerProps['disabledDate'] = (current: dayjs.Dayjs) => {
        if (!current) {
            return false;
        }
        const localDateTime = convertUtcToLocal(null, timeZoneOffsetMins);
        const dateTimeWithoutZ = localDateTime.slice(0, -1);
        const currentTimestamp = current.unix();
        const minDate = typeof validations?.minDate === 'number' && dayjs.unix(validations.minDate);
        const maxDate = typeof validations?.maxDate === 'number' && dayjs.unix(validations.maxDate);
        const pastDays = validations?.pastDays;
        const futureDays = validations?.futureDays;

        // Calculate allowed date range based on pastDays and futureDays
        const allowedPastDays =
            isNumber(pastDays) &&
            dayjs(dateTimeWithoutZ)
                .subtract(Math.abs(pastDays) + 1, 'days')
                .unix();
        const allowedFutureDays =
            isNumber(futureDays) && dayjs(dateTimeWithoutZ).add(futureDays, 'days').unix();

        // Disable dates outside the allowed range
        if (allowedPastDays && currentTimestamp < allowedPastDays) {
            return true;
        }
        if (allowedFutureDays && currentTimestamp > allowedFutureDays) {
            return true;
        }
        if (minDate && current.isBefore(minDate, 'day')) {
            return true;
        }
        if (maxDate && current.isAfter(maxDate, 'day')) {
            return true;
        }
        return false;
    };

    const handleOnOpenChange = (open: boolean) => {
        if (!open && isOpen) {
            onCancel(true);
        }
        setIsOpen(true);
    };

    return (
        <DatePicker
            className={className}
            defaultValue={inputValue}
            format={DATE_FORMAT}
            onChange={handleChange}
            required={required}
            style={{flexGrow: 1}}
            autoFocus
            ref={forwardedRef}
            disabledDate={handleDisabledDate}
            disabled={disabled}
            onOpenChange={handleOnOpenChange}
            data-testid={dataTestId}
            onKeyDown={handleOnKeyDown}
            onBlur={isOpen ? undefined : handleOnBlur}
        />
    );
};
