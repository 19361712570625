import {OverlayModalComponentProps} from '@sharefiledev/sharefile-appshell';
import {Skeleton} from 'antd';
import {lazy, Suspense} from 'react';

import {NotificationProvider} from '../utils/notification/useNotification';

const LazyCreateTableModal = lazy(() => import('../overlays/create-table/CreateTableModal'));

export const AsyncLazyCreateTableModal: React.FC<
    OverlayModalComponentProps<'urn:sfmodal:dc-pilet:createtable'>
> = (props) => (
    <Suspense fallback={<Skeleton />}>
        <NotificationProvider>
            <LazyCreateTableModal {...props} />
        </NotificationProvider>
    </Suspense>
);
