import {Expand, Shrink} from '@sharefiledev/icons';
import {Modal} from 'antd';
import _ from 'lodash';
import {useRef, useState} from 'react';

import {t} from '../../../../../utils';
import {useRichText} from '../../../../table/context/RichText';
import {InputFieldProps} from '../../../Fields';
import {createUniqueId, fetchCurrentField} from '../helper';
import RichTextEditor from '../index';
import {ExpandedModal, FooterWithinModal, FooterWrapper} from '../SFLexicalRichTextEditor.styled';
import {ToolbarButton, updateTextEditorFields} from './helper';

interface FooterProp {
    editorProp: InputFieldProps;
    isExpanded?: boolean;
}

const Footer = ({editorProp}: FooterProp) => {
    const richTextRef = useRef<any>(null);
    const [visible, setVisible] = useState(false);
    const {textEditors, setTextEditors, setReload} = useRichText();
    const [richTextProp, setRichTextProp] = useState<any>(null);
    const {name, record} = editorProp;
    const uniqueID = createUniqueId(name, record?._id);

    const updateWithNewState = (mode: 'open' | 'close') => {
        const tempProp = {...editorProp, isExpanded: true};
        const existingEditorValue = fetchCurrentField(textEditors, uniqueID);
        setRichTextProp({...tempProp, value: existingEditorValue?.rtFieldValue});
        if (mode === 'close' && existingEditorValue) {
            setTextEditors((prevState) =>
                updateTextEditorFields(prevState, uniqueID, {...existingEditorValue.rtFieldValue})
            );
            setReload(true);
        }
    };

    const handleClick = () => {
        updateWithNewState('open');
        setVisible(true);
    };

    const handleClose = () => {
        updateWithNewState('close');
        setVisible(false);
    };

    return (
        <>
            {!editorProp.isExpanded && (
                <FooterWrapper>
                    <ToolbarButton
                        onClickEvent={handleClick}
                        isSelected={false}
                        ariaLabel={t('dynamic-components:richText.areaLabel.expand')}
                        icon={
                            <>
                                <Expand size={20} />{' '}
                                <span>{t('dynamic-components:richText.format.expand')}</span>
                            </>
                        }
                        isDisabled={false}
                        className="toolbar-item"
                        title={t('dynamic-components:richText.title.expand')}
                    />
                </FooterWrapper>
            )}
            {visible && (
                <Modal
                    title=""
                    open={visible}
                    width="100%"
                    style={{top: 8}}
                    footer={null}
                    closable={false}
                    onCancel={handleClose}
                    className="rich-text-modal"
                    afterOpenChange={(open) => open && richTextRef.current.focus()}
                >
                    <ExpandedModal>
                        <RichTextEditor {..._.cloneDeep(richTextProp)} forwardedRef={richTextRef} />
                    </ExpandedModal>
                    <FooterWithinModal>
                        <ToolbarButton
                            onClickEvent={handleClose}
                            isSelected={false}
                            ariaLabel={t('dynamic-components:richText.areaLabel.shrink')}
                            icon={
                                <>
                                    <Shrink size={20} />
                                    <span>{t('dynamic-components:richText.format.shrink')}</span>
                                </>
                            }
                            isDisabled={false}
                            className="toolbar-item"
                            title={t('dynamic-components:richText.title.shrink')}
                        />
                    </FooterWithinModal>
                </Modal>
            )}
        </>
    );
};

export default Footer;
