import {Input} from 'antd';

import {InputFieldProps} from '../Fields';
import {useFieldOperations} from '../hooks/useFieldOperations';

export const InputField = ({
    forwardedRef,
    fieldValue,
    onChange,
    onCancel,
    required,
    validations,
    className,
    dataTestId,
    fieldType,
    disabled,
}: InputFieldProps) => {
    const {inputValue, handleOnChange, isError, handleOnBlur, handleKeyDown} = useFieldOperations(
        fieldType,
        fieldValue,
        required,
        validations,
        onChange,
        onCancel
    );

    return (
        <Input
            value={inputValue}
            onChange={(event) => handleOnChange(event.target.value)}
            onBlur={handleOnBlur}
            onKeyDown={handleKeyDown}
            ref={forwardedRef}
            autoFocus
            status={isError ? 'error' : ''}
            className={className}
            data-testid={dataTestId}
            disabled={disabled}
        />
    );
};
