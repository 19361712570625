import {TabContextProps} from '@sharefiledev/flow-web';
import type {} from '@sharefiledev/sharefile-appshell'; // force tsc to resolve this package

const prefix = 'urn';
const namespace = 'sfextslot';
const owner = 'dc-pilet';
const modalNamespace = 'sfmodal';

export const dataTablesExtensionSlots = {
    projectTab: `${prefix}:${namespace}:${owner}:project:tab:data-tables` as const,
    dataTable: `${prefix}:${namespace}:${owner}:data-table` as const,
};

export const piletOverlays = {
    createTableModal: `${prefix}:${modalNamespace}:${owner}:createtable` as const, //urn:sfmodal:dc-pilet:createtable
    clinetViewRequestRecordViewDrawer: 'urn:sfdrawer:task-aggregator:client:datatable' as const,
    viewRequestRecordViewDrawer: 'urn:sfdrawer:task-aggregator:datatable' as const,
};

type FlowWebTabExtensionImplementationParams<TParams> = TParams & {
    tabContext: TabContextProps;
    piletSortWeight?: number;
};

export type ProjectDataTablesTabParams = FlowWebTabExtensionImplementationParams<{
    projectUri: string;
}>;
export interface VerticalizationParams {
    verticalContextKey?: string;
}
interface RequestTableRecordViewDrawerOptions extends VerticalizationParams {
    containerRID: string;
    readonly?: boolean;
}

export type {RequestTableRecordViewDrawerOptions};

export type ClientRequestListDrawerOptions = {
    params: {containerRID: string};
};

export type CreateTableModalParams = {
    containerRID: string;
};

export interface CreateTableSuccessReturnData {
    tableName: string;
    containerRID: string;
    tableRID: string;
}

declare module '@sharefiledev/sharefile-appshell' {
    interface PiralCustomExtensionSlotMap {
        [dataTablesExtensionSlots.projectTab]: ProjectDataTablesTabParams;
        //any should replace with exact type.
        [dataTablesExtensionSlots.dataTable]: any;
    }
    interface PiralCustomOverlayOptionsMap {
        [piletOverlays.viewRequestRecordViewDrawer]: RequestTableRecordViewDrawerOptions;
        [piletOverlays.clinetViewRequestRecordViewDrawer]: ClientRequestListDrawerOptions;
        [piletOverlays.createTableModal]: CreateTableModalParams;
    }

    interface PiralCustomOverlayDataMap {
        [piletOverlays.createTableModal]: CreateTableSuccessReturnData;
    }
}
