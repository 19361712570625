import {FieldsEnum} from '../../constants/fields';
import {Layout, UIMapperType, Variant} from '../../engine/types';
import {ContactMultiSelectField} from '../Fields/Edit/ContactMultiSelect';
import {CurrencyInputField} from '../Fields/Edit/Currency';
import {DatePickerField} from '../Fields/Edit/Date';
import {FileInputInline} from '../Fields/Edit/FileInline';
import {InputField} from '../Fields/Edit/InputField';
import {NumberField} from '../Fields/Edit/Number';
import {RichTextInputField} from '../Fields/Edit/RichTextInline';
import {SingleSelect} from '../Fields/Edit/Select';
import {TextAreaField} from '../Fields/Edit/TextArea';
import {AvatarViewField} from '../Fields/View/AvatarView';
import {CurrencyField} from '../Fields/View/Currency';
import {DateText} from '../Fields/View/Date';
import {FileField} from '../Fields/View/File';
import {LinkField} from '../Fields/View/Link';
import {RichTextField} from '../Fields/View/RichText';
import {SingleSelectText} from '../Fields/View/Select';
import {TextField} from '../Fields/View/TextField';

const FieldMapper: UIMapperType = {
    [Layout.Table]: {
        [Variant.View]: {
            [FieldsEnum.TextInputField]: TextField,
            [FieldsEnum.TextAreaField]: TextField,
            [FieldsEnum.Select]: SingleSelectText,
            [FieldsEnum.DateField]: DateText,
            [FieldsEnum.DateOverdueField]: DateText,
            [FieldsEnum.AccountUserMultiSelect]: AvatarViewField,
            [FieldsEnum.NumericField]: TextField,
            [FieldsEnum.LinkField]: LinkField,
            [FieldsEnum.CurrencyField]: CurrencyField,
            [FieldsEnum.FileStorageField]: FileField,
            [FieldsEnum.RichTextEditorField]: RichTextField,
            [FieldsEnum.AssigneeField]: AvatarViewField,
        },
        [Variant.Edit]: {
            [FieldsEnum.TextInputField]: InputField,
            [FieldsEnum.TextAreaField]: TextAreaField,
            [FieldsEnum.Select]: SingleSelect,
            [FieldsEnum.SingleSelectField]: SingleSelect,
            [FieldsEnum.DateField]: DatePickerField,
            [FieldsEnum.DateOverdueField]: DatePickerField,
            [FieldsEnum.AccountUserMultiSelect]: ContactMultiSelectField,
            [FieldsEnum.NumericField]: NumberField,
            [FieldsEnum.LinkField]: InputField,
            [FieldsEnum.CurrencyField]: CurrencyInputField,
            [FieldsEnum.FileStorageField]: FileInputInline,
            [FieldsEnum.RichTextEditorField]: RichTextInputField,
            [FieldsEnum.AssigneeField]: ContactMultiSelectField,
        },
    },
};

export default FieldMapper;
