import {Tag} from 'antd';

import {COLORS} from '../../../constants/constants';
import {SelectFieldProps} from '../Fields';

export const SingleSelectText = ({attributes, fieldValue}: SelectFieldProps) => {
    const selectConfig = attributes.enumConfig;
    const activeStatus = fieldValue;

    const getBadge = (badgeType: string, children: React.ReactNode) => {
        if (badgeType) {
            return (
                <Tag
                    bordered={false}
                    style={{
                        background: COLORS[badgeType].background,
                        color: COLORS[badgeType].color,
                        display: 'inline-flex',
                    }}
                >
                    {children}
                </Tag>
            );
        }
        return null;
    };
    const currentSelectConfig = selectConfig![activeStatus];

    if (!currentSelectConfig) return null;

    return <>{getBadge(currentSelectConfig.badgeType, currentSelectConfig.displayName)}</>;
};
