const prefix = 'dataTablePilet_';

//track crud on data-table operations
export const trackCreateDataTable = `${prefix}createDataTable`;
export const trackUpdateDataTable = `${prefix}updateDataTable`;
export const trackdeleteDataTable = `${prefix}deleteDataTable`;
export const trackUpdateDataTableName = `${prefix}updateDataTableName`;
export const trackTypeColumnAdded = (columnType: string) => {
    return `${prefix}added[${columnType}]`;
};

//track crud on data-table record operations
export const trackCreateDataTableRecord = `${prefix}createDataTableRecord`;
export const trackUpdateDataTableRecord = `${prefix}updateDataTableRecord`;
export const trackdeleteDataTableRecord = `${prefix}deleteDataTableRecord`;

//track from which layout the records are gettig created.
export const trackEmptyStateCreateRecord = `${prefix}emptyStateCreateRecord`;
export const trackToolHeaderCreateRecord = `${prefix}toolHeaderCreateRecord`;

//track file uploads
export const trackUploadFileSuccess = `${prefix}fileUploadSuccess`;

// add new column as rich text
export const trackAddNewRichTextColumn = `${prefix}fieldAddedRichTextColumnSuccessfully`;

//track filter option on data-table
export const trackFilterDataTable = (filterInput: string) => {
    return `${prefix}Filter[${filterInput}]`;
};
