import {ContactListType} from '@citrite/sf-api';
import {Close} from '@sharefiledev/icons';
import {Select, Spin, Tag} from 'antd';
import {debounce} from 'lodash';
import React, {ReactNode, useCallback, useEffect, useState} from 'react';

import {loadAssigneeUsers} from '../../api/sfApi';
import {COLORS, REMOVE_TAG_BUTTON_CLASS} from '../../constants/constants';
import {FieldProperties, InputObj, TagRender} from '../../engine/types';
import {ContactOption} from '../../layouts/Fields/Edit/ContactOption';
import {StyledDropdown} from '../../layouts/table/DataTable.styled';
import {
    AssigneeMultiUserFieldWrapper,
    StyledSpanVerticalAlign,
    StyledTagRemoveBtn,
} from '../../schema-components/antd/edit/ContactMultiSelect/ContactMultiSelect.styled';
import {t} from '../../utils';
import {useContactSearch} from '../../utils/hooks/useContactSearch/useContactSearch';
import {addUsersToProjectFromRelatedResources} from '../../utils/projectManagement/addUsertoProject';
import {getParentResourceRID} from '../../utils/utils';

export type ContactOptionType = {
    label: ReactNode;
    value: string;
    details: any;
};
export interface ContactOptionProps {
    userType: string;
    firstName: string;
    lastName: string;
    email: string;
    id: string;
}
export interface ContactMultiSelectProps extends FieldProperties {
    validations?: {
        type: [string];
        limit?: {
            value: number;
            message: string;
        };
    };
    attributes?: {
        userGroup?: string;
        allowMultiSelect?: boolean;
    };
    isFilter?: boolean;
    userList?: Record<string, any>;
}

export const ContactMultiSelect: React.FC<ContactMultiSelectProps> = ({
    value,
    onChange,
    attributes,
    label,
    disabled,
    isFilter = false,
    record,
    forwardedRef,
    className,
    dataTestId,
    userList: users,
}) => {
    const [term, setTerm] = React.useState('');
    const [contactsList, setContactsList] = React.useState<ContactOptionType[]>([]);
    const [selectedContacts, setSelectedContacts] = React.useState<ContactOptionType[]>([]);
    const [loading, setIsLoading] = useState(true);
    const [initialContactsList, setInitialContactsList] = useState<ContactOptionProps[]>([]);
    const userList: Record<string, any> = users || [];
    const isFieldEmpty = () => !value || (Array.isArray(value) && value.length === 0);

    const {allowMultiSelect = true, userGroup = ContactListType.AllUsers} = attributes || {
        allowMultiSelect: true,
        userGroup: ContactListType.AllUsers,
    };
    const fetchUsers = async () => {
        let users: ContactOptionProps[];
        if (attributes && attributes.userGroup) {
            users = (await searchContacts('', userGroup ?? ContactListType.AllUsers)) ?? [];
        } else {
            users = Object.keys(userList).map((key) => {
                const [firstName, lastName] = userList[key].FullName.split(' ');
                return {
                    firstName: firstName || '',
                    lastName: lastName || '',
                    email: userList[key].Email,
                    id: userList[key].Id,
                    userType: userList[key].userType,
                };
            });
        }
        setInitialContactsList(users);
        setContactsList(initialContactsList.map(convertToOption));
        isFieldEmpty() && setIsLoading(false);
        return;
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    /**
     * This hook is using for reset the values
     * when user click clear in filter section
     */
    useEffect(() => {
        if (!value || (value && Array.isArray(value) && value.length === 0)) {
            setContactsList(initialContactsList.map(convertToOption));
            setSelectedContacts([]);
        }
    }, [value]);

    const loadAssignees = useCallback(async (ids: string[]) => {
        const result = await loadAssigneeUsers(ids);
        const contactOptions = result
            .map((contact: any) => ({
                firstName: contact.FirstName,
                lastName: contact.LastName,
                email: contact.Email,
                id: contact.Id,
                userType: contact.UserType,
            }))
            .map(convertToOption);
        setIsLoading(false);
        setSelectedContacts(contactOptions);
    }, []);

    useEffect(() => {
        if (value && Array.isArray(value) && value.length > 0) {
            loadAssignees(value);
        } else {
            setContactsList([]);
        }
    }, [loadAssignees]);
    useEffect(() => {
        if (term.length === 0) {
            setContactsList(initialContactsList.map(convertToOption));
        } else {
            userFind(term);
        }
    }, [term, initialContactsList]);

    const debouncedSearch = useCallback(
        debounce((value) => {
            setTerm(value);
        }, 500),
        [setTerm]
    );

    const {searchContacts} = useContactSearch();
    const userFind = (filterTerm: string) => {
        const contactOptions = initialContactsList
            .filter(
                (contact: ContactOptionProps) =>
                    contact.firstName.toLowerCase().includes(filterTerm.toLowerCase()) ||
                    contact.lastName.toLowerCase().includes(filterTerm.toLowerCase()) ||
                    contact.email.toLowerCase().includes(filterTerm.toLowerCase())
            )
            .map(convertToOption);
        setContactsList(contactOptions);
        return contactOptions;
    };

    const SelectedUserLabel: React.FC<any> = ({firstName, lastName, email}) => (
        <>{firstName || lastName ? `${firstName} ${lastName}` : email ?? ''}</>
    );

    const renderTag: TagRender = ({label, value, closable, onClose}) => {
        const contact = selectedContacts?.find(
            (option: ContactOptionType) => option.value === value
        )?.details;

        return (
            <Tag
                closeIcon={
                    <StyledTagRemoveBtn
                        className={REMOVE_TAG_BUTTON_CLASS}
                        aria-label={t(
                            'dynamic-components:manageField.fields.userMultiSelect.removeBtnAriaLabel',
                            {
                                userName: contact ? contact.email : '',
                            }
                        )}
                        onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
                            if (e.key === 'Enter') {
                                e.stopPropagation();
                                onClose();
                            }
                        }}
                        type="button"
                    >
                        <StyledSpanVerticalAlign>
                            <Close size={16} />
                        </StyledSpanVerticalAlign>
                    </StyledTagRemoveBtn>
                }
                bordered={false}
                style={{
                    marginInlineEnd: 4,
                    background: COLORS['default'].background,
                    color: COLORS['default'].color,
                }}
                closable={closable}
                onClose={onClose}
            >
                {contact ? <SelectedUserLabel {...contact} /> : label}
            </Tag>
        );
    };

    const addEmployeeToProject = async (updatedList: any) => {
        if (!isFilter && userGroup !== ContactListType.Clients) {
            const {_id} = record;
            const tableRID = getParentResourceRID(_id);
            const projectRID = getParentResourceRID(tableRID);
            await addUsersToProjectFromRelatedResources(projectRID, updatedList);
        }
    };

    const updateList = (contact: any) => {
        const data: InputObj = {value: [], errorMessage: ''};
        if (allowMultiSelect) {
            setSelectedContacts((prevList) => {
                // Check if the new object already exists in the list
                const index = prevList.findIndex(
                    (item: ContactOptionType) => item.value === contact.value
                );

                let updatedList: ContactOptionType[] = [];
                if (index !== -1) {
                    // If it exists, remove it from the list
                    updatedList = prevList.filter((_, i) => i !== index);
                } else {
                    // If it doesn't exist, add the new object to the list
                    updatedList = [...prevList, contact];
                }
                const userIds = updatedList.map((e: ContactOptionType) => e.value);
                if (index === -1) {
                    addEmployeeToProject(userIds);
                }
                // Call the callback function with the updated list
                if (onChange) {
                    onChange({...data, value: userIds});
                }
                return updatedList;
            });
        } else {
            setSelectedContacts([contact]);
            addEmployeeToProject([contact.value]);
            if (onChange) {
                onChange({...data, value: [contact.value]});
            }
        }
    };
    const renderDropdown = (menu: any) => {
        return loading ? (
            <div>
                <Spin size="small" />
            </div>
        ) : (
            <StyledDropdown>{menu}</StyledDropdown>
        );
    };

    return (
        <AssigneeMultiUserFieldWrapper isEditable={!disabled}>
            <Select
                mode={allowMultiSelect ? 'multiple' : undefined}
                autoFocus
                data-testid={dataTestId}
                labelInValue
                filterOption={false}
                placeholder={t('dynamic-components:userSelect.search')}
                onSearch={debouncedSearch}
                notFoundContent={loading ? <Spin size="small" /> : null}
                style={{width: '100%'}}
                showSearch
                maxTagCount="responsive"
                onSelect={(_, option) => updateList(option)}
                onDeselect={updateList}
                value={selectedContacts?.map((option: ContactOptionType) => ({
                    label: <SelectedUserLabel {...option.details} />,
                    value: option.details.id!,
                }))}
                ref={forwardedRef}
                aria-label={`${label} ${allowMultiSelect ? 'multi-select' : 'select'}`}
                options={contactsList}
                dropdownRender={renderDropdown}
                tagRender={renderTag}
                className={className}
            />
        </AssigneeMultiUserFieldWrapper>
    );
};

export const convertToOption = (contact: ContactOptionProps): ContactOptionType => {
    return {
        label: <ContactOption {...contact} />,
        value: contact.id!,
        details: contact,
    };
};
