/* tslint:disable */
/* This file was generated by @citrite/translate. Manual changes will be lost. */
import { Language } from '@citrite/translate';
export type locale = 'de' | 'en' | 'es' | 'fr' | 'it' | 'ja' | 'nl' | 'pt-BR';
export const languages: Language[] = [
  {
    "key": "de",
    "content": {
      "common": {
        "action": "Aktion",
        "actions": "Aktionen",
        "active": "Aktiv",
        "add": "Hinzufügen",
        "all_activity": "Alle Aktivitäten",
        "all_variables": "Alle Variablen",
        "automations": "Automatisierungen",
        "back": "Zurück",
        "beta": "Beta",
        "blank_workflow": "Leerer Workflow",
        "cancel": "Abbrechen",
        "change": "Ändern",
        "clients": "Kunden",
        "close": "Schließen",
        "content": "Inhalt",
        "created_by_name_email": "Erstellt von: {{userName}} ({{userEmail}})",
        "currently_selected": "Zurzeit ausgewählt",
        "delete": "Löschen",
        "description": "Beschreibung",
        "details": "Details",
        "document": "Dokument",
        "edit": "Bearbeiten",
        "existing_project": "Vorhandenes Projekt",
        "file_name": "Dateiname",
        "folder": "Ordner",
        "folder_name": "Ordnername",
        "from_template": "Von einer Vorlage",
        "incomplete_workflow": "Unvollständiger Workflow",
        "last_updated": "Zuletzt aktualisiert",
        "last_updated_by": "Zuletzt aktualisiert von",
        "link_to_edit_options": "Optionen bearbeiten",
        "load_more": "Mehr laden",
        "missing_name": "Name fehlt",
        "more_options": "Weitere Optionen",
        "name": "Name",
        "new_action": "Neue Aktion",
        "new_folder": "Neuer Ordner",
        "new_project": "Neues Projekt",
        "ok": "OK",
        "optional_flag": "(optional)",
        "options": "Optionen",
        "preview": "Vorschau",
        "previous_actions": "Vorherige Aktionen",
        "project": "Projekt",
        "project_name": "Projektname",
        "properties": "Eigenschaften",
        "prospects": "Interessenten",
        "relevant_variables": "Relevante Variablen",
        "run_details": "Ausführungsdetails",
        "run_history": "Ausführungsverlauf",
        "save": "Speichern",
        "save_as_template": "Als Vorlage speichern",
        "search": "Suchen",
        "select": "Auswählen",
        "selected": "ausgewählt",
        "selected_clients": "Ausgewählte Kunden",
        "selected_prospects": "Ausgewählte Interessenten",
        "send": "Senden",
        "signers": "Unterzeichner",
        "start": "Start",
        "started": "Gestartet",
        "started_by": "Gestartet von",
        "status": "Status",
        "submit": "Senden",
        "text": "Text",
        "trigger": "Auslöser",
        "trigger_variables": "Auslöservariablen",
        "user": "Benutzer",
        "variable": "Variable",
        "variables": "Variablen",
        "workflow": "Workflow",
        "workflow_name": "Workflowname",
        "workflows": "Workflows"
      },
      "descriptions": {
        "action_output_symbol": "Durch diese Aktion generierter Wert",
        "action_symbol": "Vorherige Maßnahme {{descriptionText}}",
        "available_variables": {
          "num": "1 verfügbare Variable",
          "num_plural": "{{count}} verfügbare Variablen"
        },
        "context_symbol": "Zur Laufzeit definierter Wert",
        "file_location": "Speicherort zum Speichern des signierten Dokuments",
        "merge_fields": "Diese Vorlage enthält Datenfelder. Sie können Variablen verwenden, um sie für jede Ausführung anzupassen, oder Sie können einfachen Text eingeben, wenn sie unverändert bleiben sollen.",
        "properties_placeholder": "Beschreiben Sie Ihren Workflow",
        "save_signed_document": "Signiertes Dokument speichern unter",
        "signed_suffix_label": "-signiert",
        "text": "Eine Textzeichenfolge",
        "user": "Ein Benutzer aus Ihrem ShareFile-Adressbuch",
        "variables": "Beschreibung für Benutzer"
      },
      "file_export": {
        "back": "Zurück",
        "browse_integrations": "Integrationen durchsuchen",
        "cancel": "Abbrechen",
        "continue": "Weiter",
        "description": "Beim Exportieren wird eine Kopie der ausgewählten Datei an eine andere Anwendung gesendet.",
        "destination": "Ziel",
        "export": "Exportieren",
        "export_description": "Beim Exportieren wird eine Kopie der ausgewählten Dateien an eine andere Anwendung gesendet. Wählen Sie zunächst eine Anwendung zum Senden aus und geben Sie dann an, wohin in dieser Anwendung Sie die Datei exportieren möchten.",
        "export_title": "Exportieren",
        "exporting": "Exportieren",
        "failed_to_load_integrations": "Integrationen konnten nicht geladen werden. Versuchen Sie es erneut.",
        "file_failed_to_send": "{{fileName}} konnte nicht gesendet werden",
        "file_sent_successfully": "{{fileName}} gesendet",
        "files_failed_to_send": "{{count}} Dateien konnten nicht gesendet werden",
        "files_sent_successfully": "{{count}} Dateien gesendet",
        "more_files": "+ {{num}} weitere Dateien",
        "no_destinations": "Sie haben noch keine Ziele konfiguriert. Installieren Sie eine Integration, die das Exportieren unterstützt, um fortzufahren.",
        "one_more_file": "+ 1 weitere Datei",
        "select_destination": "Ziel auswählen",
        "starting_export": "Dateiexport starten",
        "step_description": "Wählen Sie zuerst eine Anwendung als Ziel aus. Als Nächstes geben Sie an, wohin die Datei in dieser Anwendung exportiert werden soll.",
        "view_details": "Details anzeigen",
        "workflow_failed": "Fehler beim Ausführen des Workflows"
      },
      "messages": {
        "add_variable": "Variable zum Hinzufügen dynamischer Inhalte verwenden",
        "all_done": "Fertig!",
        "all_fields_required": "Alle Felder sind erforderlich, sofern nicht anders angegeben.",
        "all_items_loaded": "Sie haben alle Elemente geladen",
        "cancel_run": "Möchten Sie diese {{runName}}-Ausführung abbrechen? Alle unvollständigen Schritte gehen verloren.",
        "cannot_delete_array_item": "Dies darf nicht leer sein.",
        "close_designer": "Möchten Sie {{flowName}} wirklich schließen? Ihre nicht gespeicherten Änderungen gehen verloren.",
        "couldnt_load_selection": "Wir konnten Ihre Auswahl nicht laden. Versuchen Sie, die Seite neu zu laden oder eine neue Auswahl zu treffen.",
        "delete_action": "Möchten Sie {{actionName}} wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden. Alle Aktionen, die sich auf diese Aktion beziehen, werden abgebrochen.",
        "delete_flow": "Möchten Sie {{flowName}} wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden. Wenn Sie diesen Workflow löschen, werden keine aktiven Ausführungen abgebrochen.",
        "disable_flow": "Möchten Sie {{flowName}} wirklich deaktivieren? Wenn Sie diesen Workflow deaktivieren, werden keine aktiven Ausführungen abgebrochen.",
        "error_loading_templates": "Fehler beim Laden von Vorlagen",
        "flow_complete": "Dies ist der letzte Schritt des Workflows.",
        "flow_save_failure": "Der Speichervorgang konnte nicht durchgeführt werden. Versuchen Sie es erneut",
        "flow_save_success": "Gespeicherter Workflow",
        "generic_error": "Ein Fehler ist aufgetreten",
        "info_banner_content_existing_project": "Wir speichern jedes signierte Dokument im {{projectName}}-Projekt.",
        "info_banner_content_folder": "Wir speichern jedes signierte Dokument in einem Ordner.",
        "info_banner_content_folder_name_client": "Wir speichern das signierte Dokument im Ordner des Kunden. Wenn noch kein Kundenordner besteht, erstellen wir einen für Sie.",
        "info_banner_content_folder_name_prospect": "Wir erstellen einen Kundenordner für Sie. Wenn bereits ein Ordner mit diesem Namen vorhanden ist, hängen wir die E-Mail-Adresse des Interessenten an.",
        "info_banner_content_new_folder": "Wir speichern jedes signierte Dokument in einem neuen Ordner.",
        "info_banner_content_new_project": "Wir speichern jedes signierte Dokument in einem neuen Projekt.",
        "invalid_flow": "Dieser Workflow enthält Fehler. Sie können ihn erst ausführen, wenn er vom Besitzer, {{ownerName}}, korrigiert wurde.",
        "invalid_flow_creator": "Dieser Workflow enthält Fehler. Sie können ihn erst ausführen, wenn er korrigiert wurde. Bearbeiten Sie den Workflow und versuchen Sie es erneut.",
        "loading_content": "Inhalt wird geladen...",
        "multiple_node_errors": "Dieser Schritt enthält {{numErrors}} Fehler",
        "next_step": "Nächster Schritt: ",
        "no_available_symbols": "Für diese Option stehen keine geeigneten Variablen zur Verfügung.",
        "no_edit_permission": "Sie sind nicht berechtigt, diesen Workflow zu bearbeiten.",
        "no_items": "Keine Elemente",
        "no_runs": "Keine anzuzeigenden Ausführungen",
        "no_templates": "Keine Vorlagen gefunden",
        "no_workflows": "Keine anzuzeigenden Workflows",
        "opens_in_new_tab": "Wird auf einer neuen Registerkarte geöffnet",
        "please_wait": "Bitte warten ...",
        "redirecting": "Wird in {{secondsRemaining}} Sekunden umgeleitet...",
        "required_fields_count": "{{numFilledIn}} von {{numRequired}} erforderliche Felder sind ausgefüllt",
        "run_cancelation_failed": "Fehler beim Abbrechen der Ausführung von {{runName}}.",
        "run_canceled": "Die Ausführung von {{runName}} wurde erfolgreich abgebrochen.",
        "save_warning": "Dieser Workflow kann in seinem aktuellen Status nicht ausgeführt werden. Möchten Sie wirklich speichern?",
        "send_agreements_success": "Die Kundenvereinbarung wurde gesendet.",
        "single_node_error": "Dieser Schritt enthält einen Fehler",
        "something_went_wrong": "Ein Fehler ist aufgetreten",
        "start_info": "Sie müssen auf alle Dateien, Ordner und Funktionen zugreifen können, die in diesem Workflow verwendet werden, um ihn erfolgreich ausführen zu können. Wenden Sie sich an Ihren Administrator, wenn Sie eine Signaturlizenz oder Zugriff auf einen Ordner benötigen.",
        "start_warning": "Wir benötigen weitere Informationen, bevor wir diesen Workflow starten können. Stellen Sie sicher, dass Sie alle erforderlichen Felder ausgefüllt haben.",
        "template_fetch_error": "Ihre Vorlage konnte nicht abgerufen werden",
        "template_not_selected": "Keine Dokumentvorlage hinzugefügt",
        "unknown_variable": "Diese Variable wurde gelöscht",
        "workflow_failed": "{{workflowName}} fehlgeschlagen",
        "workflow_started": "{{workflowName}} gestartet"
      },
      "run_logs": {
        "flow_not_found": "Workflow nicht gefunden",
        "log_strings": {
          "action_completed": "Aktion {{actionName}} abgeschlossen",
          "action_started": "Aktion {{actionName}} gestartet",
          "error_loading_action_label": "Unbenannte Aktion",
          "loading_action_label": "...",
          "workflow_canceled": "Abgebrochener Workflow",
          "workflow_completed": "Abgeschlossener Workflow",
          "workflow_failed": "Fehler beim Abschließen des Workflows",
          "workflow_started": "Gestarteter Workflow"
        },
        "logs_empty": "Noch keine Aktivität",
        "logs_fetching_error": "Aktivität kann nicht geladen werden",
        "run_not_found": "Aktivitätseintrag nicht gefunden"
      },
      "run_status": {
        "canceled": "Abgebrochen",
        "canceling": "Wird abgebrochen",
        "closed": "Abgeschlossen",
        "failed": "Fehler",
        "open": "In Bearbeitung",
        "unknown": "Unbekannt"
      },
      "symbol_tags": {
        "error": "#FEHLER",
        "reference_error": "#REFERROR"
      },
      "titles": {
        "dashboard": "Automatisierte Workflows",
        "flow_options": "{{workflowName}} starten",
        "merge_fields": "Datenfelder der Vorlage",
        "selected_template": "Vorlage:",
        "send_agreements": "Kundenvereinbarung senden",
        "send_agreements_alternate": "Vereinbarung senden",
        "template_preview": "Vorschau - Kundenvereinbarung",
        "template_preview_alternate": "Vorschau - Vereinbarung"
      },
      "user_actions": {
        "add_automation": "Automatisierung hinzufügen",
        "add_trigger_variable": "Auslöservariable hinzufügen",
        "add_variable": "+ Variable hinzufügen",
        "add_workflow": "Workflow hinzufügen",
        "cancel_flow_run": "Workflowausführung abbrechen",
        "cancel_run": "Ausführung abbrechen",
        "change_template": "Vorlage ändern",
        "close_sidebar": "Konfigurationsbereich schließen",
        "close_workflow": "Workflow schließen",
        "contact_owner": "Besitzer kontaktieren",
        "continue_editing": "Bearbeitung fortsetzen",
        "create_a_template": "Vorlage erstellen",
        "create_automation": "Automatisierung erstellen",
        "create_variable": "Variable erstellen",
        "delete_action": "Aktion löschen",
        "delete_flow": "Workflow löschen",
        "disable_flow": "Workflow deaktivieren",
        "make_field_optional": "Feld optional machen",
        "make_field_required": "Feld erforderlich machen",
        "search_project": "Nach einem Projekt suchen...",
        "select_a_template": "Vorlage auswählen",
        "select_a_trigger": "Auslöser auswählen",
        "select_a_value": "Wert auswählen",
        "select_an_action": "Aktion auswählen",
        "select_folder": "Ordner auswählen",
        "select_item": "Dieses Element auswählen",
        "select_next_client": "Nächsten Kunden auswählen",
        "select_next_prospect": "Nächsten Interessenten auswählen",
        "select_prev_client": "Vorherigen Kunden auswählen",
        "select_prev_prospect": "Vorherigen Interessenten auswählen",
        "set_fields": "Datenfelder festlegen",
        "show_sidebar": "Konfigurationsbereich anzeigen",
        "start_flow": "Workflow starten",
        "view_run_details": "Details anzeigen",
        "view_run_history": "Ausführungen anzeigen"
      },
      "user_prompts": {
        "client_send_agreements_selection": "Kunden zum Senden einer Vereinbarung auswählen",
        "configure_options": "Wir benötigen ein wenig mehr Informationen, um diesen Workflow zu starten.",
        "create_template_help": "Sie können nicht finden, wonach Sie suchen?",
        "prospect_send_agreements_selection": "Interessenten zum Senden einer Vereinbarung auswählen",
        "search_content": "Inhalt suchen...",
        "search_templates": "Vorlagen durchsuchen...",
        "search_variables": "Variablen suchen...",
        "what_happens_next": "Wie geht es weiter?"
      }
    }
  },
  {
    "key": "en",
    "content": {
      "common": {
        "action": "Action",
        "actions": "Actions",
        "active": "Active",
        "add": "Add",
        "all_activity": "All activity",
        "all_variables": "All variables",
        "automations": "Automations",
        "back": "Back",
        "beta": "Beta",
        "blank_workflow": "Blank workflow",
        "cancel": "Cancel",
        "change": "Change",
        "clients": "Clients",
        "close": "Close",
        "content": "Content",
        "create": "Create",
        "created_by_name_email": "Created by: {{userName}} ({{userEmail}})",
        "currently_selected": "Currently selected",
        "delete": "Delete",
        "description": "Description",
        "details": "Details",
        "document": "Document",
        "edit": "Edit",
        "existing_project": "Existing Project",
        "file_name": "File name",
        "folder": "Folder",
        "folder_name": "Folder name",
        "from_template": "From template",
        "incomplete_workflow": "Incomplete workflow",
        "last_updated": "Last updated",
        "last_updated_by": "Last updated by",
        "link_to_edit_options": "Edit Options",
        "load_more": "Load more",
        "missing_name": "Missing Name",
        "more_options": "More options",
        "name": "Name",
        "new_action": "New Action",
        "new_folder": "New Folder",
        "new_project": "New Project",
        "ok": "OK",
        "optional_flag": "(optional)",
        "options": "Options",
        "preview": "Preview",
        "previous_actions": "Previous actions",
        "project": "Project",
        "project_name": "Project name",
        "properties": "Properties",
        "prospects": "Prospects",
        "relevant_variables": "Relevant variables",
        "run_details": "Run details",
        "run_history": "Run history",
        "save": "Save",
        "save_as_template": "Save as template",
        "search": "Search",
        "select": "Select",
        "selected": "selected",
        "selected_clients": "Selected Clients",
        "selected_prospects": "Selected Prospects",
        "send": "Send",
        "signers": "Signers",
        "start": "Start",
        "started": "Started",
        "started_by": "Started by",
        "status": "Status",
        "submit": "Submit",
        "text": "Text",
        "trigger": "Trigger",
        "trigger_variables": "Trigger variables",
        "update": "Update",
        "user": "User",
        "variable": "Variable",
        "variables": "Variables",
        "workflow": "Workflow",
        "workflow_name": "Workflow name",
        "workflows": "Workflows",
        "create_automation": "Create automation"
      },
      "descriptions": {
        "action_output_symbol": "Value generated from this action",
        "action_symbol": "Previous action{{descriptionText}}",
        "available_variables": {
          "num": "1 variable available",
          "num_plural": "{{count}} variables available"
        },
        "context_symbol": "Value defined at runtime",
        "file_location": "Location to save signed document",
        "merge_fields": "This template has data fields. You can use variables to customize them for each run, or you can enter plain text if you want them to stay the same.",
        "properties_placeholder": "Describe your workflow",
        "save_signed_document": "Save signed document as",
        "signed_suffix_label": "-signed",
        "text": "A string of text",
        "user": "A user from your ShareFile address book",
        "variables": "Description for users"
      },
      "file_export": {
        "back": "Back",
        "browse_integrations": "Browse Integrations",
        "cancel": "Cancel",
        "continue": "Continue",
        "description": "Exporting sends a copy of the selected file to another application.",
        "destination": "Destination",
        "export": "Export",
        "export_description": "Exporting will send a copy of the selected files to another application. First, choose an application to send to and then specify where in that application you want to export the file.",
        "export_title": "Export",
        "exporting": "Exporting",
        "failed_to_load_integrations": "Failed to load integrations. Please try again.",
        "file_failed_to_send": "{{fileName}} failed to send",
        "file_sent_successfully": "{{fileName}} sent successfully",
        "files_failed_to_send": "{{count}} files failed to send",
        "files_sent_successfully": "{{count}} files sent successfully",
        "more_files": "+ {{num}} more files",
        "no_destinations": "You haven’t configured any destinations yet. Install an integration that supports exporting to continue.",
        "one_more_file": "+ 1 more file",
        "select_destination": "Select a destination",
        "starting_export": "Starting file export",
        "step_description": "First, you’ll choose an application to be the destination. Next you’ll specify where in that application you want to export the file.",
        "view_details": "View Details",
        "workflow_failed": "Workflow failed to run"
      },
      "messages": {
        "add_variable": "Use a variable to add dynamic content",
        "all_done": "All done!",
        "all_fields_required": "All fields are required unless otherwise noted.",
        "all_items_loaded": "You've loaded all items",
        "automation_name_field_description": "This will be helpful when viewing automation runs",
        "cancel_run": "Do you want to cancel this {{runName}} run? Any incomplete steps will be lost.",
        "cannot_delete_array_item": "This can't be blank.",
        "close_designer": "Are you sure you want to close {{flowName}}? Your unsaved changes will be lost.",
        "couldnt_load_selection": "We couldn’t load your selection. Try reloading the page or reselecting.",
        "delete_action": "Are you sure you want to delete {{actionName}}? This can't be undone. Any actions that refer to this action will be broken.",
        "delete_flow": "Are you sure you want to delete {{flowName}}? This can't be undone. Deleting this workflow won't cancel any active runs.",
        "disable_flow": "Are you sure you want to disable {{flowName}}? Disabling this workflow won't cancel any active runs.",
        "error_loading_templates": "Error loading templates",
        "flow_complete": "This is the final step of the workflow.",
        "flow_save_failure": "We weren't able to save, please try again",
        "flow_save_success": "Saved workflow",
        "flow_status_success": "Flow status updated",
        "generic_error": "An error occurred",
        "info_banner_content_existing_project": "We'll save each signed document in the {{projectName}} project.",
        "info_banner_content_folder": "We'll save each signed document in a folder.",
        "info_banner_content_folder_name_client": "We'll save the signed document to the client's folder. If they don't already have a folder, we'll create one for you.",
        "info_banner_content_folder_name_prospect": "We'll create a client folder for you. If there's already a folder with this name, we'll append the prospect's email address.",
        "info_banner_content_new_folder": "We'll save each signed document in a new folder.",
        "info_banner_content_new_project": "We'll save each signed document in a new project.",
        "invalid_flow": "There are errors in this workflow. You won't be able to run it until they've been corrected by the owner, {{ownerName}}.",
        "invalid_flow_creator": "There are errors in this workflow. You won't be able to run it until they've been corrected. Please edit the workflow and try again.",
        "loading_content": "Loading content...",
        "multiple_node_errors": "This step contains {{numErrors}} errors",
        "next_step": "Next step: ",
        "no_available_symbols": "There are no appropriate variables available for this option.",
        "no_edit_permission": "You don't have permission to edit this workflow.",
        "no_items": "No items",
        "no_runs": "No runs to show",
        "no_templates": "No templates found",
        "no_workflows": "No workflows to show",
        "opens_in_new_tab": "Opens in new tab",
        "please_wait": "Please wait ...",
        "redirecting": "Redirecting in {{secondsRemaining}} seconds ...",
        "required_fields_count": "{{numFilledIn}} of {{numRequired}} required fields complete",
        "run_cancelation_failed": "{{runName}} run cancelation failed.",
        "run_canceled": "{{runName}} run successfully canceled.",
        "save_warning": "This workflow won't be able to run in its current state. Are you sure you want to save?",
        "send_agreements_success": "Client agreement successfully sent.",
        "single_node_error": "This step contains an error",
        "something_went_wrong": "Oh no ... something went wrong",
        "start_info": "You need to be able to access all the files, folders, and functionality used in this workflow in order to successfully run it. Reach out to your admin if you need a signature license or access to a folder.",
        "start_warning": "We need more information before we can start this workflow. Make sure you've completed all of the required fields.",
        "template_fetch_error": "We weren't able to fetch your template",
        "template_not_selected": "No document template added",
        "unknown_variable": "This variable has been deleted",
        "workflow_failed": "{{workflowName}} failed",
        "workflow_started": "Started {{workflowName}}",
        "no_automations_found": "There aren't any automations"
      },
      "operators": {
        "is_equal_to": "is equal to",
        "is_not_equal_to": "is not equal to",
        "is_greater_than": "is greater than",
        "is_less_than": "is less than",
        "is_true": "is true",
        "is_false": "is false",
        "is_empty": "is empty",
        "is_not_empty": "is not empty"
      },
      "run_logs": {
        "flow_not_found": "Workflow not found",
        "log_strings": {
          "action_completed": "Finished action {{actionName}}",
          "action_started": "Began action {{actionName}}",
          "error_loading_action_label": "Unnamed action",
          "loading_action_label": "...",
          "workflow_canceled": "Canceled workflow",
          "workflow_completed": "Completed workflow",
          "workflow_failed": "Failed to complete workflow",
          "workflow_started": "Started workflow"
        },
        "logs_empty": "No activity yet",
        "logs_fetching_error": "Unable to load activity",
        "run_not_found": "Activity entry not found"
      },
      "run_status": {
        "canceled": "Canceled",
        "canceling": "Canceling",
        "closed": "Completed",
        "failed": "Error",
        "open": "In progress",
        "unknown": "Unknown"
      },
      "symbol_tags": {
        "error": "#ERROR",
        "reference_error": "#REFERROR"
      },
      "titles": {
        "dashboard": "Automated Workflows",
        "flow_options": "Start {{workflowName}}",
        "merge_fields": "Template data fields",
        "selected_template": "Template:",
        "send_agreements": "Send Client Agreement",
        "send_agreements_alternate": "Send agreement",
        "template_preview": "Preview - Client Agreement",
        "template_preview_alternate": "Preview - Agreement"
      },
      "user_actions": {
        "add_automation": "Add automation",
        "add_condition": "Add condition",
        "add_trigger_variable": "Add trigger variable",
        "add_variable": "+ Add variable",
        "add_workflow": "Add workflow",
        "cancel_flow_run": "Cancel workflow run",
        "cancel_run": "Cancel run",
        "change_template": "Change Template",
        "close_sidebar": "Close configuration pane",
        "close_workflow": "Close workflow",
        "contact_owner": "Contact Owner",
        "continue_editing": "Continue editing",
        "create_a_template": "Create a template",
        "create_automation": "Create automation",
        "create_variable": "Create variable",
        "delete_action": "Delete action",
        "delete_flow": "Delete workflow",
        "disable_flow": "Disable workflow",
        "make_field_optional": "Make field optional",
        "make_field_required": "Make field required",
        "remove_condition": "Remove condition",
        "search_project": "Search for a project...",
        "select_a_template": "Select a template",
        "select_a_trigger": "Select a trigger",
        "select_a_value": "Select a value",
        "select_action_placeholder": "Select from available actions",
        "select_an_action": "Select an action",
        "select_folder": "Select folder",
        "select_item": "Select this item",
        "select_next_client": "Select next client",
        "select_next_prospect": "Select next prospect",
        "select_prev_client": "Select previous client",
        "select_prev_prospect": "Select previous prospect",
        "select_trigger_placeholder": "Select from available triggers",
        "set_fields": "Set data fields",
        "show_sidebar": "Show configuration pane",
        "start_flow": "Start workflow",
        "view_run_details": "View details",
        "view_run_history": "View runs"
      },
      "user_prompts": {
        "automation_name": "What do you want to call this automation?",
        "client_send_agreements_selection": "Select a client to send an agreement",
        "conditional_if": "If the following item",
        "conditional_if_description": "Select from the available criteria",
        "conditional_then": "The following value",
        "conditional_then_description": "Select the option that would trigger this automation",
        "configure_options": "We need a little more information to start this workflow.",
        "create_template_help": "Can't find what you're looking for?",
        "prospect_send_agreements_selection": "Select a prospect to send an agreement",
        "search_content": "Search content...",
        "search_templates": "Search templates...",
        "search_variables": "Search variables...",
        "select_action": "What would you like this to do?",
        "select_trigger": "When would you like this to happen?",
        "what_happens_next": "What happens next?"
      }
    }
  },
  {
    "key": "es",
    "content": {
      "common": {
        "action": "Acción",
        "actions": "Acciones",
        "active": "Activo",
        "add": "Agregar",
        "all_activity": "Toda la actividad",
        "all_variables": "Todas las variables",
        "automations": "Automatizaciones",
        "back": "Atrás",
        "beta": "Beta",
        "blank_workflow": "Flujo de trabajo en blanco",
        "cancel": "Cancelar",
        "change": "Cambiar",
        "clients": "Clientes",
        "close": "Cerrar",
        "content": "Contenido",
        "created_by_name_email": "Creado por: {{userName}} ({{userEmail}})",
        "currently_selected": "Selección actual",
        "delete": "Eliminar",
        "description": "Descripción",
        "details": "Detalles",
        "document": "Documento",
        "edit": "Modificar",
        "existing_project": "Proyecto existente",
        "file_name": "Nombre de archivo",
        "folder": "Carpeta",
        "folder_name": "Nombre de carpeta",
        "from_template": "A partir de la plantilla",
        "incomplete_workflow": "Flujo de trabajo incompleto",
        "last_updated": "Última actualización",
        "last_updated_by": "Última actualización por",
        "link_to_edit_options": "Modificar opciones",
        "load_more": "Cargar más",
        "missing_name": "Falta el nombre",
        "more_options": "Más opciones",
        "name": "Nombre",
        "new_action": "Nueva acción",
        "new_folder": "Nueva carpeta",
        "new_project": "Nuevo proyecto",
        "ok": "OK",
        "optional_flag": "(optativo)",
        "options": "Opciones",
        "preview": "Vista previa",
        "previous_actions": "Acciones anteriores",
        "project": "Proyecto",
        "project_name": "Nombre del proyecto",
        "properties": "Propiedades",
        "prospects": "Prospectos",
        "relevant_variables": "Variables relevantes",
        "run_details": "Detalles de ejecución",
        "run_history": "Historial de ejecución",
        "save": "Guardar",
        "save_as_template": "Guardar como plantilla",
        "search": "Buscar",
        "select": "Seleccionar",
        "selected": "seleccionado",
        "selected_clients": "Clientes seleccionados",
        "selected_prospects": "Prospectos seleccionados",
        "send": "Enviar",
        "signers": "Firmantes",
        "start": "Inicio",
        "started": "Iniciado",
        "started_by": "Comenzada por",
        "status": "Estado",
        "submit": "Enviar",
        "text": "Texto",
        "trigger": "Desencadenante",
        "trigger_variables": "Variables desencadenantes",
        "user": "Usuario",
        "variable": "Variable",
        "variables": "Variables",
        "workflow": "Flujo de trabajo",
        "workflow_name": "Nombre del flujo de trabajo",
        "workflows": "Flujos de trabajo"
      },
      "descriptions": {
        "action_output_symbol": "Valor generado a partir de esta acción",
        "action_symbol": "Acción anterior{{descriptionText}}",
        "available_variables": {
          "num": "1 variable disponible",
          "num_plural": "{{count}} variables disponibles"
        },
        "context_symbol": "Valor definido en tiempo de ejecución",
        "file_location": "Ubicación para guardar el documento firmado",
        "merge_fields": "Esta plantilla tiene campos de datos. Puede usar variables para personalizarlos para cada ejecución, o puede introducir texto sin formato si quiere que permanezcan igual.",
        "properties_placeholder": "Describa su flujo de trabajo",
        "save_signed_document": "Guardar documento firmado como",
        "signed_suffix_label": "-firmado",
        "text": "Una cadena de texto",
        "user": "Un usuario de su libreta de direcciones de ShareFile",
        "variables": "Descripción para los usuarios"
      },
      "file_export": {
        "back": "Atrás",
        "browse_integrations": "Explorar integraciones",
        "cancel": "Cancelar",
        "continue": "Continuar",
        "description": "La exportación envía una copia del archivo seleccionado a otra aplicación.",
        "destination": "Destino",
        "export": "Exportar",
        "export_description": "Al exportar, se enviará una copia de los archivos seleccionados a otra aplicación. En primer lugar, elija una aplicación a la que enviar los archivos y, a continuación, especifique en qué lugar de esa aplicación quiere exportarlos.",
        "export_title": "Exportar",
        "exporting": "Exportando",
        "failed_to_load_integrations": "Error al cargar las integraciones. Inténtelo de nuevo.",
        "file_failed_to_send": "No se pudo enviar {{fileName}}",
        "file_sent_successfully": "\"{{fileName}}\" se envió correctamente",
        "files_failed_to_send": "Archivos que no pudieron enviarse: {{count}}",
        "files_sent_successfully": "Archivos enviados correctamente: {{count}}",
        "more_files": "+ {{num}} archivos más",
        "no_destinations": "Aún no ha configurado ningún destino. Para continuar, instale una integración compatible con exportación.",
        "one_more_file": "+ 1 archivo más",
        "select_destination": "Seleccionar un destino",
        "starting_export": "Iniciando la exportación de archivos",
        "step_description": "En primer lugar, elegirá una aplicación como destino. A continuación, especificará en qué parte de esa aplicación quiere exportar el archivo.",
        "view_details": "Ver detalles",
        "workflow_failed": "No se pudo ejecutar el flujo de trabajo"
      },
      "messages": {
        "add_variable": "Usar una variable para agregar contenido dinámico",
        "all_done": "Todo listo.",
        "all_fields_required": "Todos los campos son obligatorios a no ser que se indique lo contrario.",
        "all_items_loaded": "Ha cargado todos los elementos",
        "cancel_run": "¿Quiere cancelar esta ejecución de {{runName}}? Cualquier paso incompleto se perderá.",
        "cannot_delete_array_item": "Esto no puede estar en blanco.",
        "close_designer": "¿Seguro que quiere cerrar {{flowName}}? Los cambios no guardados se perderán.",
        "couldnt_load_selection": "No pudimos cargar su selección. Intente volver a cargar la página o vuelva a seleccionar.",
        "delete_action": "¿Seguro que quiere eliminar {{actionName}}? Esta operación no se puede deshacer. Cualquier acción que haga referencia a esta acción se interrumpirá.",
        "delete_flow": "¿Seguro que quiere eliminar {{flowName}}? Esta operación no se puede deshacer. La eliminación de este flujo de trabajo no cancelará ninguna ejecución activa.",
        "disable_flow": "¿Seguro que quiere inhabilitar {{flowName}}? La eliminación de este flujo de trabajo no cancelará ninguna ejecución activa.",
        "error_loading_templates": "Error al cargar plantillas",
        "flow_complete": "Este es el paso final del flujo de trabajo.",
        "flow_save_failure": "No pudimos guardar; inténtelo de nuevo",
        "flow_save_success": "Flujo de trabajo guardado",
        "generic_error": "Ocurrió un error",
        "info_banner_content_existing_project": "Guardaremos cada documento firmado en el proyecto {{projectName}}.",
        "info_banner_content_folder": "Guardaremos cada documento firmado en una carpeta.",
        "info_banner_content_folder_name_client": "Guardaremos el documento firmado en la carpeta del cliente. Si aún no tiene una carpeta, crearemos una por usted.",
        "info_banner_content_folder_name_prospect": "Crearemos una carpeta de cliente para usted. Si ya hay una carpeta con este nombre, agregaremos la dirección de correo electrónico del prospecto.",
        "info_banner_content_new_folder": "Guardaremos cada documento firmado en una nueva carpeta.",
        "info_banner_content_new_project": "Guardaremos cada documento firmado en una nueva carpeta.",
        "invalid_flow": "Hay errores en este flujo de trabajo. No podrá ejecutarlo hasta que el propietario, {{ownerName}}, los haya corregido.",
        "invalid_flow_creator": "Hay errores en este flujo de trabajo. No podrá ejecutarlo hasta que se hayan corregido. Modifique el flujo de trabajo e inténtelo de nuevo.",
        "loading_content": "Cargando contenido...",
        "multiple_node_errors": "Este paso contiene {{numErrors}} errores",
        "next_step": "Siguiente paso: ",
        "no_available_symbols": "No hay variables apropiadas disponibles para esta opción.",
        "no_edit_permission": "No tiene permiso para modificar este flujo de trabajo.",
        "no_items": "No hay elementos",
        "no_runs": "No hay ejecuciones que mostrar",
        "no_templates": "No se encontraron plantillas",
        "no_workflows": "No hay flujos de trabajo que mostrar",
        "opens_in_new_tab": "Se abre en una nueva ficha",
        "please_wait": "Espere ...",
        "redirecting": "Redirigiendo en {{secondsRemaining}} segundos...",
        "required_fields_count": "{{numFilledIn}} de {{numRequired}} campos obligatorios completados",
        "run_cancelation_failed": "Error en la cancelación de la ejecución de {{runName}}.",
        "run_canceled": "Ejecución de {{runName}} cancelada correctamente.",
        "save_warning": "Este flujo de trabajo no podrá ejecutarse en su estado actual. ¿Seguro que quiere guardar?",
        "send_agreements_success": "El contrato del cliente se envió correctamente.",
        "single_node_error": "Este paso contiene un error",
        "something_went_wrong": "Se ha producido un error",
        "start_info": "Para poder ejecutar este flujo de trabajo correctamente, debe poder acceder a todos los archivos, carpetas y funciones que se utilizan en él. Contacte con su administrador si necesita una licencia de firma o acceso a una carpeta.",
        "start_warning": "Necesitamos más información antes de poder iniciar este flujo de trabajo. Asegúrese de haber completado todos los campos obligatorios.",
        "template_fetch_error": "No pudimos obtener su plantilla",
        "template_not_selected": "No se agregó ninguna plantilla de documento",
        "unknown_variable": "Esta variable se ha eliminado",
        "workflow_failed": "{{workflowName}} falló",
        "workflow_started": "{{workflowName}} iniciado"
      },
      "run_logs": {
        "flow_not_found": "Flujo de trabajo no encontrado",
        "log_strings": {
          "action_completed": "Acción {{actionName}} finalizada",
          "action_started": "Comenzó la acción {{actionName}}",
          "error_loading_action_label": "Acción sin nombre",
          "loading_action_label": "...",
          "workflow_canceled": "Flujo de trabajo cancelado",
          "workflow_completed": "Flujo de trabajo completado",
          "workflow_failed": "No se pudo completar el flujo de trabajo",
          "workflow_started": "Flujo de trabajo iniciado"
        },
        "logs_empty": "Aún no hay actividad",
        "logs_fetching_error": "No se puede cargar la actividad",
        "run_not_found": "No se encontró la entrada de actividad"
      },
      "run_status": {
        "canceled": "Cancelado",
        "canceling": "Cancelando",
        "closed": "Completado",
        "failed": "Error",
        "open": "En curso",
        "unknown": "Desconocido"
      },
      "symbol_tags": {
        "error": "#ERROR",
        "reference_error": "#REFEROR"
      },
      "titles": {
        "dashboard": "Flujos de trabajo automatizados",
        "flow_options": "Iniciar {{workflowName}}",
        "merge_fields": "Campos de datos de la plantilla",
        "selected_template": "Plantilla:",
        "send_agreements": "Enviar contrato del cliente",
        "send_agreements_alternate": "Enviar contrato",
        "template_preview": "Vista previa - Contrato del cliente",
        "template_preview_alternate": "Vista previa - Contrato"
      },
      "user_actions": {
        "add_automation": "Agregar automatización",
        "add_trigger_variable": "Agregar variable desencadenante",
        "add_variable": "+ Agregar variable",
        "add_workflow": "Agregar flujo de trabajo",
        "cancel_flow_run": "Cancelar ejecución de flujo de trabajo",
        "cancel_run": "Cancelar ejecución",
        "change_template": "Cambiar plantilla",
        "close_sidebar": "Cerrar panel de configuración",
        "close_workflow": "Cerrar flujo de trabajo",
        "contact_owner": "Contactar con el propietario",
        "continue_editing": "Continuar modificando",
        "create_a_template": "Crear una plantilla",
        "create_automation": "Crear automatización",
        "create_variable": "Crear variable",
        "delete_action": "Eliminar acción",
        "delete_flow": "Eliminar flujo de trabajo",
        "disable_flow": "Inhabilitar flujo de trabajo",
        "make_field_optional": "Hacer que el campo sea opcional",
        "make_field_required": "Hacer que el campo sea obligatorio",
        "search_project": "Buscar un proyecto...",
        "select_a_template": "Seleccione una plantilla",
        "select_a_trigger": "Seleccione un desencadenante",
        "select_a_value": "Seleccione un valor",
        "select_an_action": "Seleccione una acción",
        "select_folder": "Seleccionar carpeta",
        "select_item": "Seleccionar este elemento",
        "select_next_client": "Seleccionar siguiente cliente",
        "select_next_prospect": "Seleccionar siguiente prospecto",
        "select_prev_client": "Seleccionar cliente anterior",
        "select_prev_prospect": "Seleccionar prospecto anterior",
        "set_fields": "Definir campos de datos",
        "show_sidebar": "Mostrar panel de configuración",
        "start_flow": "Comenzar flujo de trabajo",
        "view_run_details": "Ver detalles",
        "view_run_history": "Ver ejecuciones"
      },
      "user_prompts": {
        "client_send_agreements_selection": "Seleccione un cliente para enviar un acuerdo",
        "configure_options": "Necesitamos un poco más de información para iniciar este flujo de trabajo.",
        "create_template_help": "¿No encuentra lo que busca?",
        "prospect_send_agreements_selection": "Seleccione un cliente para enviar un acuerdo",
        "search_content": "Buscar contenido...",
        "search_templates": "Buscar plantillas...",
        "search_variables": "Buscar variables...",
        "what_happens_next": "¿Qué ocurre a continuación?"
      }
    }
  },
  {
    "key": "fr",
    "content": {
      "common": {
        "action": "Action",
        "actions": "Actions",
        "active": "Actif",
        "add": "Ajouter",
        "all_activity": "Toutes les activités",
        "all_variables": "Toutes les variables",
        "automations": "Automatisations",
        "back": "Précédent",
        "beta": "Bêta",
        "blank_workflow": "Workflow vide",
        "cancel": "Annuler",
        "change": "Modifier",
        "clients": "Clients",
        "close": "Fermer",
        "content": "Contenu",
        "created_by_name_email": "Créé par : {{userName}} ({{userEmail}})",
        "currently_selected": "Actuellement sélectionné",
        "delete": "Supprimer",
        "description": "Description",
        "details": "Détails",
        "document": "Document",
        "edit": "Modifier",
        "existing_project": "Projet existant",
        "file_name": "Nom du fichier",
        "folder": "Dossier",
        "folder_name": "Nom du dossier",
        "from_template": "À partir du modèle",
        "incomplete_workflow": "Workflow incomplet",
        "last_updated": "Dernière mise à jour",
        "last_updated_by": "Dernière mise à jour par",
        "link_to_edit_options": "Modifier les options",
        "load_more": "Charger plus",
        "missing_name": "Nom manquant",
        "more_options": "Plus d'options",
        "name": "Nom",
        "new_action": "Nouvelle action",
        "new_folder": "Nouveau dossier",
        "new_project": "Nouveau projet",
        "ok": "OK",
        "optional_flag": "(facultatif)",
        "options": "Options",
        "preview": "Aperçu",
        "previous_actions": "Actions précédentes",
        "project": "Projet",
        "project_name": "Nom du projet",
        "properties": "Propriétés",
        "prospects": "Prospects",
        "relevant_variables": "Variables pertinentes",
        "run_details": "Détails d'exécution",
        "run_history": "Historique d'exécution",
        "save": "Enregistrer",
        "save_as_template": "Enregistrer en tant que modèle",
        "search": "Rechercher",
        "select": "Sélectionner",
        "selected": "sélectionné",
        "selected_clients": "Clients sélectionnés",
        "selected_prospects": "Prospects sélectionnés",
        "send": "Envoyer",
        "signers": "Signataires",
        "start": "Démarrer",
        "started": "Démarré",
        "started_by": "Démarré par",
        "status": "État",
        "submit": "Envoyer",
        "text": "Texte",
        "trigger": "Déclencheur",
        "trigger_variables": "Variables de déclenchement",
        "user": "Utilisateur",
        "variable": "Variable",
        "variables": "Variables",
        "workflow": "Workflow",
        "workflow_name": "Nom du workflow",
        "workflows": "Workflows"
      },
      "descriptions": {
        "action_output_symbol": "Valeur générée par cette action",
        "action_symbol": "Action précédente {{descriptionText}}",
        "available_variables": {
          "num": "1 variable disponible",
          "num_plural": "{{count}} variables disponibles"
        },
        "context_symbol": "Valeur définie lors de l'exécution",
        "file_location": "Emplacement où enregistrer le document signé",
        "merge_fields": "Ce modèle comporte des champs de données. Vous pouvez utiliser des variables pour les personnaliser pour chaque exécution, ou vous pouvez saisir du texte brut si vous souhaitez qu'ils restent identiques.",
        "properties_placeholder": "Décrivez votre workflow",
        "save_signed_document": "Enregistrer le document signé sous",
        "signed_suffix_label": "-signé",
        "text": "Chaîne de texte",
        "user": "Utilisateur de votre carnet d'adresses ShareFile",
        "variables": "Description pour les utilisateurs"
      },
      "file_export": {
        "back": "Précédent",
        "browse_integrations": "Parcourir les intégrations",
        "cancel": "Annuler",
        "continue": "Continuer",
        "description": "L'exportation envoie une copie du fichier sélectionné vers une autre application.",
        "destination": "Destination",
        "export": "Exporter",
        "export_description": "L'exportation enverra une copie des fichiers sélectionnés vers une autre application. Tout d'abord, choisissez l'application vers laquelle vous souhaitez envoyer les fichiers, puis spécifiez l'emplacement des fichiers à exporter dans cette application.",
        "export_title": "Exporter",
        "exporting": "Exportation en cours",
        "failed_to_load_integrations": "Le chargement des intégrations a échoué. Réessayez.",
        "file_failed_to_send": "Impossible d'envoyer le fichier {{fileName}}",
        "file_sent_successfully": "{{fileName}} a été envoyé avec succès",
        "files_failed_to_send": "Impossible d'envoyer {{count}} fichiers",
        "files_sent_successfully": "{{count}} fichiers envoyés avec succès",
        "more_files": "+ {{num}} fichiers supplémentaires",
        "no_destinations": "Vous n'avez pas encore configuré de destination. Installez une intégration compatible avec l'exportation pour continuer.",
        "one_more_file": "+ 1 fichier supplémentaire",
        "select_destination": "Sélectionner une destination",
        "starting_export": "Démarrage de l'exportation de fichiers",
        "step_description": "Choisissez d'abord une application de destination. Ensuite, spécifiez l'emplacement dans cette application où vous souhaitez exporter le fichier.",
        "view_details": "Afficher les détails",
        "workflow_failed": "Échec de l’exécution du workflow"
      },
      "messages": {
        "add_variable": "Utilisez une variable pour ajouter du contenu dynamique",
        "all_done": "Terminé",
        "all_fields_required": "Tous les champs sont obligatoires, sauf indication contraire.",
        "all_items_loaded": "Vous avez chargé tous les éléments",
        "cancel_run": "Voulez-vous annuler l'exécution de {{runName}} ? Toute étape incomplète sera perdue.",
        "cannot_delete_array_item": "Ce champ ne peut pas être vide.",
        "close_designer": "Êtes-vous sûr de vouloir fermer {{flowName}} ? Les modifications non enregistrées seront perdues.",
        "couldnt_load_selection": "Nous n'avons pas pu charger votre sélection. Essayez de recharger la page ou de changer votre sélection.",
        "delete_action": "Êtes-vous sûr de vouloir supprimer {{actionName}} ? Cela ne peut pas être annulé. Toutes les actions faisant référence à cette action seront interrompues.",
        "delete_flow": "Êtes-vous sûr de vouloir supprimer {{flowName}} ? Cela ne peut pas être annulé. La suppression de ce workflow n'annulera aucune exécution active.",
        "disable_flow": "Êtes-vous sûr de vouloir désactiver {{flowName}} ? La désactivation de ce workflow n'annulera aucune exécution active.",
        "error_loading_templates": "Erreur lors du chargement des modèles",
        "flow_complete": "Il s'agit de la dernière étape du workflow.",
        "flow_save_failure": "Nous n'avons pas pu enregistrer. Réessayez",
        "flow_save_success": "Workflow enregistré",
        "generic_error": "Une erreur s’est produite",
        "info_banner_content_existing_project": "Nous enregistrerons chaque document signé dans le projet {{projectName}}.",
        "info_banner_content_folder": "Nous enregistrerons chaque document signé dans un dossier.",
        "info_banner_content_folder_name_client": "Nous enregistrerons le document signé dans le dossier du client. S'ils n'ont pas encore de dossier, nous en créerons un pour vous.",
        "info_banner_content_folder_name_prospect": "Nous allons créer un dossier client pour vous. Si un dossier portant ce nom existe déjà, nous ajouterons l'adresse e-mail du prospect.",
        "info_banner_content_new_folder": "Nous enregistrerons chaque document signé dans un nouveau dossier.",
        "info_banner_content_new_project": "Nous enregistrerons chaque document signé dans un nouveau projet.",
        "invalid_flow": "Ce workflow comporte des erreurs. Vous ne pourrez pas l'exécuter tant qu'elles n'auront pas été corrigées par le propriétaire, {{ownerName}}.",
        "invalid_flow_creator": "Ce workflow comporte des erreurs. Vous ne pourrez pas l'exécuter tant qu'elles n'auront pas été corrigées. Modifiez le workflow et réessayez.",
        "loading_content": "Chargement du contenu...",
        "multiple_node_errors": "Cette étape contient {{numErrors}} erreurs",
        "next_step": "Étape suivante : ",
        "no_available_symbols": "Aucune variable appropriée n'est disponible pour cette option.",
        "no_edit_permission": "Vous n'êtes pas autorisé à modifier ce workflow.",
        "no_items": "Aucun élément",
        "no_runs": "Aucune exécution à afficher",
        "no_templates": "Aucun modèle trouvé",
        "no_workflows": "Aucun workflow à afficher",
        "opens_in_new_tab": "S’ouvre dans un nouvel onglet",
        "please_wait": "Veuillez patienter...",
        "redirecting": "Redirection dans {{secondsRemaining}} secondes...",
        "required_fields_count": "{{numFilledIn}} champs obligatoires remplis sur {{numRequired}}",
        "run_cancelation_failed": "L'annulation de l'exécution de {{runName}} a échoué.",
        "run_canceled": "Exécution de {{runName}} annulée avec succès.",
        "save_warning": "Ce workflow ne pourra pas s'exécuter dans son état actuel. Êtes-vous sûr de vouloir enregistrer ?",
        "send_agreements_success": "Le contrat client a été envoyé.",
        "single_node_error": "Cette étape contient une erreur",
        "something_went_wrong": "Un problème s'est produit",
        "start_info": "Vous devez être en mesure d'accéder à tous les fichiers, dossiers et fonctionnalités utilisés dans ce workflow pour pouvoir l'exécuter correctement. Contactez votre administrateur si vous avez besoin d'une licence de signature ou d'un accès à un dossier.",
        "start_warning": "Nous avons besoin de plus d'informations avant de pouvoir démarrer ce workflow. Assurez-vous d'avoir rempli tous les champs obligatoires.",
        "template_fetch_error": "Nous n'avons pas pu récupérer votre modèle",
        "template_not_selected": "Aucun modèle de document ajouté",
        "unknown_variable": "Cette variable a été supprimée",
        "workflow_failed": "{{workflowName}} a échoué",
        "workflow_started": "{{workflowName}} démarré"
      },
      "run_logs": {
        "flow_not_found": "Workflow introuvable",
        "log_strings": {
          "action_completed": "Action {{actionName}} terminée",
          "action_started": "Action {{actionName}} démarrée",
          "error_loading_action_label": "Action sans nom",
          "loading_action_label": "...",
          "workflow_canceled": "Workflow annulé",
          "workflow_completed": "Workflow terminé",
          "workflow_failed": "Impossible de terminer le workflow",
          "workflow_started": "Workflow démarré"
        },
        "logs_empty": "Aucune activité pour le moment",
        "logs_fetching_error": "Impossible de charger l'activité",
        "run_not_found": "Entrée d'activité introuvable"
      },
      "run_status": {
        "canceled": "Annulé",
        "canceling": "Annulation",
        "closed": "Terminé",
        "failed": "Erreur",
        "open": "En cours",
        "unknown": "Inconnu"
      },
      "symbol_tags": {
        "error": "#ERREUR",
        "reference_error": "#REFERREUR"
      },
      "titles": {
        "dashboard": "Workflows automatisés",
        "flow_options": "Démarrer {{workflowName}}",
        "merge_fields": "Champs de données du modèle",
        "selected_template": "Modèle :",
        "send_agreements": "Envoyer le contrat client",
        "send_agreements_alternate": "Envoyer le contrat",
        "template_preview": "Aperçu - Contrat client",
        "template_preview_alternate": "Aperçu - Contrat"
      },
      "user_actions": {
        "add_automation": "Ajouter l'automatisation",
        "add_trigger_variable": "Ajouter variable de déclenchement",
        "add_variable": "+ Ajouter variable",
        "add_workflow": "Ajouter workflow",
        "cancel_flow_run": "Annuler exécution du workflow",
        "cancel_run": "Annuler exécution",
        "change_template": "Changer de modèle",
        "close_sidebar": "Fermer volet de configuration",
        "close_workflow": "Fermer workflow",
        "contact_owner": "Contacter le propriétaire",
        "continue_editing": "Continuer l'édition",
        "create_a_template": "Créer un modèle",
        "create_automation": "Créer automatisation",
        "create_variable": "Créer une variable",
        "delete_action": "Supprimer action",
        "delete_flow": "Supprimer workflow",
        "disable_flow": "Désactiver workflow",
        "make_field_optional": "Rendre le champ facultatif",
        "make_field_required": "Rendre le champ obligatoire",
        "search_project": "Rechercher un projet...",
        "select_a_template": "Sélectionner un modèle",
        "select_a_trigger": "Sélectionner un déclencheur",
        "select_a_value": "Sélectionner une valeur",
        "select_an_action": "Sélectionner une action",
        "select_folder": "Sélectionner un dossier",
        "select_item": "Sélectionner cet élément",
        "select_next_client": "Sélectionner client suivant",
        "select_next_prospect": "Sélectionner prospect suivant",
        "select_prev_client": "Sélectionner client précédent",
        "select_prev_prospect": "Sélectionner prospect précédent",
        "set_fields": "Définir les champs de données",
        "show_sidebar": "Afficher volet de configuration",
        "start_flow": "Démarrer workflow",
        "view_run_details": "Afficher les détails",
        "view_run_history": "Afficher les exécutions"
      },
      "user_prompts": {
        "client_send_agreements_selection": "Sélectionnez un client pour envoyer un contrat",
        "configure_options": "Nous avons besoin d'un peu plus d'informations pour démarrer ce workflow.",
        "create_template_help": "Vous ne trouvez pas ce que vous cherchez ?",
        "prospect_send_agreements_selection": "Sélectionnez un prospect pour envoyer un contrat",
        "search_content": "Rechercher contenu...",
        "search_templates": "Rechercher modèles...",
        "search_variables": "Rechercher variables...",
        "what_happens_next": "Étape suivante"
      }
    }
  },
  {
    "key": "it",
    "content": {
      "common": {
        "action": "Azione",
        "actions": "Azioni",
        "active": "Attivo",
        "add": "Aggiungi",
        "all_activity": "Tutte le attività",
        "all_variables": "Tutte le variabili",
        "automations": "Automazioni",
        "back": "Indietro",
        "beta": "Beta",
        "blank_workflow": "Flusso di lavoro vuoto",
        "cancel": "Annulla",
        "change": "Modifica",
        "clients": "Clienti",
        "close": "Chiudi",
        "content": "Contenuto",
        "created_by_name_email": "Creato da: {{userName}} ({{userEmail}})",
        "currently_selected": "Attualmente selezionato",
        "delete": "Elimina",
        "description": "Descrizione",
        "details": "Dettagli",
        "document": "Documento",
        "edit": "Modifica",
        "existing_project": "Progetto esistente",
        "file_name": "Nome file",
        "folder": "Cartella",
        "folder_name": "Nome cartella",
        "from_template": "Da modello",
        "incomplete_workflow": "Flusso di lavoro incompleto",
        "last_updated": "Ultimo aggiornamento",
        "last_updated_by": "Autore ultimo aggiornamento",
        "link_to_edit_options": "Modifica opzioni",
        "load_more": "Carica altro",
        "missing_name": "Nome mancante",
        "more_options": "Più opzioni",
        "name": "Nome",
        "new_action": "Nuova azione",
        "new_folder": "Nuova cartella",
        "new_project": "Nuovo progetto",
        "ok": "OK",
        "optional_flag": "(opzionale)",
        "options": "Opzioni",
        "preview": "Anteprima",
        "previous_actions": "Azioni precedenti",
        "project": "Progetto",
        "project_name": "Nome del progetto",
        "properties": "Proprietà",
        "prospects": "Clienti potenziali",
        "relevant_variables": "Variabili rilevanti",
        "run_details": "Dettagli dell'esecuzione",
        "run_history": "Cronologia delle esecuzioni",
        "save": "Salva",
        "save_as_template": "Salva come modello",
        "search": "Cerca",
        "select": "Seleziona",
        "selected": "selezionato",
        "selected_clients": "Clienti selezionati",
        "selected_prospects": "Clienti potenziali selezionati",
        "send": "Invia",
        "signers": "Firmatari",
        "start": "Avvia",
        "started": "Avviato",
        "started_by": "Avviata da",
        "status": "Stato",
        "submit": "Invia",
        "text": "Testo",
        "trigger": "Attivazione",
        "trigger_variables": "Variabili di attivazione",
        "user": "Utente",
        "variable": "Variabile",
        "variables": "Variabili",
        "workflow": "Flusso di lavoro",
        "workflow_name": "Nome flusso di lavoro",
        "workflows": "Flussi di lavoro"
      },
      "descriptions": {
        "action_output_symbol": "Valore generato da questa azione",
        "action_symbol": "Azione precedente{{descriptionText}}",
        "available_variables": {
          "num": "1 variabile disponibile",
          "num_plural": "{{count}} variabili disponibili"
        },
        "context_symbol": "Valore definito in fase di esecuzione",
        "file_location": "Posizione in cui salvare il documento firmato",
        "merge_fields": "Questo modello contiene campi dati. È possibile utilizzare variabili per personalizzarli per ogni esecuzione oppure immettere testo normale se si desidera che rimangano invariate.",
        "properties_placeholder": "Descrivere il proprio flusso di lavoro",
        "save_signed_document": "Salva documento firmato come",
        "signed_suffix_label": "-firmato",
        "text": "Una stringa di testo",
        "user": "Un utente della tua rubrica di ShareFile",
        "variables": "Descrizione per gli utenti"
      },
      "file_export": {
        "back": "Indietro",
        "browse_integrations": "Sfoglia le integrazioni",
        "cancel": "Annulla",
        "continue": "Continua",
        "description": "L'esportazione invia una copia del file selezionato a un'altra applicazione.",
        "destination": "Destinazione",
        "export": "Esporta",
        "export_description": "Con l'esportazione sarà inviata una copia dei file selezionati a un'altra applicazione. Innanzitutto, scegliere un'applicazione a cui inviare e quindi specificare la posizione in cui si desidera esportare il file.",
        "export_title": "Esporta",
        "exporting": "Esportazione",
        "failed_to_load_integrations": "Impossibile caricare le integrazioni. Riprovare.",
        "file_failed_to_send": "Impossibile inviare {{fileName}}",
        "file_sent_successfully": "{{fileName}} inviato correttamente",
        "files_failed_to_send": "Impossibile inviare {{count}} file",
        "files_sent_successfully": "{{count}} file inviati correttamente",
        "more_files": "+ {{num}} altri file",
        "no_destinations": "Non è stata ancora configurata nessuna destinazione. Installare un'integrazione che supporti l'esportazione per continuare.",
        "one_more_file": "+ 1 altro file",
        "select_destination": "Seleziona una destinazione",
        "starting_export": "Avvio dell'esportazione del file",
        "step_description": "Innanzitutto, si sceglierà un'applicazione che dovrà essere la destinazione. Successivamente si specificherà la posizione di quell'applicazione in cui si desidera esportare il file.",
        "view_details": "Visualizza dettagli",
        "workflow_failed": "Impossibile eseguire il flusso di lavoro"
      },
      "messages": {
        "add_variable": "Utilizzare una variabile per aggiungere contenuto dinamico",
        "all_done": "Tutto fatto!",
        "all_fields_required": "Tutti i campi sono obbligatori se non diversamente specificato.",
        "all_items_loaded": "Sono stati caricati tutti gli elementi",
        "cancel_run": "Annullare questa esecuzione {{runName}}? Eventuali passaggi incompleti andranno persi.",
        "cannot_delete_array_item": "Questo non può essere vuoto.",
        "close_designer": "Chiudere {{flowName}}? Le modifiche non salvate andranno perse.",
        "couldnt_load_selection": "Non è stato possibile caricare la selezione. Provare a ricaricare la pagina o a riselezionarla.",
        "delete_action": "Eliminare {{actionName}}? Questa operazione non può essere annullata. Tutte le azioni che fanno riferimento a questa azione verranno interrotte.",
        "delete_flow": "Eliminare {{flowName}}? Questa operazione non può essere annullata. L'eliminazione di questo flusso di lavoro non annullerà alcuna esecuzione attiva.",
        "disable_flow": "Disattivare {{flowName}}? La disattivazione di questo flusso di lavoro non annullerà alcuna esecuzione attiva.",
        "error_loading_templates": "Errore durante il caricamento dei modelli",
        "flow_complete": "Questo è il passaggio finale del flusso di lavoro.",
        "flow_save_failure": "Non siamo riusciti a salvare; riprovare",
        "flow_save_success": "Flusso di lavoro salvato",
        "generic_error": "Si è verificato un errore",
        "info_banner_content_existing_project": "Salveremo ogni documento firmato nel progetto {{projectName}}.",
        "info_banner_content_folder": "Salveremo ogni documento firmato in una cartella.",
        "info_banner_content_folder_name_client": "Salveremo il documento firmato nella cartella del cliente. Se non ha già una cartella, ne creeremo una.",
        "info_banner_content_folder_name_prospect": "Creeremo una cartella cliente. Se esiste già una cartella con questo nome, aggiungeremo l'indirizzo e-mail del potenziale cliente.",
        "info_banner_content_new_folder": "Salveremo ogni documento firmato in una nuova cartella.",
        "info_banner_content_new_project": "Salveremo ogni documento firmato in un nuovo progetto.",
        "invalid_flow": "Questo flusso di lavoro contiene errori. Non sarà possibile eseguirlo fino a quando non saranno stati corretti dal proprietario, {{ownerName}}.",
        "invalid_flow_creator": "Questo flusso di lavoro contiene errori. Non sarà possibile eseguirlo fino a quando non saranno stati corretti. Modificare il flusso di lavoro e riprovare.",
        "loading_content": "Caricamento contenuto...",
        "multiple_node_errors": "Questo passaggio contiene {{numErrors}} errori",
        "next_step": "Passaggio successivo: ",
        "no_available_symbols": "Non sono disponibili variabili appropriate per questa opzione.",
        "no_edit_permission": "Non si dispone dell'autorizzazione per modificare questo flusso di lavoro.",
        "no_items": "Nessun elemento",
        "no_runs": "Nessuna esecuzione da mostrare",
        "no_templates": "Nessun modello trovato",
        "no_workflows": "Nessun flusso di lavoro da mostrare",
        "opens_in_new_tab": "Si apre in una nuova scheda",
        "please_wait": "Attendere ...",
        "redirecting": "Reindirizzamento tra {{secondsRemaining}} secondi...",
        "required_fields_count": "{{numFilledIn}} di {{numRequired}} campi obbligatori completi",
        "run_cancelation_failed": "Annullamento dell'esecuzione {{runName}} non riuscito.",
        "run_canceled": "Esecuzione {{runName}} annullata correttamente.",
        "save_warning": "Questo flusso di lavoro non potrà essere eseguito nello stato corrente. Salvare?",
        "send_agreements_success": "Contratto con il cliente inviato correttamente.",
        "single_node_error": "Questo passaggio contiene un errore",
        "something_went_wrong": "Oh no... Qualcosa è andato storto",
        "start_info": "È necessario essere in grado di accedere a tutti i file, le cartelle e le funzionalità utilizzate in questo flusso di lavoro per eseguirlo correttamente. Contattare l'amministratore se si necessita di una licenza di firma o dell'accesso a una cartella.",
        "start_warning": "Abbiamo necessità di ulteriori informazioni prima di poter avviare questo flusso di lavoro. Assicurarsi di aver completato tutti i campi obbligatori.",
        "template_fetch_error": "Non siamo riusciti a recuperare il modello",
        "template_not_selected": "Nessun modello di documento aggiunto",
        "unknown_variable": "Questa variabile è stata eliminata",
        "workflow_failed": "{{workflowName}} non riuscito",
        "workflow_started": "Avviato {{workflowName}}"
      },
      "run_logs": {
        "flow_not_found": "Flusso di lavoro non trovato",
        "log_strings": {
          "action_completed": "Azione {{actionName}} finita",
          "action_started": "Azione {{actionName}} avviata",
          "error_loading_action_label": "Azione senza nome",
          "loading_action_label": "...",
          "workflow_canceled": "Flusso di lavoro annullato",
          "workflow_completed": "Flusso di lavoro completato",
          "workflow_failed": "Impossibile completare il flusso di lavoro",
          "workflow_started": "Flusso di lavoro avviato"
        },
        "logs_empty": "Ancora nessuna attività",
        "logs_fetching_error": "Impossibile caricare l'attività",
        "run_not_found": "Voce di attività non trovata"
      },
      "run_status": {
        "canceled": "Annullato",
        "canceling": "Annullamento",
        "closed": "Completato",
        "failed": "Errore",
        "open": "In corso",
        "unknown": "Sconosciuta"
      },
      "symbol_tags": {
        "error": "#ERRORE",
        "reference_error": "#REFERROR"
      },
      "titles": {
        "dashboard": "Flussi di lavoro automatizzati",
        "flow_options": "Avvia {{workflowName}}",
        "merge_fields": "Campi dati del modello",
        "selected_template": "Modello:",
        "send_agreements": "Invia contratto cliente",
        "send_agreements_alternate": "Invia contratto",
        "template_preview": "Anteprima - Contratto con il cliente",
        "template_preview_alternate": "Anteprima - Contratto"
      },
      "user_actions": {
        "add_automation": "Aggiungi automazione",
        "add_trigger_variable": "Aggiungi variabile di attivazione",
        "add_variable": "+ Aggiungi variabile",
        "add_workflow": "Aggiungi flusso di lavoro",
        "cancel_flow_run": "Annulla l'esecuzione del flusso di lavoro",
        "cancel_run": "Annulla esecuzione",
        "change_template": "Modifica modello",
        "close_sidebar": "Chiudi riquadro di configurazione",
        "close_workflow": "Chiudi flusso di lavoro",
        "contact_owner": "Contatta il proprietario",
        "continue_editing": "Continua a modificare",
        "create_a_template": "Crea un modello",
        "create_automation": "Crea automazione",
        "create_variable": "Crea variabile",
        "delete_action": "Elimina azione",
        "delete_flow": "Elimina flusso di lavoro",
        "disable_flow": "Disattiva flusso di lavoro",
        "make_field_optional": "Rendi facoltativo il campo",
        "make_field_required": "Rendi obbligatorio il campo",
        "search_project": "Cerca un progetto...",
        "select_a_template": "Seleziona un modello",
        "select_a_trigger": "Selezionare un'attivazione",
        "select_a_value": "Seleziona un valore",
        "select_an_action": "Selezionare un'azione",
        "select_folder": "Seleziona cartella",
        "select_item": "Seleziona questo elemento",
        "select_next_client": "Seleziona il client successivo",
        "select_next_prospect": "Seleziona il cliente potenziale successivo",
        "select_prev_client": "Seleziona il cliente precedente",
        "select_prev_prospect": "Seleziona il cliente potenziale precedente",
        "set_fields": "Imposta campi dati",
        "show_sidebar": "Mostra riquadro di configurazione",
        "start_flow": "Avvia flusso di lavoro",
        "view_run_details": "Visualizza dettagli",
        "view_run_history": "Visualizza esecuzioni"
      },
      "user_prompts": {
        "client_send_agreements_selection": "Selezionare un cliente a cui inviare un accordo",
        "configure_options": "Abbiamo bisogno di qualche informazione in più per avviare questo flusso di lavoro.",
        "create_template_help": "Non trovi quello che stai cercando?",
        "prospect_send_agreements_selection": "Selezionare un cliente potenziale a cui inviare un accordo",
        "search_content": "Cerca contenuti...",
        "search_templates": "Modelli di ricerca...",
        "search_variables": "Cerca variabili...",
        "what_happens_next": "Cosa succede dopo?"
      }
    }
  },
  {
    "key": "ja",
    "content": {
      "common": {
        "action": "操作",
        "actions": "操作",
        "active": "アクティブ",
        "add": "追加",
        "all_activity": "すべてのアクティビティ",
        "all_variables": "すべての変数",
        "automations": "自動処理",
        "back": "戻る",
        "beta": "ベータ版",
        "blank_workflow": "空のワークフロー",
        "cancel": "キャンセル",
        "change": "変更",
        "clients": "顧客",
        "close": "閉じる",
        "content": "コンテンツ",
        "created_by_name_email": "作成者: {{userName}} ({{userEmail}})",
        "currently_selected": "現在選択中",
        "delete": "削除",
        "description": "説明",
        "details": "詳細",
        "document": "ドキュメント",
        "edit": "編集",
        "existing_project": "既存のプロジェクト",
        "file_name": "ファイル名",
        "folder": "フォルダー",
        "folder_name": "フォルダー名",
        "from_template": "テンプレート利用",
        "incomplete_workflow": "未完了のワークフロー",
        "last_updated": "最終更新",
        "last_updated_by": "最終更新者",
        "link_to_edit_options": "オプションの編集",
        "load_more": "さらに読み込む",
        "missing_name": "名前がありません",
        "more_options": "追加オプション",
        "name": "名前",
        "new_action": "新しい操作",
        "new_folder": "新しいフォルダー",
        "new_project": "新しいプロジェクト",
        "ok": "OK",
        "optional_flag": "（オプション）",
        "options": "オプション",
        "preview": "プレビュー",
        "previous_actions": "以前の操作",
        "project": "プロジェクト",
        "project_name": "プロジェクト名",
        "properties": "プロパティ",
        "prospects": "見込み客",
        "relevant_variables": "関連する変数",
        "run_details": "実行の詳細",
        "run_history": "実行の履歴",
        "save": "保存",
        "save_as_template": "テンプレートとして保存する",
        "search": "検索",
        "select": "選択",
        "selected": "選択済み",
        "selected_clients": "選択済みの顧客",
        "selected_prospects": "選択済みの見込み客",
        "send": "送信",
        "signers": "署名者",
        "start": "開始",
        "started": "開始",
        "started_by": "開始ユーザー",
        "status": "状態",
        "submit": "送信",
        "text": "テキスト",
        "trigger": "トリガー",
        "trigger_variables": "トリガー変数",
        "user": "ユーザー",
        "variable": "変数",
        "variables": "変数",
        "workflow": "ワークフロー",
        "workflow_name": "ワークフロー名",
        "workflows": "ワークフロー"
      },
      "descriptions": {
        "action_output_symbol": "この操作で生成される値",
        "action_symbol": "以前の操作 {{descriptionText}}",
        "available_variables": {
          "num": "1 個の変数が利用可能",
          "num_plural": "{{count}} 個の変数が利用可能"
        },
        "context_symbol": "ランタイムで定義される値",
        "file_location": "署名済みドキュメントの保存場所",
        "merge_fields": "このテンプレートにはデータ フィールドがあります。変数を使用して実行ごとにカスタマイズすることも、固定の値にする場合は テキスト形式で入力することもできます。",
        "properties_placeholder": "ワークフローを説明する",
        "save_signed_document": "署名済みドキュメントに名前を付けて保存: ",
        "signed_suffix_label": "-署名済み",
        "text": "テキストの文字列",
        "user": "ShareFile アドレス帳のユーザー",
        "variables": "ユーザー向けの説明"
      },
      "file_export": {
        "back": "戻る",
        "browse_integrations": "統合を参照する",
        "cancel": "キャンセル",
        "continue": "続行",
        "description": "エクスポートすると、選択したファイルのコピーが別のアプリケーションに送信されます。",
        "destination": "アップロード先",
        "export": "エクスポート",
        "export_description": "エクスポートすると、選択したファイルのコピーが別のアプリケーションに送信されます。まず、送信先のアプリケーションを選択し、ファイルをエクスポートするそのアプリケーション内の場所を指定します。",
        "export_title": "エクスポート",
        "exporting": "エクスポート中",
        "failed_to_load_integrations": "統合を読み込めません。再試行してください。",
        "file_failed_to_send": "{{fileName}} を送信できませんでした",
        "file_sent_successfully": "{{fileName}} を送信しました",
        "files_failed_to_send": "{{count}} 個のファイルを送信できませんでした",
        "files_sent_successfully": "{{count}} 個のファイルが送信されました",
        "more_files": "+ {{num}} 個の追加ファイル",
        "no_destinations": "エクスポート先を構成していません。続行するには、エクスポートをサポートする統合をインストールしてください。",
        "one_more_file": "+ 1 個の追加ファイル",
        "select_destination": "エクスポート先を選択する",
        "starting_export": "ファイルのエクスポートを開始します",
        "step_description": "まず、エクスポート先となるアプリケーションを選択します。次に、ファイルをエクスポートするアプリケーションの場所を指定します。",
        "view_details": "詳細の表示",
        "workflow_failed": "ワークフローの実行に失敗しました"
      },
      "messages": {
        "add_variable": "変数を使用して動的コンテンツを追加する",
        "all_done": "すべて完了しました",
        "all_fields_required": "特に明記されていない限り、すべてのフィールドへの入力が必須です。",
        "all_items_loaded": "すべてのアイテムを読み込みました",
        "cancel_run": "この {{runName}} の実行をキャンセルしますか? 未完了のステップの内容は失われます。",
        "cannot_delete_array_item": "これを空白にすることはできません。",
        "close_designer": "{{flowName}} を終了してもよろしいですか? 保存されていない変更は失われます。",
        "couldnt_load_selection": "選択内容を読み込めませんでした。ページを再読み込みするか、選択し直してください。",
        "delete_action": "{{actionName}} を削除してもよろしいですか? これを元に戻すことはできません。この操作を参照する操作はすべて機能しなくなります。",
        "delete_flow": "{{flowName}} を削除してもよろしいですか? これを元に戻すことはできません。このワークフローを削除しても、アクティブな実行はキャンセルされません。",
        "disable_flow": "{{flowName}} を無効にしてもよろしいですか? このワークフローを無効にしても、アクティブな実行はキャンセルされません。",
        "error_loading_templates": "テンプレートの読み込みエラー",
        "flow_complete": "これはワークフローの最後のステップです。",
        "flow_save_failure": "保存できませんでした。再試行してください",
        "flow_save_success": "ワークフローが保存されました",
        "generic_error": "エラーが発生しました",
        "info_banner_content_existing_project": "署名済みの各ドキュメントを {{projectName}} プロジェクトに保存します。",
        "info_banner_content_folder": "署名済みの各ドキュメントをフォルダーに保存します。",
        "info_banner_content_folder_name_client": "署名済みのドキュメントを顧客のフォルダーに保存します。フォルダーがまだない場合は、フォルダーが作成されます。",
        "info_banner_content_folder_name_prospect": "顧客のフォルダーが作成されます。この名前のフォルダーが既に存在する場合は、見込み客のメール アドレスが追加されます。",
        "info_banner_content_new_folder": "署名済みの各ドキュメントを新しいフォルダーに保存します。",
        "info_banner_content_new_project": "署名済みの各ドキュメントを新しいプロジェクトに保存します。",
        "invalid_flow": "このワークフローにはエラーがあり、所有者である {{ownerName}} によって修正されるまでは実行できません。",
        "invalid_flow_creator": "このワークフローにはエラーがあります。修正されるまでは実行できません。ワークフローを編集して再試行してください。",
        "loading_content": "コンテンツを読み込んでいます...",
        "multiple_node_errors": "このステップには {{numErrors}} 個のエラーがあります",
        "next_step": "次のステップ: ",
        "no_available_symbols": "このオプションに使用できる適切な変数はありません。",
        "no_edit_permission": "このワークフローを編集する権限がありません。",
        "no_items": "アイテムがありません",
        "no_runs": "表示する実行がありません",
        "no_templates": "テンプレートが見つかりませんでした",
        "no_workflows": "表示するワークフローがありません",
        "opens_in_new_tab": "新しいタブで開きます",
        "please_wait": "しばらくお待ちください ...",
        "redirecting": "{{secondsRemaining}} 秒後にリダイレクトします...",
        "required_fields_count": "{{numFilledIn}}/{{numRequired}} の必須フィールドが完了しています",
        "run_cancelation_failed": "{{runName}} の実行のキャンセルに失敗しました。",
        "run_canceled": "{{runName}} の実行がキャンセルされました。",
        "save_warning": "このワークフローは現在の状態では実行できません。保存してもよろしいですか?",
        "send_agreements_success": "顧客契約の送信に成功しました。",
        "single_node_error": "このステップにはエラーがあります",
        "something_went_wrong": "問題が発生しました",
        "start_info": "ワークフローを正常に実行するには、このワークフローで使用されるすべてのファイル、フォルダー、および機能にアクセスできる必要があります。署名ライセンスまたはフォルダーへのアクセスが必要な場合は、管理者にお問い合わせください。",
        "start_warning": "このワークフローを開始する前に、さらに情報が必要です。すべての必須フィールドに入力していることを確認してください。",
        "template_fetch_error": "テンプレートを取得できませんでした",
        "template_not_selected": "ドキュメント テンプレートが追加されていません",
        "unknown_variable": "この変数は削除されました",
        "workflow_failed": "{{workflowName}} が失敗しました",
        "workflow_started": "{{workflowName}} を開始しました"
      },
      "run_logs": {
        "flow_not_found": "ワークフローが見つかりません",
        "log_strings": {
          "action_completed": "操作 {{actionName}} を終了しました",
          "action_started": "操作 {{actionName}} を開始しました",
          "error_loading_action_label": "無題の操作",
          "loading_action_label": "...",
          "workflow_canceled": "ワークフローを開始しました",
          "workflow_completed": "ワークフローを完了しました",
          "workflow_failed": "ワークフローを完了できませんでした",
          "workflow_started": "ワークフローを開始しました"
        },
        "logs_empty": "まだアクティビティはありません",
        "logs_fetching_error": "アクティビティを読み込めません",
        "run_not_found": "アクティビティのエントリが見つかりません"
      },
      "run_status": {
        "canceled": "キャンセル済み",
        "canceling": "キャンセル中",
        "closed": "完了済み",
        "failed": "エラー",
        "open": "進行中",
        "unknown": "不明"
      },
      "symbol_tags": {
        "error": "#エラー",
        "reference_error": "#REFERROR"
      },
      "titles": {
        "dashboard": "自動化されたワークフロー",
        "flow_options": "{{workflowName}} を開始する",
        "merge_fields": "テンプレート データ フィールド",
        "selected_template": "テンプレート:",
        "send_agreements": "顧客契約を送信する",
        "send_agreements_alternate": "契約を送信する",
        "template_preview": "プレビュー - 顧客契約",
        "template_preview_alternate": "プレビュー - 契約"
      },
      "user_actions": {
        "add_automation": "自動処理を追加する",
        "add_trigger_variable": "トリガー変数を追加する",
        "add_variable": "+ 変数を追加する",
        "add_workflow": "ワークフローを追加する",
        "cancel_flow_run": "ワークフローの実行をキャンセルする",
        "cancel_run": "実行をキャンセルする",
        "change_template": "テンプレートを変更する",
        "close_sidebar": "構成ペインを閉じる",
        "close_workflow": "ワークフローを閉じる",
        "contact_owner": "所有者に連絡する",
        "continue_editing": "編集を続ける",
        "create_a_template": "テンプレートを作成する",
        "create_automation": "自動処理を作成する",
        "create_variable": "変数を作成する",
        "delete_action": "操作を削除する",
        "delete_flow": "ワークフローを削除する",
        "disable_flow": "ワークフローを無効にする",
        "make_field_optional": "フィールドをオプションにする",
        "make_field_required": "フィールドを必須にする",
        "search_project": "プロジェクトを検索する...",
        "select_a_template": "テンプレートを選択する",
        "select_a_trigger": "トリガーを選択する",
        "select_a_value": "値を選択する",
        "select_an_action": "操作を選択する",
        "select_folder": "フォルダーを選択する",
        "select_item": "このアイテムを選択する",
        "select_next_client": "次の顧客を選択する",
        "select_next_prospect": "次の見込み客を選択する",
        "select_prev_client": "前の顧客を選択する",
        "select_prev_prospect": "前の見込み客を選択する",
        "set_fields": "データ フィールドを設定する",
        "show_sidebar": "構成ペインを表示する",
        "start_flow": "ワークフローを開始する",
        "view_run_details": "詳細を表示する",
        "view_run_history": "実行を表示する"
      },
      "user_prompts": {
        "client_send_agreements_selection": "契約書を送信する顧客を選択してください",
        "configure_options": "このワークフローを開始するには、追加の情報が必要です。",
        "create_template_help": "探しているものが見つからない場合",
        "prospect_send_agreements_selection": "契約書を送信する見込み客を選択してください",
        "search_content": "コンテンツを検索する...",
        "search_templates": "テンプレートを検索する...",
        "search_variables": "変数を検索する...",
        "what_happens_next": "次の作業"
      }
    }
  },
  {
    "key": "nl",
    "content": {
      "common": {
        "action": "Actie",
        "actions": "Acties",
        "active": "Actief",
        "add": "Toevoegen",
        "all_activity": "Alle activiteit",
        "all_variables": "Alle variabelen",
        "automations": "Automatiseringen",
        "back": "Terug",
        "beta": "Bèta",
        "blank_workflow": "Lege werkstroom",
        "cancel": "Annuleren",
        "change": "Wijzigen",
        "clients": "Klanten",
        "close": "Sluiten",
        "content": "Inhoud",
        "created_by_name_email": "Gemaakt door: {{userName}} ({{userEmail}})",
        "currently_selected": "Momenteel geselecteerd",
        "delete": "Verwijderen",
        "description": "Beschrijving",
        "details": "Details",
        "document": "Document",
        "edit": "Bewerken",
        "existing_project": "Bestaand project",
        "file_name": "Bestandsnaam",
        "folder": "Map",
        "folder_name": "Mapnaam",
        "from_template": "Van sjabloon",
        "incomplete_workflow": "Onvolledige werkstroom",
        "last_updated": "Laatst bijgewerkt",
        "last_updated_by": "Laatst bijgewerkt door",
        "link_to_edit_options": "Opties bewerken",
        "load_more": "Meer laden",
        "missing_name": "Ontbrekende naam",
        "more_options": "Meer opties",
        "name": "Naam",
        "new_action": "Nieuwe actie",
        "new_folder": "Nieuwe map",
        "new_project": "Nieuw project",
        "ok": "OK",
        "optional_flag": "(optioneel)",
        "options": "Opties",
        "preview": "Voorbeeldweergave",
        "previous_actions": "Vorige acties",
        "project": "Project",
        "project_name": "Projectnaam",
        "properties": "Eigenschappen",
        "prospects": "Potentiële klanten",
        "relevant_variables": "Relevante variabelen",
        "run_details": "Details van uitvoering",
        "run_history": "Geschiedenis van uitvoering",
        "save": "Opslaan",
        "save_as_template": "Opslaan als sjabloon",
        "search": "Zoeken",
        "select": "Selecteren",
        "selected": "geselecteerd",
        "selected_clients": "Geselecteerde klanten",
        "selected_prospects": "Geselecteerde potentiële klanten",
        "send": "Verzenden",
        "signers": "Ondertekenaars",
        "start": "Start",
        "started": "Gestart",
        "started_by": "Gestart door",
        "status": "Status",
        "submit": "Verzenden",
        "text": "Tekst",
        "trigger": "Trigger",
        "trigger_variables": "Triggervariabelen",
        "user": "Gebruiker",
        "variable": "Variabele",
        "variables": "Variabelen",
        "workflow": "Werkstroom",
        "workflow_name": "Werkstroomnaam",
        "workflows": "Werkstromen"
      },
      "descriptions": {
        "action_output_symbol": "Waarde gegenereerd door deze actie",
        "action_symbol": "Vorige actie{{descriptionText}}",
        "available_variables": {
          "num": "1 variabele beschikbaar",
          "num_plural": "{{count}} variabelen beschikbaar"
        },
        "context_symbol": "Waarde gedefinieerd tijdens runtime",
        "file_location": "Locatie om ondertekend document op te slaan",
        "merge_fields": "Deze sjabloon bevat gegevensvelden. U kunt variabelen gebruiken om ze voor elke uitvoering aan te passen, of u kunt platte tekst invoeren als u wilt dat ze hetzelfde blijven.",
        "properties_placeholder": "Beschrijf uw werkstroom",
        "save_signed_document": "Ondertekend document opslaan als",
        "signed_suffix_label": "-ondertekend",
        "text": "Een tekenreeks",
        "user": "Een gebruiker uit uw ShareFile-adresboek",
        "variables": "Beschrijving voor gebruikers"
      },
      "file_export": {
        "back": "Terug",
        "browse_integrations": "Door integraties bladeren",
        "cancel": "Annuleren",
        "continue": "Doorgaan",
        "description": "Exporteren stuurt een kopie van het geselecteerde bestand naar een andere toepassing.",
        "destination": "Doel",
        "export": "Exporteren",
        "export_description": "Bij het exporteren wordt een kopie van de geselecteerde bestanden naar een andere toepassing gestuurd. Kies eerst een toepassing waarnaar u wilt verzenden en geef vervolgens aan waar in die toepassing u het bestand wilt exporteren.",
        "export_title": "Exporteren",
        "exporting": "Exporteren",
        "failed_to_load_integrations": "Kan integraties niet laden. Probeer het opnieuw.",
        "file_failed_to_send": "{{fileName}} kan niet worden verzonden",
        "file_sent_successfully": "{{fileName}} is verzonden",
        "files_failed_to_send": "{{count}} bestanden kunnen niet worden verzonden",
        "files_sent_successfully": "{{count}} bestanden zijn verzonden",
        "more_files": "+ {{num}} extra bestanden",
        "no_destinations": "U hebt nog geen bestemmingen geconfigureerd. Installeer een integratie die exporteren ondersteunt om door te gaan.",
        "one_more_file": "+ 1 extra bestand",
        "select_destination": "Bestemming selecteren",
        "starting_export": "Bestandsexport wordt gestart",
        "step_description": "Eerst kiest u een toepassing als bestemming. Vervolgens geeft u aan waar in dit toepassing u het bestand wilt exporteren.",
        "view_details": "Gegevens weergeven",
        "workflow_failed": "De werkstroom kan niet worden uitgevoerd"
      },
      "messages": {
        "add_variable": "Een variabele gebruiken om dynamische inhoud toe te voegen",
        "all_done": "Klaar!",
        "all_fields_required": "Alle velden zijn verplicht, tenzij anders vermeld.",
        "all_items_loaded": "U hebt alle items geladen",
        "cancel_run": "Wilt u deze uitvoering {{runName}} annuleren? Alle onvolledige stappen gaan verloren.",
        "cannot_delete_array_item": "Dit mag niet leeg zijn.",
        "close_designer": "Weet u zeker dat u {{flowName}} wilt sluiten? Uw niet-opgeslagen wijzigingen gaan verloren.",
        "couldnt_load_selection": "We kunnen uw selectie niet laden. Laad de pagina opnieuw of selecteer opnieuw.",
        "delete_action": "Weet u zeker dat u {{actionName}} wilt verwijderen? Dit kan niet ongedaan worden gemaakt. Alle acties die naar deze actie verwijzen, worden verbroken.",
        "delete_flow": "Weet u zeker dat u {{flowName}} wilt verwijderen? Dit kan niet ongedaan worden gemaakt. Als u deze werkstroom verwijdert, worden actieve uitvoeringen niet geannuleerd.",
        "disable_flow": "Weet u zeker dat u {{flowName}} wilt uitschakelen? Als u deze werkstroom uitschakelt, worden actieve uitvoeringen niet geannuleerd.",
        "error_loading_templates": "Fout bij het laden van sjablonen",
        "flow_complete": "Dit is de laatste stap van de werkstroom.",
        "flow_save_failure": "We konden niet opslaan, probeer het opnieuw",
        "flow_save_success": "Opgeslagen werkstroom",
        "generic_error": "Er is een fout opgetreden",
        "info_banner_content_existing_project": "We slaan elk ondertekend document op in het project {{projectName}}.",
        "info_banner_content_folder": "We slaan elk ondertekend document op in een map.",
        "info_banner_content_folder_name_client": "We slaan het ondertekende document op in de map van de klant. Als deze nog geen map heeft, maken we er een voor de klant.",
        "info_banner_content_folder_name_prospect": "We maken een klantmap voor u aan. Als er al een map met deze naam bestaat, voegen we het e-mailadres van de potentiële klant toe.",
        "info_banner_content_new_folder": "We slaan elk ondertekend document op in een nieuwe map.",
        "info_banner_content_new_project": "We slaan elk ondertekend document op in een nieuw project.",
        "invalid_flow": "Er zijn fouten in deze werkstroom. U kunt deze niet uitvoeren totdat ze door de eigenaar, {{ownerName}}, zijn gecorrigeerd.",
        "invalid_flow_creator": "Er zijn fouten in deze werkstroom. U kunt deze niet uitvoeren totdat ze zijn gecorrigeerd. Bewerk de werkstroom en probeer het opnieuw.",
        "loading_content": "Inhoud laden...",
        "multiple_node_errors": "Deze stap bevat {{numErrors}} fouten",
        "next_step": "Volgende stap: ",
        "no_available_symbols": "Er zijn geen geschikte variabelen beschikbaar voor deze optie.",
        "no_edit_permission": "U bent niet gemachtigd om deze werkstroom te bewerken.",
        "no_items": "Geen items",
        "no_runs": "Geen uitvoeringen om weer te geven",
        "no_templates": "Geen sjablonen gevonden",
        "no_workflows": "Geen werkstromen om weer te geven",
        "opens_in_new_tab": "Opent in een nieuw tabblad",
        "please_wait": "Even geduld ...",
        "redirecting": "U wordt over {{secondsRemaining}} seconden omgeleid...",
        "required_fields_count": "{{numFilledIn}} van {{numRequired}} verplichte velden ingevuld",
        "run_cancelation_failed": "Annuleren van uitvoering {{runName}} is mislukt.",
        "run_canceled": "Uitvoering {{runName}} is geannuleerd.",
        "save_warning": "Deze werkstroom kan in de huidige staat niet worden uitgevoerd. Weet u zeker dat u wilt opslaan?",
        "send_agreements_success": "Klantovereenkomst is verzonden.",
        "single_node_error": "Deze stap bevat een fout",
        "something_went_wrong": "Er is iets fout gegaan",
        "start_info": "U moet toegang hebben tot alle bestanden, mappen en functionaliteit die in deze werkstroom worden gebruikt om deze met succes uit te voeren. Neem contact op met uw beheerder als u een handtekeninglicentie of toegang tot een map nodig hebt.",
        "start_warning": "We hebben meer informatie nodig voordat we deze werkstroom kunnen starten. Zorg ervoor dat u alle verplichte velden hebt ingevuld.",
        "template_fetch_error": "We kunnen uw sjabloon niet ophalen",
        "template_not_selected": "Geen documentsjabloon toegevoegd",
        "unknown_variable": "Deze variabele is verwijderd",
        "workflow_failed": "{{workflowName}} is mislukt",
        "workflow_started": "{{workflowName}} gestart"
      },
      "run_logs": {
        "flow_not_found": "Werkstroom niet gevonden",
        "log_strings": {
          "action_completed": "Actie {{actionName}} voltooid",
          "action_started": "Actie {{actionName}} begonnen",
          "error_loading_action_label": "Naamloze actie",
          "loading_action_label": "...",
          "workflow_canceled": "Geannuleerde werkstroom",
          "workflow_completed": "Voltooide werkstroom",
          "workflow_failed": "Kan werkstroom niet voltooien",
          "workflow_started": "Gestarte werkstroom"
        },
        "logs_empty": "Nog geen activiteit",
        "logs_fetching_error": "Kan activiteit niet laden",
        "run_not_found": "Activiteitvermelding niet gevonden"
      },
      "run_status": {
        "canceled": "Geannuleerd",
        "canceling": "Annuleren",
        "closed": "Voltooid",
        "failed": "Fout",
        "open": "In uitvoering",
        "unknown": "Onbekend"
      },
      "symbol_tags": {
        "error": "#FOUT",
        "reference_error": "#REFERROR"
      },
      "titles": {
        "dashboard": "Geautomatiseerde werkstromen",
        "flow_options": "{{workflowName}} starten",
        "merge_fields": "Gegevensvelden van sjabloon",
        "selected_template": "Sjabloon:",
        "send_agreements": "Klantovereenkomst verzenden",
        "send_agreements_alternate": "Overeenkomst verzenden",
        "template_preview": "Voorbeeldweergave - Klantovereenkomst",
        "template_preview_alternate": "Voorbeeldweergave - Overeenkomst"
      },
      "user_actions": {
        "add_automation": "Automatisering toevoegen",
        "add_trigger_variable": "Triggervariabele toevoegen",
        "add_variable": "+ Variabele toevoegen",
        "add_workflow": "Werkstroom toevoegen",
        "cancel_flow_run": "Uitvoering van werkstroom annuleren",
        "cancel_run": "Uitvoering annuleren",
        "change_template": "Sjabloon wijzigen",
        "close_sidebar": "Configuratievenster sluiten",
        "close_workflow": "Werkstroom sluiten",
        "contact_owner": "Contact opnemen met de eigenaar",
        "continue_editing": "Doorgaan met bewerken",
        "create_a_template": "Sjabloon maken",
        "create_automation": "Automatisering maken",
        "create_variable": "Variabele maken",
        "delete_action": "Actie verwijderen",
        "delete_flow": "Werkstroom verwijderen",
        "disable_flow": "Werkstroom uitschakelen",
        "make_field_optional": "Veld optioneel maken",
        "make_field_required": "Veld verplicht maken",
        "search_project": "Een project zoeken...",
        "select_a_template": "Een sjabloon selecteren",
        "select_a_trigger": "Een trigger selecteren",
        "select_a_value": "Een waarde selecteren",
        "select_an_action": "Een actie selecteren",
        "select_folder": "Map selecteren",
        "select_item": "Dit item selecteren",
        "select_next_client": "Volgende klant selecteren",
        "select_next_prospect": "Volgende potentiële klant selecteren",
        "select_prev_client": "Vorige klant selecteren",
        "select_prev_prospect": "Vorige potentiële klant selecteren",
        "set_fields": "Gegevensvelden instellen",
        "show_sidebar": "Configuratievenster tonen",
        "start_flow": "Werkstroom starten",
        "view_run_details": "Details weergeven",
        "view_run_history": "Uitvoeringen weergeven"
      },
      "user_prompts": {
        "client_send_agreements_selection": "Selecteer een klant om een overeenkomst te verzenden",
        "configure_options": "We hebben wat meer informatie nodig om deze werkstroom te starten.",
        "create_template_help": "Kunt u niet vinden wat u zoekt?",
        "prospect_send_agreements_selection": "Selecteer een potentiële klant om een overeenkomst te verzenden",
        "search_content": "Inhoud zoeken...",
        "search_templates": "Sjablonen zoeken...",
        "search_variables": "Variabelen zoeken...",
        "what_happens_next": "Wat gebeurt er daarna?"
      }
    }
  },
  {
    "key": "pt-BR",
    "content": {
      "common": {
        "action": "Ação",
        "actions": "Ações",
        "active": "Ativo",
        "add": "Adicionar",
        "all_activity": "Todas as atividades",
        "all_variables": "Todas as variáveis",
        "automations": "Automações",
        "back": "Voltar",
        "beta": "Beta",
        "blank_workflow": "Fluxo de trabalho em branco",
        "cancel": "Cancelar",
        "change": "Alterar",
        "clients": "Clientes",
        "close": "Fechar",
        "content": "Conteúdo",
        "created_by_name_email": "Criado por: {{userName}} ({{userEmail}})",
        "currently_selected": "Atualmente selecionado",
        "delete": "Excluir",
        "description": "Descrição",
        "details": "Detalhes",
        "document": "Documento",
        "edit": "Editar",
        "existing_project": "Projeto existente",
        "file_name": "Nome do arquivo",
        "folder": "Pasta",
        "folder_name": "Nome da pasta",
        "from_template": "Do modelo",
        "incomplete_workflow": "Fluxo de trabalho incompleto",
        "last_updated": "Última atualização",
        "last_updated_by": "Última atualização por",
        "link_to_edit_options": "Editar opções",
        "load_more": "Carregar mais",
        "missing_name": "Nome ausente",
        "more_options": "Mais opções",
        "name": "Nome",
        "new_action": "Nova ação",
        "new_folder": "Nova pasta",
        "new_project": "Novo projeto",
        "ok": "OK",
        "optional_flag": "(opcional)",
        "options": "Opções",
        "preview": "Visualizar",
        "previous_actions": "Ações anteriores",
        "project": "Projeto",
        "project_name": "Nome do Projeto",
        "properties": "Propriedades",
        "prospects": "Clientes em potencial",
        "relevant_variables": "Variáveis relevantes",
        "run_details": "Detalhes de execução",
        "run_history": "Histórico de execução",
        "save": "Salvar",
        "save_as_template": "Salvar como modelo",
        "search": "Pesquisar",
        "select": "Selecionar",
        "selected": "selecionado",
        "selected_clients": "Clientes selecionados",
        "selected_prospects": "Clientes em potencial selecionados",
        "send": "Enviar",
        "signers": "Signatários",
        "start": "Início",
        "started": "Iniciado",
        "started_by": "Iniciado por",
        "status": "Status",
        "submit": "Enviar",
        "text": "Texto",
        "trigger": "Gatilho",
        "trigger_variables": "Variáveis de acionamento",
        "user": "Usuário",
        "variable": "Variável",
        "variables": "Variáveis",
        "workflow": "Fluxo de trabalho",
        "workflow_name": "Nome do fluxo de trabalho",
        "workflows": "Fluxos de trabalho"
      },
      "descriptions": {
        "action_output_symbol": "Valor gerado a partir dessa ação",
        "action_symbol": "Ação anterior {{descriptionText}}",
        "available_variables": {
          "num": "1 variável disponível",
          "num_plural": "{{count}} variáveis disponíveis"
        },
        "context_symbol": "Valor definido em tempo de execução",
        "file_location": "Local para salvar o documento assinado",
        "merge_fields": "Esse modelo tem campos de dados. Você pode usar variáveis para personalizá-las para cada execução ou pode inserir texto sem formatação se quiser que elas permaneçam as mesmas.",
        "properties_placeholder": "Descreva seu fluxo de trabalho",
        "save_signed_document": "Salvar documento assinado como",
        "signed_suffix_label": "-assinado",
        "text": "Uma cadeia de caracteres de texto",
        "user": "Um usuário do seu catálogo de endereços do ShareFile",
        "variables": "Descrição para usuários"
      },
      "file_export": {
        "back": "Voltar",
        "browse_integrations": "Procurar integrações",
        "cancel": "Cancelar",
        "continue": "Continuar",
        "description": "Exportar envia uma cópia do arquivo selecionado para outro aplicativo.",
        "destination": "Destino",
        "export": "Exportar",
        "export_description": "Com a exportação, será enviada uma cópia dos arquivos selecionados para outro aplicativo. Primeiro, escolha um aplicativo para enviar e, em seguida, especifique para onde nesse aplicativo você deseja exportar o arquivo.",
        "export_title": "Exportar",
        "exporting": "Exportando",
        "failed_to_load_integrations": "Falha ao carregar integrações. Tente novamente.",
        "file_failed_to_send": "Falha ao enviar {{fileName}}",
        "file_sent_successfully": "{{fileName}} enviado com sucesso",
        "files_failed_to_send": "Falha ao enviar {{count}} arquivos",
        "files_sent_successfully": "{{count}} arquivos enviados com sucesso",
        "more_files": "+ {{num}} mais arquivos",
        "no_destinations": "Você ainda não configurou nenhum destino. Instale uma integração que ofereça suporte à exportação para continuar.",
        "one_more_file": "+ mais 1 arquivo",
        "select_destination": "Selecione um destino",
        "starting_export": "Iniciando a exportação de arquivos",
        "step_description": "Primeiro, você escolherá um aplicativo para ser o destino. Em seguida, você especificará onde no aplicativo deseja exportar o arquivo.",
        "view_details": "Exibir detalhes",
        "workflow_failed": "Falha na execução do fluxo de trabalho"
      },
      "messages": {
        "add_variable": "Use uma variável para adicionar conteúdo dinâmico",
        "all_done": "Pronto!",
        "all_fields_required": "Todos os campos são obrigatórios, salvo indicação em contrário.",
        "all_items_loaded": "Você carregou todos os itens",
        "cancel_run": "Deseja cancelar esta corrida {{runName}}? Todas as etapas incompletas serão perdidas.",
        "cannot_delete_array_item": "Isso não pode ficar em branco.",
        "close_designer": "Tem certeza de que deseja fechar {{flowName}}? Suas alterações não salvas serão perdidas.",
        "couldnt_load_selection": "Não foi possível carregar sua seleção. Tente recarregar a página ou selecioná-la novamente.",
        "delete_action": "Tem certeza de que deseja excluir {{actionName}}? Isso não pode ser desfeito. Qualquer ação que se refira a essa ação será interrompida.",
        "delete_flow": "Tem certeza de que deseja excluir {{flowName}}? Isso não pode ser desfeito. A exclusão desse fluxo de trabalho não cancelará nenhuma execução ativa.",
        "disable_flow": "Tem certeza de que deseja desativar {{flowName}}? A desativação desse fluxo de trabalho não cancelará nenhuma execução ativa.",
        "error_loading_templates": "Erro ao carregar modelos",
        "flow_complete": "Esta é a etapa final do fluxo de trabalho.",
        "flow_save_failure": "Não foi possível salvar, tente novamente",
        "flow_save_success": "Fluxo de trabalho salvo",
        "generic_error": "Ocorreu um erro",
        "info_banner_content_existing_project": "Salvaremos cada documento assinado no projeto {{projectName}}.",
        "info_banner_content_folder": "Salvaremos cada documento assinado em uma pasta.",
        "info_banner_content_folder_name_client": "Salvamos o documento assinado na pasta do cliente. Se eles ainda não tiverem uma pasta, criaremos uma para você.",
        "info_banner_content_folder_name_prospect": "Criaremos uma pasta de cliente para você. Se já houver uma pasta com esse nome, acrescentaremos o endereço de email do cliente potencial.",
        "info_banner_content_new_folder": "Salvaremos cada documento assinado em uma pasta nova.",
        "info_banner_content_new_project": "Salvaremos cada documento assinado em um projeto novo.",
        "invalid_flow": "Há erros nesse fluxo de trabalho. Você não poderá executá-lo até que sejam corrigidos pelo proprietário, {{ownerName}}.",
        "invalid_flow_creator": "Há erros nesse fluxo de trabalho. Você não poderá executá-lo até que eles sejam corrigidos. Edite o fluxo de trabalho e tente novamente.",
        "loading_content": "Carregando conteúdo...",
        "multiple_node_errors": "Esta etapa contém {{numErrors}} erros",
        "next_step": "Próxima etapa: ",
        "no_available_symbols": "Não há variáveis apropriadas disponíveis para essa opção.",
        "no_edit_permission": "Você não tem permissão para editar esse fluxo de trabalho.",
        "no_items": "Nenhum item",
        "no_runs": "Sem execuções para mostrar",
        "no_templates": "Nenhum modelo encontrado",
        "no_workflows": "Não há fluxos de trabalho para mostrar",
        "opens_in_new_tab": "Abre em uma nova aba",
        "please_wait": "Aguarde ...",
        "redirecting": "Redirecionando em {{secondsRemaining}} segundos...",
        "required_fields_count": "{{numFilledIn}} de {{numRequired}} campos obrigatórios preenchidos",
        "run_cancelation_failed": "Falha no cancelamento da execução de {{runName}}.",
        "run_canceled": "Execução de {{runName}} cancelada com sucesso.",
        "save_warning": "Esse fluxo de trabalho não poderá ser executado em seu estado atual. Tem certeza de que deseja salvá-lo?",
        "send_agreements_success": "Contrato do cliente enviado com sucesso.",
        "single_node_error": "Esta etapa contém um erro",
        "something_went_wrong": "Algo saiu errado",
        "start_info": "Você precisa ser capaz de acessar todos os arquivos, pastas e funcionalidades usados nesse fluxo de trabalho para executá-lo com êxito. Entre em contato com seu administrador se precisar de uma licença de assinatura ou acesso a uma pasta.",
        "start_warning": "Precisamos de mais informações antes de iniciarmos esse fluxo de trabalho. Verifique se você preencheu todos os campos obrigatórios.",
        "template_fetch_error": "Não foi possível buscar seu modelo",
        "template_not_selected": "Nenhum modelo de documento adicionado",
        "unknown_variable": "Esta variável foi excluída",
        "workflow_failed": "Falha no {{workflowName}}",
        "workflow_started": "{{workflowName}} foi iniciado"
      },
      "run_logs": {
        "flow_not_found": "Fluxo de trabalho não encontrado",
        "log_strings": {
          "action_completed": "Ação concluída {{actionName}}",
          "action_started": "Ação {{actionName}} iniciada",
          "error_loading_action_label": "Ação sem nome",
          "loading_action_label": "...",
          "workflow_canceled": "Fluxo de trabalho cancelado",
          "workflow_completed": "Fluxo de trabalho concluído",
          "workflow_failed": "Falha ao concluir o fluxo de trabalho",
          "workflow_started": "Fluxo de trabalho iniciado"
        },
        "logs_empty": "Nenhuma atividade ainda",
        "logs_fetching_error": "Não é possível carregar a atividade",
        "run_not_found": "Entrada de atividade não encontrada"
      },
      "run_status": {
        "canceled": "Cancelado",
        "canceling": "Cancelando",
        "closed": "Concluído",
        "failed": "Erro",
        "open": "Em andamento ",
        "unknown": "Desconhecido"
      },
      "symbol_tags": {
        "error": "#ERRO",
        "reference_error": "#REFERROR"
      },
      "titles": {
        "dashboard": "Fluxos de trabalho automatizados",
        "flow_options": "Iniciar {{workflowName}}",
        "merge_fields": "Campos de dados do modelo",
        "selected_template": "Modelo:",
        "send_agreements": "Enviar o contrato do cliente",
        "send_agreements_alternate": "Enviar contrato",
        "template_preview": "Visualização - Contrato do Cliente",
        "template_preview_alternate": "Visualização - Contrato"
      },
      "user_actions": {
        "add_automation": "Adicionar automação",
        "add_trigger_variable": "Adicionar variável de ativação",
        "add_variable": "+ Adicionar variável",
        "add_workflow": "Adicionar fluxo de trabalho",
        "cancel_flow_run": "Cancelar execução de fluxo de trabalho",
        "cancel_run": "Cancelar execução",
        "change_template": "Alterar modelo",
        "close_sidebar": "Fechar painel de configuração",
        "close_workflow": "Fechar fluxo de trabalho",
        "contact_owner": "Entre em contato com o proprietário",
        "continue_editing": "Continuar a editar",
        "create_a_template": "Criar um modelo",
        "create_automation": "Criar automação",
        "create_variable": "Criar variável",
        "delete_action": "Excluir ação",
        "delete_flow": "Excluir fluxo de trabalho",
        "disable_flow": "Desativar fluxo de trabalho",
        "make_field_optional": "Tornar o campo opcional",
        "make_field_required": "Tornar o campo obrigatório",
        "search_project": "Procurar um projeto...",
        "select_a_template": "Selecionar um modelo",
        "select_a_trigger": "Selecione um gatilho",
        "select_a_value": "Selecionar um valor",
        "select_an_action": "Selecione uma ação",
        "select_folder": "Selecionar pasta",
        "select_item": "Selecionar este item",
        "select_next_client": "Selecionar o próximo cliente",
        "select_next_prospect": "Selecionar o próximo cliente em potencial",
        "select_prev_client": "Selecionar o cliente anterior",
        "select_prev_prospect": "Selecione o cliente em potencial anterior",
        "set_fields": "Definir campos de dados",
        "show_sidebar": "Mostrar painel de configuração",
        "start_flow": "Começar fluxo de trabalho",
        "view_run_details": "Exibir detalhes",
        "view_run_history": "Exibir execuções"
      },
      "user_prompts": {
        "client_send_agreements_selection": "Selecione um cliente para enviar um contrato",
        "configure_options": "Precisamos de um pouco mais de informações para iniciar esse fluxo de trabalho.",
        "create_template_help": "Não consegue encontrar o que você está procurando?",
        "prospect_send_agreements_selection": "Selecione um cliente em potencial para enviar um contrato",
        "search_content": "Pesquisar conteúdo...",
        "search_templates": "Buscar modelos...",
        "search_variables": "Pesquisar variáveis...",
        "what_happens_next": "O que acontece a seguir?"
      }
    }
  }
];