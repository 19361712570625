import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { BlockParams } from '@sharefiledev/solution-view-engine';

export interface ShellProps {
	piletApi: PiletApi;
}
/** Block parameters for urn:block:client-mgt-pilet:list-clients */

export interface ListClientsBlockParams {
	/**
	 * CSS padding shorthand accepted
	 */
	padding?: string;
}
/** Block parameters for urn:block:client-mgt-pilet:view-client */

export interface ViewClientBlockParams {
	/**
	 * Client RID
	 */
	clientRID: string;
	/**
	 * CSS padding shorthand accepted
	 */
	padding?: string;
}
/** Zone parameters for urn:zone:client-mgt-pilet:view-client:tab */

export interface ViewClientTabZoneParams {
	label: {
		de?: string;
		en?: string;
		es?: string;
		fr?: string;
		it?: string;
		ja?: string;
		nl?: string;
		'pt-BR'?: string;
	};
}
/** Zone parameters for urn:zone:client-mgt-pilet:view-client:overview:widget */

export interface ViewClientOverviewWidgetZoneParams {
	xs?: {
		offset?: number;
		pull?: number;
		push?: number;
		span?: number;
	};
	sm?: {
		offset?: number;
		pull?: number;
		push?: number;
		span?: number;
	};
	md?: {
		offset?: number;
		pull?: number;
		push?: number;
		span?: number;
	};
	lg?: {
		offset?: number;
		pull?: number;
		push?: number;
		span?: number;
	};
	xl?: {
		offset?: number;
		pull?: number;
		push?: number;
		span?: number;
	};
	xxl?: {
		offset?: number;
		pull?: number;
		push?: number;
		span?: number;
	};
}
/** Zone parameters for urn:zone:client-mgt-pilet:view-client:create:option */

export interface ViewClientCreateOptionZoneParams {
	testId?: string;
	label: Label;
	/**
	 * The kind of extension that will handle the option selection. All handlers are called with the following properties { clientRID: string; primaryClientContactRIDs: string[]; primaryServiceContactRIDs: string[] }. "menu" will trigger a sub menu with more options from the "options" property.
	 */
	handleWith: 'drawer' | 'modal' | 'menu';
	extensionHandlerName: string;
	titleForHandler?: TitleForHandler;
	overlayOptions?: OverlayOptions;
	onCompleteInteraction?: OnCompleteInteraction;
	/**
	 * Category section for the dropdown menu items
	 */
	section?: 'people' | 'services';
	options?: {
		testId?: string;
		label?: Label;
		/**
		 * The kind of extension that will handle the option selection. All handlers are called with the following properties { clientRID: string; primaryClientContactRIDs: string[]; primaryServiceContactRIDs: string[] }. "menu" will trigger a sub menu with more options from the "options" property.
		 */
		handleWith?: 'drawer' | 'modal' | 'menu';
		extensionHandlerName?: string;
		titleForHandler?: TitleForHandler;
		overlayOptions?: OverlayOptions;
		onCompleteInteraction?: OnCompleteInteraction;
	}[];
}
export interface Label {
	de?: string;
	en?: string;
	es?: string;
	fr?: string;
	it?: string;
	ja?: string;
	nl?: string;
	'pt-BR'?: string;
}
export interface TitleForHandler {
	de?: string;
	en?: string;
	es?: string;
	fr?: string;
	it?: string;
	ja?: string;
	nl?: string;
	'pt-BR'?: string;
}
/**
 * Additional options as defined by the modal or drawer options
 */
export interface OverlayOptions {}
export interface OnCompleteInteraction {
	eventName: string;
	/**
	 * Describe the payload that should be emitted with the event. Can use the payload from the drawer interaction as a template source with {{data}}.
	 */
	eventPayload?: {
		[k: string]: unknown;
	};
}
/** Block parameters for urn:block:client-mgt-pilet:client-intake */

export interface ClientIntakeBlockParams {
	/**
	 * CSS padding shorthand accepted
	 */
	contentPadding?: string;
	/**
	 * The starting actor RID for primary contact
	 */
	initialActorRID?: string;
	/**
	 * Disable redirection to the newly created client
	 */
	disableRedirect?: boolean;
}
/** Block parameters for urn:block:client-mgt-pilet:client-contact-intake */

export interface ClientContactIntakeBlockParams {
	/**
	 * Client RID
	 */
	clientRID: string;
	/**
	 * CSS padding shorthand accepted
	 */
	contentPadding?: string;
}
/** Block parameters for urn:block:client-mgt-pilet:client-update */

export interface ClientUpdateBlockParams {
	/**
	 * Client RID
	 */
	clientRID: string;
	/**
	 * CSS padding shorthand accepted
	 */
	contentPadding?: string;
}
/** Block parameters for urn:block:client-mgt-pilet:client-requests */

export interface ClientRequestsBlockParams {
	/**
	 * Client RID
	 */
	clientRID: string;
	/**
	 * Parent containerRID under which to look for client requests. Default value is accountRID
	 */
	containerRID?: string;
}
/** Block parameters for urn:block:client-mgt-pilet:contacts-table */

export interface ContactsTableBlockParams {
	/**
	 * Client RID
	 */
	clientRID: string;
	/**
	 * CSS padding shorthand accepted
	 */
	contentPadding?: string;
}
/** Block parameters for urn:block:client-mgt-pilet:client-contacts-widget */

export interface ClientContactsWidgetBlockParams {
	/**
	 * Client RID
	 */
	clientRID: string;
	/**
	 * CSS padding shorthand accepted
	 */
	contentPadding?: string;
}
/** Block parameters for urn:block:client-mgt-pilet:service-contacts-widget */

export interface ServiceContactsWidgetBlockParams {
	/**
	 * Client RID
	 */
	clientRID: string;
	/**
	 * CSS padding shorthand accepted
	 */
	contentPadding?: string;
}
/** Block parameters for urn:block:client-mgt-pilet:client-details-widget */

export interface ClientDetailsWidgetBlockParams {
	/**
	 * Client RID
	 */
	clientRID: string;
	/**
	 * CSS padding shorthand accepted
	 */
	contentPadding?: string;
}
/** Block parameters for urn:block:client-mgt-pilet:client-associate-resources-tag-renderer */

export interface ClientAssociateResourcesTagRendererBlockParams {
	/**
	 * RID of the resource which needs to be associated with client
	 */
	subjectRID: string;
	/**
	 * Title of the drawer
	 */
	drawerTitle?: string;
	/**
	 * Users associated with subjectRID for computing client suggestion
	 */
	userRIDs?: string[];
}
/** Block parameters for urn:block:client-mgt-pilet:suggested-contacts-widget */

export interface SuggestedContactsWidgetBlockParams {
	/**
	 * Client RID
	 */
	clientRID: string;
	/**
	 * CSS padding shorthand accepted
	 */
	contentPadding?: string;
}
/** Block parameters for urn:block:client-mgt-pilet:suggested-clients */

export interface SuggestedClientsBlockParams {
	currentClientRID?: string;
	userRIDs?: string[];
	/**
	 * CSS padding shorthand accepted
	 */
	contentPadding?: string;
	widgetType?: 'card' | 'inline-block';
}
/** Block parameters for urn:block:client-mgt-pilet:client-requests-widget */

export interface ClientRequestsWidgetBlockParams {
	/**
	 * Client RID
	 */
	clientRID: string;
	/**
	 * Parent containerRID under which to look for client requests. Default value is accountRID
	 */
	containerRID?: string;
	/**
	 * CSS padding shorthand accepted
	 */
	padding?: string;
}

export namespace BlockNames {
	export const ListClients = 'urn:block:client-mgt-pilet:list-clients' as const;
	export const ViewClient = 'urn:block:client-mgt-pilet:view-client' as const;
	export const ClientIntake = 'urn:block:client-mgt-pilet:client-intake' as const;
	export const ClientContactIntake =
		'urn:block:client-mgt-pilet:client-contact-intake' as const;
	export const ClientUpdate = 'urn:block:client-mgt-pilet:client-update' as const;
	export const ClientRequests = 'urn:block:client-mgt-pilet:client-requests' as const;
	export const ContactsTable = 'urn:block:client-mgt-pilet:contacts-table' as const;
	export const ClientContactsWidget =
		'urn:block:client-mgt-pilet:client-contacts-widget' as const;
	export const ServiceContactsWidget =
		'urn:block:client-mgt-pilet:service-contacts-widget' as const;
	export const ClientDetailsWidget =
		'urn:block:client-mgt-pilet:client-details-widget' as const;
	export const ClientAssociateResourcesTagRenderer =
		'urn:block:client-mgt-pilet:client-associate-resources-tag-renderer' as const;
	export const SuggestedContactsWidget =
		'urn:block:client-mgt-pilet:suggested-contacts-widget' as const;
	export const SuggestedClients = 'urn:block:client-mgt-pilet:suggested-clients' as const;
	export const ClientRequestsWidget =
		'urn:block:client-mgt-pilet:client-requests-widget' as const;
}

export type ListClientsProps = ShellProps & BlockParams & ListClientsBlockParams;
export type ViewClientProps = ShellProps & BlockParams & ViewClientBlockParams;
export type ClientIntakeProps = ShellProps & BlockParams & ClientIntakeBlockParams;
export type ClientContactIntakeProps = ShellProps &
	BlockParams &
	ClientContactIntakeBlockParams;
export type ClientUpdateProps = ShellProps & BlockParams & ClientUpdateBlockParams;
export type ClientRequestsProps = ShellProps & BlockParams & ClientRequestsBlockParams;
export type ContactsTableProps = ShellProps & BlockParams & ContactsTableBlockParams;
export type ClientContactsWidgetProps = ShellProps &
	BlockParams &
	ClientContactsWidgetBlockParams;
export type ServiceContactsWidgetProps = ShellProps &
	BlockParams &
	ServiceContactsWidgetBlockParams;
export type ClientDetailsWidgetProps = ShellProps &
	BlockParams &
	ClientDetailsWidgetBlockParams;
export type ClientAssociateResourcesTagRendererProps = ShellProps &
	BlockParams &
	ClientAssociateResourcesTagRendererBlockParams;
export type SuggestedContactsWidgetProps = ShellProps &
	BlockParams &
	SuggestedContactsWidgetBlockParams;
export type SuggestedClientsProps = ShellProps &
	BlockParams &
	SuggestedClientsBlockParams;
export type ClientRequestsWidgetProps = ShellProps &
	BlockParams &
	ClientRequestsWidgetBlockParams;

declare module '@sharefiledev/sharefile-appshell' {
	interface PiralCustomExtensionSlotMap {
		['urn:block:client-mgt-pilet:list-clients']: BlockParams & ListClientsBlockParams;
		['urn:block:client-mgt-pilet:view-client']: BlockParams & ViewClientBlockParams;
		['urn:block:client-mgt-pilet:client-intake']: BlockParams & ClientIntakeBlockParams;
		['urn:block:client-mgt-pilet:client-contact-intake']: BlockParams &
			ClientContactIntakeBlockParams;
		['urn:block:client-mgt-pilet:client-update']: BlockParams & ClientUpdateBlockParams;
		['urn:block:client-mgt-pilet:client-requests']: BlockParams &
			ClientRequestsBlockParams;
		['urn:block:client-mgt-pilet:contacts-table']: BlockParams & ContactsTableBlockParams;
		['urn:block:client-mgt-pilet:client-contacts-widget']: BlockParams &
			ClientContactsWidgetBlockParams;
		['urn:block:client-mgt-pilet:service-contacts-widget']: BlockParams &
			ServiceContactsWidgetBlockParams;
		['urn:block:client-mgt-pilet:client-details-widget']: BlockParams &
			ClientDetailsWidgetBlockParams;
		['urn:block:client-mgt-pilet:client-associate-resources-tag-renderer']: BlockParams &
			ClientAssociateResourcesTagRendererBlockParams;
		['urn:block:client-mgt-pilet:suggested-contacts-widget']: BlockParams &
			SuggestedContactsWidgetBlockParams;
		['urn:block:client-mgt-pilet:suggested-clients']: BlockParams &
			SuggestedClientsBlockParams;
		['urn:block:client-mgt-pilet:client-requests-widget']: BlockParams &
			ClientRequestsWidgetBlockParams;
	}
}
