import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {debounce} from 'lodash';
import {useEffect, useMemo} from 'react';

import {useRichText} from '../../../../table/context/RichText';
import {InputFieldProps} from '../../../Fields';
import {createUniqueId} from '../helper';
import {updateTextEditorFields} from './helper';

interface OnChangProp {
    editorProp: InputFieldProps;
}
export const OnChangePlugin = ({editorProp}: OnChangProp) => {
    const [editor] = useLexicalComposerContext();
    const {setTextEditors} = useRichText();
    const {name, record} = editorProp;
    const uniqueID = createUniqueId(name, record?._id);

    const saveContent = useMemo(() => {
        return (content: any) => {
            setTextEditors((prevState) => updateTextEditorFields(prevState, uniqueID, content));
        };
    }, []);

    const debouncedSaveContent = debounce(saveContent, 500);
    useEffect(() => {
        return editor.registerUpdateListener(({editorState, dirtyElements, dirtyLeaves}) => {
            if (dirtyElements.size === 0 && dirtyLeaves.size === 0) return;

            const serializedState = editorState.toJSON();
            debouncedSaveContent(serializedState);
        });
    }, [editor]);

    return null;
};
