import {projectsExtensionSlots} from '@sharefiledev/projects-pilet';
import type {PiletApi} from '@sharefiledev/sharefile-appshell';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';

import {
    AsyncLazyAddTab,
    AsyncLazyClientViewDrawer,
    AsyncLazyDynamicTable,
    AsyncLazyDynamicTablesWithTabsDevelopment,
    AsyncLazyRecordViewDrawer,
} from './Blocks';
import {AsyncLazyBlockDevelopment} from './Development';
import {DataTable} from './layouts/table';
import AsyncLazyApp from './LazyApp';
import {AsyncLazyCreateTableModal} from './overlays';
import {dataTablesExtensionSlots} from './sdk';
import {piletOverlays} from './sdk/extensionTypes';
import {t} from './utils';
import {setLogger} from './utils/logger';

export const setup = (app: PiletApi) => {
    dayjs.extend(weekday);
    dayjs.extend(localeData);

    setLogger(app.sf.getLogger());
    registerApp(app);

    app.registerExtension(projectsExtensionSlots.tabs, ({params}) => (
        <AsyncLazyApp piletApi={app} params={params} />
    ));

    // data tables tab in Projects.
    app.registerExtension(dataTablesExtensionSlots.projectTab, ({params}) => (
        <AsyncLazyApp piletApi={app} params={params} />
    ));

    const drawerPropsRenderer = {
        drawerProps: {
            title: t('dynamic-components:form.title.update'),
            width: '400px',
            footer: null,
        },
    };
    // service-provider drawer
    app.registerDrawer(
        piletOverlays.viewRequestRecordViewDrawer,
        ({options, cancelInteraction, completeInteraction}) => (
            <AsyncLazyRecordViewDrawer
                piletApi={app}
                completeInteraction={completeInteraction}
                cancelInteraction={cancelInteraction}
                containerRID={options?.containerRID || ''}
                isProjectFrozen={options?.readonly ?? false}
            />
        ),
        {
            ...drawerPropsRenderer,
        }
    );

    // client-view drawer
    app.registerDrawer(
        piletOverlays.clinetViewRequestRecordViewDrawer,
        ({options, cancelInteraction, completeInteraction}) => (
            <AsyncLazyClientViewDrawer
                piletApi={app}
                completeInteraction={completeInteraction}
                cancelInteraction={cancelInteraction}
                containerRID={options?.params?.containerRID || ''}
            />
        ),
        {
            ...drawerPropsRenderer,
        }
    );

    // create-table modal
    app.registerModal(
        piletOverlays.createTableModal,
        (props) => <AsyncLazyCreateTableModal {...props} />,
        {
            modalProps: {
                title: t('dynamic-components:addTab.createTab'),
                centered: true,
                width: 500,
                footer: null,
                styles: {
                    body: {
                        height: '230px',
                    },
                },
            },
        }
    );

    if (process.env.NODE_ENV === 'development') {
        app.sf.registerLeftNavComponent({
            href: '/dynamic-tables-dev',
            title: () => 'Test Local',
            icon: () => null,
        });

        app.registerPage('/dynamic-tables-dev', () => <AsyncLazyBlockDevelopment piletApi={app} />);
    }
};

const registerApp = (app: PiletApi) => {
    app.registerExtension(
        'urn:sfblock:dynamic-components-pilet:dynamic-tables-with-tabs',
        AsyncLazyDynamicTablesWithTabsDevelopment
    );

    app.registerExtension(
        'urn:sfblock:dynamic-components:dynamic-tables-content',
        AsyncLazyDynamicTable
    );

    app.registerExtension('urn:sfblock:dynamic-components:dynamic-tables-add-tab', AsyncLazyAddTab);

    // extension slot for data-table library
    app.registerExtension(dataTablesExtensionSlots.dataTable, ({params}) => (
        <DataTable {...params} />
    ));
};
