import {ContactListType} from '@citrite/sf-api';
import {Select, Spin, Tag} from 'antd';
import {debounce} from 'lodash';
import {useCallback, useEffect, useRef, useState} from 'react';

import {loadAssigneeUsers} from '../../../api/sfApi';
import {Loader} from '../../../common/loader/Loader';
import {COLORS, MAX_ASSIGNEE_LIMIT, REMOVE_TAG_BUTTON_CLASS} from '../../../constants/constants';
import {TagRender} from '../../../engine/types';
import {FieldType} from '../../../enum';
import {t} from '../../../utils';
import {useContactSearch} from '../../../utils/hooks/useContactSearch/useContactSearch';
import {Icons} from '../../../utils/icons/icon';
import {addUsersToProjectFromRelatedResources} from '../../../utils/projectManagement/addUsertoProject';
import {getParentResourceRID} from '../../../utils/utils';
import {StyledDropdown} from '../../table/DataTable.styled';
import {
    AvatarFieldProps,
    ContactApiResponse,
    ContactOptionProps,
    ContactOptionType,
} from '../Fields';
import {StyledSpanVerticalAlign, StyledTagRemoveBtn} from '../Fields.styled';
import {useAvatarMultiSelectOperation} from '../hooks/useAvatarMultiSelectOperation';
import {useFieldOperations} from '../hooks/useFieldOperations';
import {stringToArray} from '../utils';
import {ContactOption} from './ContactOption';

export const ContactMultiSelectField = ({
    disabled,
    fieldValue,
    forwardedRef,
    validations,
    attributes,
    isFilter = false,
    recordId,
    required,
    onChange,
    onCancel,
    dataTestId,
    fieldType,
    userList: userListForm,
}: AvatarFieldProps) => {
    const isInlineField = fieldType === FieldType.Inline;
    const [isDropdownOpen, setIsDropdownOpen] = useState(isInlineField);
    const containerRef = useRef(null);
    const avatarValue = stringToArray(fieldValue ?? []);
    const maxCount = Math.min(validations?.limit?.value ?? MAX_ASSIGNEE_LIMIT, MAX_ASSIGNEE_LIMIT);
    const {allowMultiSelect = true, userGroup = ContactListType.AllUsers} = attributes || {
        allowMultiSelect: true,
        userGroup: ContactListType.AllUsers,
    };
    const {inputValue, handleOnChange, handleOnBlur, handleKeyDown} = useFieldOperations(
        fieldType,
        avatarValue,
        required,
        validations,
        onChange,
        onCancel
    );
    const [term, setTerm] = useState('');
    const [contactsList, setContactsList] = useState<ContactOptionType[]>([]);
    const [initialContactsList, setInitialContactsList] = useState<ContactOptionProps[]>([]);
    const [selectedContacts, setSelectedContacts] = useState<ContactOptionType[]>([]);
    const [loading, setIsLoading] = useState(true);

    const {operationCompleted} = useAvatarMultiSelectOperation(avatarValue, containerRef);
    const userList: Record<string, any> = userListForm ?? {};
    const {searchContacts} = useContactSearch();
    const isFieldEmpty = () =>
        !fieldValue || (Array.isArray(fieldValue) && fieldValue.length === 0);
    useEffect(() => {
        const fetchUsers = async () => {
            let users: ContactOptionProps[];
            if (attributes && attributes.userGroup) {
                users = (await searchContacts('', userGroup ?? ContactListType.AllUsers)) ?? [];
            } else {
                users = Object.keys(userList).map((key) => {
                    const [firstName, lastName] = userList[key].FullName.split(' ');
                    return {
                        firstName: firstName || '',
                        lastName: lastName || '',
                        email: userList[key].Email,
                        id: userList[key].Id,
                        userType: userList[key].userType,
                    };
                });
            }
            setInitialContactsList(users);
            setContactsList(initialContactsList.map(convertToOption));
            isFieldEmpty() && setIsLoading(false);
            return;
        };
        fetchUsers();
    }, []);
    useEffect(() => {
        if (!inputValue || (Array.isArray(inputValue) && inputValue.length === 0)) {
            setContactsList(initialContactsList.map(convertToOption));
            setSelectedContacts([]);
        }
    }, [inputValue]);
    const SelectedUserLabel: React.FC<ContactOptionProps> = ({firstName, lastName, email}) => (
        <>{firstName || lastName ? `${firstName} ${lastName}` : email ?? ''}</>
    );

    const loadAssignees = useCallback(async (ids: string[]) => {
        const result = await loadAssigneeUsers(ids);
        const contactOptions = result.map((contact: ContactApiResponse) =>
            convertToOption({
                firstName: contact.FirstName,
                lastName: contact.LastName,
                email: contact.Email,
                id: contact.Id,
                userType: contact.UserType,
            })
        );
        setIsLoading(false);
        setSelectedContacts(contactOptions);
    }, []);

    useEffect(() => {
        if (inputValue && Array.isArray(inputValue) && inputValue.length > 0) {
            loadAssignees(inputValue);
        }
    }, [loadAssignees]);
    useEffect(() => {
        if (term.length === 0) {
            setContactsList(initialContactsList.map(convertToOption));
        } else {
            userFind(term);
        }
    }, [term, initialContactsList]);

    const debouncedSearch = useCallback(
        debounce((inputValue) => {
            setTerm(inputValue);
        }, 500),
        [setTerm]
    );

    const handleDropdownVisibleChange = (open: boolean) => {
        setIsDropdownOpen(open);
    };

    const handleKey = (event: React.KeyboardEvent<any>) => {
        (!contactsList.length || !isDropdownOpen) && handleKeyDown(event);
    };

    const tagRender: TagRender = ({label, value, closable, onClose}) => {
        const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
            event.preventDefault();
            event.stopPropagation();
        };
        const contact = selectedContacts?.find(
            (option: ContactOptionType) => option.value === value
        )?.details;
        return (
            <Tag
                closeIcon={
                    <StyledTagRemoveBtn
                        className={REMOVE_TAG_BUTTON_CLASS}
                        aria-label={t(
                            'dynamic-components:manageField.fields.userMultiSelect.removeBtnAriaLabel',
                            {
                                userName: contact ? contact.email : '',
                            }
                        )}
                        onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
                            if (e.key === 'Enter') {
                                e.stopPropagation();
                                onClose();
                            }
                        }}
                        type="button"
                    >
                        <StyledSpanVerticalAlign>
                            <Icons.Close size={16} />
                        </StyledSpanVerticalAlign>
                    </StyledTagRemoveBtn>
                }
                bordered={false}
                style={{
                    marginInlineEnd: 4,
                    background: COLORS['default'].background,
                    color: COLORS['default'].color,
                    display: 'flex',
                    justifyContent: 'center',
                }}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
            >
                {contact ? <SelectedUserLabel {...contact} /> : label}
            </Tag>
        );
    };
    const userFind = (filterTerm: string) => {
        const contactOptions = initialContactsList
            .filter(
                (contact: ContactOptionProps) =>
                    contact.firstName.toLowerCase().includes(filterTerm.toLowerCase()) ||
                    contact.lastName.toLowerCase().includes(filterTerm.toLowerCase()) ||
                    contact.email.toLowerCase().includes(filterTerm.toLowerCase())
            )
            .map(convertToOption);
        setContactsList(contactOptions);
        return contactOptions;
    };

    const addEmployeeToProject = async (updatedList: string[]) => {
        if (!isFilter && userGroup !== ContactListType.Clients) {
            const tableRID = getParentResourceRID(recordId);
            const projectRID = getParentResourceRID(tableRID);
            await addUsersToProjectFromRelatedResources(projectRID, updatedList);
        }
    };
    const updateList = (value: any, contact: ContactOptionType, isSelect = false) => {
        if (allowMultiSelect) {
            setSelectedContacts((prevList) => {
                // Check if the new object already exists in the list
                const index = prevList.findIndex(
                    (item: ContactOptionType) => item.value === value.value
                );
                let updatedList: ContactOptionType[] = [];
                if (index === -1 && isSelect) {
                    // If it doesn't exist, add the new object to the list
                    updatedList = [...prevList, contact];
                } else {
                    // If it exists, remove it from the list
                    updatedList = prevList.filter((_, i) => i !== index);
                }
                const userIds = updatedList.map((e: ContactOptionType) => e.value);
                if (index === -1 && isSelect) {
                    addEmployeeToProject(userIds);
                }
                // Call the callback function with the updated list
                if (handleOnChange) {
                    handleOnChange(userIds);
                }
                return updatedList;
            });
        } else {
            setSelectedContacts([contact]);
            addEmployeeToProject([contact.value]);
            if (handleOnChange) {
                handleOnChange([contact.value]);
            }
        }
    };
    const renderDropdown = (menu: any) => {
        return loading ? (
            <div>
                <Spin size="small" />
            </div>
        ) : (
            <StyledDropdown>{menu}</StyledDropdown>
        );
    };
    const getDropdownStyle = () => {
        return isInlineField
            ? ({
                  width: 'fit-content',
                  minWidth: '276px',
                  maxWidth: '352px',
                  position: 'relative',
              } as React.CSSProperties)
            : undefined;
    };
    return (
        <div className="multi-select" ref={containerRef}>
            {loading && isInlineField ? (
                <Loader height="30px" />
            ) : (
                <Select
                    mode="multiple"
                    data-testid={dataTestId}
                    placeholder={t('dynamic-components:userSelect.search')}
                    maxCount={!allowMultiSelect && maxCount === 1 ? undefined : maxCount}
                    maxTagCount="responsive"
                    autoFocus
                    defaultOpen={isInlineField}
                    showSearch
                    labelInValue
                    notFoundContent={loading ? <Spin size="small" /> : null}
                    value={selectedContacts?.map(
                        (option: ContactOptionType) => option?.details?.id
                    )}
                    aria-labelledby={`${allowMultiSelect ? 'multi-select' : 'select'}`}
                    aria-required={required}
                    disabled={disabled}
                    filterOption={false}
                    onSearch={debouncedSearch}
                    options={contactsList}
                    tagRender={tagRender}
                    ref={forwardedRef}
                    onBlur={operationCompleted ? handleOnBlur : undefined}
                    onSelect={(value, option) => updateList(value, option, true)}
                    onDeselect={(value, option) => updateList(value, option)}
                    onDropdownVisibleChange={handleDropdownVisibleChange}
                    onKeyDown={handleKey}
                    dropdownRender={renderDropdown}
                    dropdownStyle={getDropdownStyle()}
                    dropdownAlign={{
                        overflow: {adjustX: true, shiftX: true, shiftY: true, adjustY: true},
                    }}
                />
            )}
        </div>
    );
};
export const convertToOption = (contact: ContactOptionProps): ContactOptionType => {
    return {
        label: <ContactOption {...contact} />,
        value: contact.id!,
        details: contact,
    };
};
