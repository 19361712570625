import React from 'react';
import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { BlockNames } from './block.types';

export function addBlocks(piletApi: PiletApi) {
	const LazyListClients = React.lazy(() => import('./ListClients'));
	piletApi.registerExtension(BlockNames.ListClients, ({ piral, params }) => (
		<React.Suspense fallback={<div data-testid="loading-list-clients" />}>
			<LazyListClients piletApi={piral} {...params} />
		</React.Suspense>
	));

	const LazyViewClient = React.lazy(() => import('./ViewClient'));
	piletApi.registerExtension(BlockNames.ViewClient, ({ piral, params }) => (
		<React.Suspense fallback={<div data-testid="loading-view-client" />}>
			<LazyViewClient piletApi={piral} {...params} />
		</React.Suspense>
	));

	const LazyClientIntake = React.lazy(() => import('./ClientIntake'));
	piletApi.registerExtension(BlockNames.ClientIntake, ({ piral, params }) => (
		<React.Suspense fallback={<div data-testid="loading-client-intake" />}>
			<LazyClientIntake piletApi={piral} {...params} />
		</React.Suspense>
	));

	const LazyClientContactIntake = React.lazy(() => import('./ClientContactIntake'));
	piletApi.registerExtension(BlockNames.ClientContactIntake, ({ piral, params }) => (
		<React.Suspense fallback={<div data-testid="loading-client-intake" />}>
			<LazyClientContactIntake piletApi={piral} {...params} />
		</React.Suspense>
	));

	const LazyClientUpdate = React.lazy(() => import('./ClientUpdate'));
	piletApi.registerExtension(BlockNames.ClientUpdate, ({ piral, params }) => (
		<React.Suspense fallback={<div data-testid="loading-client-update" />}>
			<LazyClientUpdate piletApi={piral} {...params} />
		</React.Suspense>
	));

	const LazyContactsTable = React.lazy(() => import('./ContactsTable'));
	piletApi.registerExtension(BlockNames.ContactsTable, ({ piral, params }) => (
		<React.Suspense fallback={<div data-testid="loading-contacts-table" />}>
			<LazyContactsTable piletApi={piral} {...params} />
		</React.Suspense>
	));

	const LazyClientRequests = React.lazy(() => import('./ClientRequests'));
	piletApi.registerExtension(BlockNames.ClientRequests, ({ piral, params }) => (
		<React.Suspense fallback={<div data-testid="loading-client-requests" />}>
			<LazyClientRequests piletApi={piral} {...params} />
		</React.Suspense>
	));

	const LazyClientContactsWidget = React.lazy(() => import('./ClientContactsWidget'));
	piletApi.registerExtension(BlockNames.ClientContactsWidget, ({ piral, params }) => (
		<React.Suspense fallback={<div data-testid="loading-client-contacts-widget" />}>
			<LazyClientContactsWidget piletApi={piral} {...params} />
		</React.Suspense>
	));

	const LazyServiceContactsWidget = React.lazy(() => import('./ServiceContactsWidget'));
	piletApi.registerExtension(BlockNames.ServiceContactsWidget, ({ piral, params }) => (
		<React.Suspense fallback={<div data-testid="loading-service-contacts-widget" />}>
			<LazyServiceContactsWidget piletApi={piral} {...params} />
		</React.Suspense>
	));

	const LazyClientAssociateResources = React.lazy(
		() => import('./ClientAssociateResources')
	);
	piletApi.registerExtension(
		BlockNames.ClientAssociateResourcesTagRenderer,
		({ piral, params }) => (
			<React.Suspense fallback={<div data-testid="loading-client-associate-resources" />}>
				<LazyClientAssociateResources piletApi={piral} {...params} />
			</React.Suspense>
		)
	);

	const LazySuggestedContactsWidget = React.lazy(
		() => import('./SuggestedContactsWidget')
	);
	piletApi.registerExtension(BlockNames.SuggestedContactsWidget, ({ piral, params }) => (
		<React.Suspense fallback={<div data-testid="loading-suggested-contacts-widget" />}>
			<LazySuggestedContactsWidget piletApi={piral} {...params} />
		</React.Suspense>
	));

	const LazySuggestedClientsWidget = React.lazy(() => import('./SuggestedClientsWidget'));
	piletApi.registerExtension(BlockNames.SuggestedClients, ({ piral, params }) => (
		<React.Suspense fallback={<div data-testid="loading-suggested-clients-widget" />}>
			<LazySuggestedClientsWidget piletApi={piral} {...params} />
		</React.Suspense>
	));

	const LazyClientDetailsWidget = React.lazy(() => import('./ClientDetailsWidget'));
	piletApi.registerExtension(BlockNames.ClientDetailsWidget, ({ piral, params }) => (
		<React.Suspense fallback={<div data-testid="loading-client-details-widget" />}>
			<LazyClientDetailsWidget piletApi={piral} {...params} />
		</React.Suspense>
	));

	const LazyClientRequestsWidget = React.lazy(() => import('./ClientRequestsWidget'));
	piletApi.registerExtension(BlockNames.ClientRequestsWidget, ({ piral, params }) => (
		<React.Suspense fallback={<div data-testid="loading-client-requests-widget" />}>
			<LazyClientRequestsWidget piletApi={piral} {...params} />
		</React.Suspense>
	));
}
