import { Users } from '@sharefiledev/icons';
import { RID } from '@sharefiledev/sfplugin-resource-id';
import { type PiletApi } from '@sharefiledev/sharefile-appshell';
import { addBlocks } from './blocks';
import { addBlockDevelopment } from './development';
import { AssignContactToClientExtension } from './extensions/AssignContactToClient';
import { AssignResourceToClientExtension } from './extensions/AssignResourceToClient';
import { ClientConfigurationDataAccessorExtension } from './extensions/ClientConfigurationDataAccessor';
import { ClientConfigurationPage } from './extensions/ClientConfigurationPage';
import { ClientContactSelectorExtension } from './extensions/ClientContactSelector';
import { AsyncClientManagementConfiguration } from './extensions/ClientManagementConfiguration';
import { ClientManagementHomePage } from './extensions/ClientManagementHomePage';
import { ClientViewPage } from './extensions/ClientViewPage';
import { NavigateFromFolderToClientExtension } from './extensions/NavigateFromFolderToClient';
import { RecentlyDeletedPage } from './extensions/RecentlyDeletedPage';
import { WorkflowsClientContactSelectorExtension } from './extensions/WorkflowsClientContactSelector';
import { addOverlays } from './overlays';

const smokeTestCustomers = [
	'pilet-tests.sharefiletest.com',
	'pilet-tests.sharefilestaging.com',
	'pilet-tests.sharefile.com',
];

export function setup(piral: PiletApi) {
	const { user, account, entitlements } = piral.sf.piletUserContext.get();
	const clientMgtEnabled = account.Preferences?.EnableClientManagement ?? false;
	const isEntitledForClientManagement: boolean = entitlements.getEntitlementStatus(
		'sharefile:entitlementdef-client-mgt-create'
	);

	// Ensure that the AST does not disable the extension in Advanced Settings -> Client Settings area
	if (isEntitledForClientManagement) {
		piral.registerExtension(
			'urn:sfextslot:client-mgt-pilet:client-configuration-management',
			AsyncClientManagementConfiguration
		);
	}

	if (!clientMgtEnabled || !isEntitledForClientManagement) {
		return;
	}

	addBlocks(piral);
	addOverlays(piral);

	const isUserClientType = user.Roles?.includes('Client');
	piral.sf.registerLeftNavComponent({
		href: '/clients',
		title: () => 'Clients',
		icon: Users,
		weight: 50,
		isAvailable: () => !isUserClientType,
	});

	piral.registerPage('/clients/configuration', ClientConfigurationPage);
	piral.registerPage('/clients/deleted', RecentlyDeletedPage);
	piral.registerPage('/clients/view/:clientRID', ClientViewPage);
	piral.registerPage('/clients', ClientManagementHomePage);

	piral.registerExtension(
		'urn:sfextslot:client-mgt-pilet:domino:client-contact:type',
		WorkflowsClientContactSelectorExtension
	);

	piral.registerExtension(
		'urn:sfextslot:client-mgt-pilet:assign-resource-to-client',
		AssignResourceToClientExtension
	);

	piral.registerExtension(
		'urn:sfextslot:client-mgt-pilet:navigate-from-folder-to-client',
		NavigateFromFolderToClientExtension
	);

	piral.registerExtension(
		'urn:sfextslot:client-mgt-pilet:assign-contact-to-client',
		AssignContactToClientExtension
	);

	piral.registerExtension(
		'urn:sfextslot:client-mgt-pilet:client-configuration-data-accessor',
		ClientConfigurationDataAccessorExtension
	);

	piral.registerExtension(
		'urn:sfextslot:client-mgt-pilet:client-contact-select',
		ClientContactSelectorExtension
	);

	piral.sf.registerViewEngineResourceURLRewrite({
		resourceType: 'client',
		defaultPath: '/clients/view/{clientRID}',
		callback: async (rid: string) => {
			const clientRID = RID.parse(rid);
			return { clientRID: clientRID.toString() };
		},
	});

	if (
		process.env.NODE_ENV === 'development' ||
		smokeTestCustomers.includes(location.hostname)
	) {
		addBlockDevelopment(piral);

		if (
			process.env.NODE_ENV === 'development' &&
			typeof piral.meta.config.apiOrigin === 'string'
		) {
			/**
			 * Manual require ensures tree-shaking
			 * does not include these modules in the entry index.js
			 */
			const { Configuration } = require('./generated/runtime');
			const { configureHttp } = require('./httpConfiguration');
			configureHttp((config: any) => {
				config.config = new Configuration({
					basePath: `${piral.meta.config.apiOrigin}/io/clientmgt/v1`,
				});
			});
		}
	}
}
